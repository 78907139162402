export default {
    messages: {
        '100100000': 'System Identify',
        '100100001': 'Incorrect username or password',
        '100100002': 'Invalid 2FA code, please try again!',
        '100100003': 'Insufficient Authentication Exception',
        // 不正确的 2fa code
        '905500001': 'Please enter a valid email verification code.',
        '905500002': 'This email address has been registered.',
        '905500003': 'Could not find account associated with this username',
        '905500004': 'Incorrect 2FA Code',
        '905500005': 'The password you entered is identical to a previously used password combination, please use a different one.',
        '905500006': 'Unable to cancel your withdraw request',
        '905500007': 'You need to complete KYC before using this function',
        '905500008': 'Please enable two-factor authentication',
        '905500009': 'You are not the administrator of this account',
        '905500010': 'Sub account does not exist',
        '905500011': 'This sub account is not associated with your administrator account',
        '905500012': '',
        '905500013': "The password your entered does not match our record.",
        '905500014': "This sub account is not activated",
        '905500015': "Invalid trading account",
        '905500016': 'You do not satisfy the requirement to make withdraw request',
        '905500017': 'Source account has no corresponding privilege',
        '905500018': 'Destination account has no corresponding privilege',
        '905500019': 'You do not have modification permission',
        '905500020': 'Individual accounts does not support sub account structure',
        '905500021': 'Bank account has been created',
        '905500023': 'The label is existed. Please revise and submit again',

        '906000007': 'Due to asset volatility, we were unable to execute your order.',
        '906000008': 'OTC order execution error - Insufficient balance',
        '906000009': 'OTC order execution error - Other error',

        '906000021': '',
        '906000022': 'High risk level wallet address',
        '906000023': '',
        '906000024': '',

        '904700001': "System Error",
        '904700002': "Mini BTC Non-tradable hours",
        '904700003': "Mini BTC trading has not started",
        '904700004': "Mini BTC Subscription/Redemption qty. is smaller than minimum required",
        '904700005': "Insufficient available Mini BTC subscription qty.",
        '904700006': "Insufficient balance",
        '904700007': "Insufficient balance, unable to lock",
        '904700008': "Insufficient balance, unable to unlock",
        '904700009': "You do not have permission to modify this order",
        '904700010': "Order could not be found",
        '904700011': "Unable to cancel",
        //'100100004': "Your account is suspended. Please contact support for more information.",
    },
    header: {
        ourVision: "Our Vision",
        productsServices: "Products & Services",
        markets: "Markets",
        trades: "Trade",
        investments: "Investments",
        nft: "NFT",
        otc: "OTC",
        careers: "Careers",
        company: "Company",
        disclaimer: "Legal",
        tradeRules: "Trading Rules",
        termsAndConditions: "Terms of Use",
        privacyPolicy: "Privacy Policy",
        disclosure: "Risk Warning on Digital Payment Token Services",
        login: "Log in",
        signUp: "Sign up",
        spotTrading: "DPT Spot Trading",
        securities: "Securities",
        fastTrade: "Fast Trade",
        miniBitcoin: "Mini Bitcoin",
        bitcoinIndex: "MetaComp Bitcoin Index",
        chinese: "中国",
        english: "English",
        download: "Download",
        investment: {
            digital:"Digital Carbon Credit (CNT)",
            hashRate:"Hash Rate Investment Scheme (HBIS)",
            verra :"China Verra Investment Scheme (CNVIS)",
            mmn: "Secured Money Market Portfolio Linked Note (MMN)",
        },
        aboutUs: 'About us',
        services: 'Services',
        crossBorder: 'Cross-border Money Transfer Service',
        tokenService: 'Digital Payment Token Service',
        bankAcount: 'VA bank Account',
        articles: 'Articles'
    },
    chooseMetacomp: {
        why: 'Why choose Metacomp',
        fees: 'Competitive Transaction Fees',
        banks: 'Fast Fiat Settlement in the Asia Time Zone via Leading Banks',
        dpt: 'Supporting fiat (USD, EUR, SGD & 15 currencies) and mainstream DPT',
        support: 'Dedicated Customer Support',
    },
    aboutUs: {
        about: 'About',
        metacomp: 'MetaComp',
        title: 'About MetaComp',
        content1: 'MetaComp Pte. Ltd. (“MetaComp”) is a leading Singapore-based digital asset platform that is regulated by the Monetary Authority of Singapore under the Payments Services Act 2019. Founded in 2018, MetaComp offers a world-class digital exchange platform with the best-in-class Nasdaq trading engine. MetaComp operates under a P2B2C (platform-to-business partners-to-clients) model and provides an integrated end-to-end suite of services to its clients, including Institutional Investors, Digital Economy Businesses and Regulators.',
        content2: 'Compliant by design, MetaComp’s suite of services provides a reliable platform on which businesses can develop and scale their digital asset offerings, through OTC and exchange trading services, fiat payment gateway, digital asset custody, prime brokerage, and asset management.',
        subtitle1: 'Our Vision and Mission',
        subtitle2: 'Our License',
        subcontent1: 'MetaComp is building a financial infrastructure for a seamless interoperable asset future, enabling crypto and traditional finance to coexist harmoniously.',
        subcontent2: 'MetaComp was granted its Major Payment Institution Licence under the Payment Services Act 2019 by the Monetary Authority of Singapore in December 2022.',
        contactUs: 'Contact us at'
    },
    notice: {
        spotTrading: "Spot Trading",
        securities: "Securities",
        miniBitcoin: "Mini Bitcoin",
        bitcoinIndex: "MetaComp Bitcoin Index",
        NFTMarket: "NFT Market",
        MyNFTAsset: "My NFT Asset",
    },
    userMenu: {
        levelKYC: "Level KYC",
        accountID: "Account ID",
        spotOrders: "Spot",
        investmentOrders: "Investment",
        OTCOrders: "OTC",
        spotTradeHistory: "Spot Trade History",
        faq: "FAQ",
        MCEFiat: "MetaComp Fiat Portfolio",
        MVGXFiat: "MVGX Fiat Portfolio",
        MCECrypto: "MetaComp Crypto Portfolio",
        MVGXInvestment: "MVGX Investment Portfolio",
        bankAccount: "Bank Account",
        walletAddressBook: "Wallet Address Book",
        depositHistory: "Deposit History",
        withdrawalHistory: "Withdrawal History",
        transferHistory: "Transfer History",
        statement: "Statement",
        movement: "Movement & Charges",
        personal:"Personal Details",
        KYCVerification: "KYC Verification",
        securityVerification: "Security Verification",
        APIManagement: "API Management",
        subAccounts: "Sub-Accounts",
        logout: "Log out",
    },
    login: {
        login: "Log in"
    },
    loginForm: {
        email: 'Email',
        password: 'Password',
        login: 'Login',
        forgotPassword: 'Forgot Password?',
        signUp: 'Sign up',
        visiting: 'URL verification: ',

        mobile: {
            visiting: 'URL verification: ',
        },
        rules: {
            email: 'Please enter your valid email',
            correctEmail: 'Please enter the correct email address',
            password: 'Please enter your password'
        },
        loginSuccess: 'Login successful',
        expire1: "Your password will expire in ",
        expire2: " days. Do you want to change it now?",
        resetPassword: "Your password has been expired. Please reset.",
    },
    loginHeader: {
        signUpNow: 'Sign up now',
        login: 'Login',
        haveAccount: 'Already have an account？',
        haveRegister: "Haven't registered？"
    },
    signUp: {
        create: 'Create an account to start trading with us!',
        signUp: 'Please enter your email address',
    },
    signUpForm: {
        signUp: 'Sign Up',
        email: 'Email',
        sendCode: 'Send code',
        sendCodeSuccess: 'Send code successful',
        verificationCode: 'Verification Code',
        password: 'Password',
        confirmPassword: 'Confirm Password',
        inconsistentPasswords: 'The passwords you entered are different',
        tips: 'I have read and agree to the ',
        terms: 'Terms of Use.',
        passwordRules: {
            l1: '10 characters minimum',
            l2: 'At least one number',
            l3: 'At least one uppercase letter'
        },
        rules: {
            email: 'Please enter your valid email',
            correctEmail: 'Please enter the correct email address',
            code: "Please enter your verification code",
            password: 'Please enter your password',
            confirm: 'Please confirm your password'
        },
        agreement: 'Please check the Terms of Use',
        signUpSuccess: 'Sign Up successful',
        placeholder: {
            email: "Please enter your email address",
            verificationCode: "Please enter the verification code you received",
        },
        next: "Next",
    },
    signUpInformation: {
        title: "",
    },
    signUpInformationForm: {
        verified: "Verified",
        firstName: "First Name",
        lastName: "Last Name",
        password: "Password",
        confirmPassword: "Confirm Password",
        referralCode: "Referral Code",
        signUp: "Sign Up",
        placeholder: {
            firstName: "First Name",
            lastName: "Last Name",
            password: "Please enter your password",
            confirmPassword: "Please confirm your password",
            referralCode: "Please select your referral code"
        },
        tips: "I certify that I am 18 years of age or older, I have read and agree to ",
        terms: "Terms of Use.",
        rules:{
            firstName: 'Please enter First Name',
            lastName: 'Please enter Last Name'
        }
    },
    forgotPassword: {
        forgotPassword: 'Forgot Password?',
    },
    forgotPasswordForm: {
        email: 'Email',
        sendCode: 'Send Code',
        sendCodeSuccess: 'Verification code sent successfully',
        verificationCode: 'Email verification code',
        newPassword: 'New Password',
        confirmPassword: 'Confirm Password',
        inconsistentPasswords: 'The two entered passwords are different',
        code: '2FA Code',
        submit: 'Submit',
        passwordRules: {
            l1: '10 characters minimum',
            l2: 'At least one number',
            l3: 'At least one uppercase letter'
        },
        rules: {
            email: 'Please enter your valid email address',
            correctEmail: 'Please enter the correct email address',
            code: "Please enter your verification code",
            password: 'Please enter your new password',
            confirm: 'Please confirm your password'
        },
        forgotPasswordSuccess: 'Change Password successful'

    },
    twoFa: {
        twoFa: "Security Verification",
    },
    twoFaFrom: {
        code: "2FA Code",
        emailVerification: "Email Verification",
        sendCode: "Send code",
        rules: {
            two2faCode: "This item must be filled.",
        },
        submit: "Submit",
        forgotCode: "Forgot Your 2FA Code?",
        sendCodeSuccess: "Send Code successful"
    },
    forgotCode: {
        forgotCode: "Forgot 2FA Code"
    },
    forgotCodeForm: {
        tips: "The one-time code will be sent to your email.",
        email: "Email",
        send: "Send",
    },
    home: {
        name: "Home"
    },
    about: {
        name: "About"
    },
    TransactionAndOtcAndCustody: {
        transactionFees: 'Transaction Fees',
        transactionFeesDescription: '15 bps for both maker and taker roles',
        otcFees: 'On-screen OTC Fees',
        otcFeesDescription: '25bps',
        custodyFees: 'Custody Fees',
        custodyFeesDescription: 'Waived',
    },
    depositAndWithdrawalFees: {
        title: 'Deposit and Withdrawal Fees',
        cryptoCurrencies: 'Crypto-Currencies',
        asset: 'Asset',
        fee: 'Fee',
        minAmount: 'Min Amount',
        tips: 'Withdrawal fees are meant to cover on-chain transfer costs.'
    },
    depositAndWithdrawalFeesDetail: {
        title: 'Deposit and Withdrawal Fees',
        fiatCurrencies: 'fiat currencies',
        description: 'MetaComp does not charge any fee on fiat currency deposits or withdrawals.  However, correspondent banks may include wire transfer charges which may impact the final amount received.',
    },
    otcFees: {
        title: 'OTC Fees (Desk)',
        description: '10 bps + external costs.  Please get in touch with us for more details at'
    },
    profile: {
        tabs: {
            overview: "Overview",
            personalDetails: "Personal Details",
            kyc: "KYC Verification",
            securityVerification: "Security Verification",
            apiManagement: "API Management",
            subAccounts: "Sub-Accounts",
            externalAuthorizationManagement: "External Authorization Management",
            preferencesSettings: "Preferences Settings"
        },
        overview: {
            title: "Welcome to MetaComp Trading Platform.",
            recentLogin: "Recent Login",
            ipAddress: "IP Address",
            contactEmail: "Contact Email",
            accountNo: "Account ID.",
            currentTier: "Current Tier",
            accountType: "Account Type",
            createdTime: "Created Time",
            expirationDays: "Days before Account Expiration",
            expirationTime: "Expiration Time",
            days: "Days",
            kyc: {
                title: "KYC ( Identification )",
                description: "To protect your funds, please complete KYC(identification) verification first.",
                descriptionSuccess: "You have been successfully certified.",
            },
            securityVerification: {
                title: "Security Verification",
                description: "Your current safety strength：",
                google: "Google Authenticator(2FA Code)",
                mobile: "Mobile Number",
                email: "Email Setup",
                tooWeek: "Too weak",
                middle: "Middle",
                strong: "Strong",
            },
            apiManagement: {
                title: "API Management",
                description: "Use API to automate your trading"
            },
            subAccounts: {
                title: "Sub-Accounts",
                description: "For convenient asset management, multiple sub-accounts can be created from the main account"
            },
            externalAuthorizationManagement: {
                title: "External Authorization Management",
                description: "External Authorization"
            },
            preferenceSettings: {
                title: "Preferences Settings",
                description: "Profile or notification settings"
            }

        },
        personalDetails: {
            title: "Personal Details",
            recentLogin: "Recent Login",
            ipAddress: "IP Address",
            contactEmail: "Contact Email",
            contactNumber: "Contact Number",
            address: "Mail Address",
            contactName: "Contact Name",
            name: "Name",
            description: "Description",
            disabled: "Disabled",
            enable: "Enable",
            recentLoginTable: {
                id: "ID",
                loginRegion: "Login Region",
                LoginDevice: "Login Device",
                time: "Time",
                ipAddress: "IP Address",
                language: "Language",
                status: "Status"
            }
        },
        kyc: {
            title: "KYC Verification",
            description: "To protect your funds, please complete KYC(identification) verification first.",
            changeType: "Apply for A Business Account",
            stage0: {
                title: "KYC Stage 0",
                emailVerification: "Email address verification",
                mobileVerification: "Mobile verification"
            },
            rules:{
                upload: "Please upload this file"
            }
        },
        securityVerification: {
            title: "Security Verification",
            description: "Your current safety strength：",
            google: {
                title: "Google Authenticator(2FA Code)",
                description: "2FA code is used as a safety verification for when you log in, withdraw or change safety settings. If you have trouble receiving SMS codes, you can use google authentication instead."
            },
            mobile: {
                title: "Contact Number",
                description: "Used for verification in account login, withdrawal and other functions. Click to view the supported country list."
            },
            email: {
                title: "Email Setup",
                description: "Used for verification in account login, withdrawal and other functions."
            },
            securitySettings: "Security Settings",
            loginPassword: {
                title: "Login Password",
                description: "Login password is used to log in to your account."
            },
            fundPassword: {
                title: "Fund Password",
                description: "To protect your assets, please turn on the fund password during trading."
            },
            twoFa: {
                title: "Login 2-Step Verification",
                description: "The Login 2-step Verification adds an extra layer of security to your account. You have to enable Google authenticator or Email verification or SMS to enable this setting. Once enabled, the Email verification code or SMS code or the six-digit code from Google Authenticator is required every time you log in to your account.",
                google: "Google Authenticator(2FA Code)",
                email: "Email Code",
                sms: "SMS Code"
            },
            mailNotificationSettings: {
                title: "Mail Notification Settings",
                description: "If there are any changes, you will be notified through email",
            },
            updateUserType2faSuccess: "Update Successful",
            enable: "Enable",
            change: "Change",
            tooWeek: "Too weak",
            middle: "Middle",
            strong: "Strong",
        },
        api: {
            title: "API Management",
            description: {
                l1: "Use API to automate your trading. For reference,",
                l2: " API Document",
                l3: " for details.",
            },
            create: "Create API",
            delete: "Delete All API",
            t1: "1. Do not disclose your API Key to anyone to avoid asset losses. It is recommended to bind IP for API Key to increase your account security.",
            t1Edit: "Edit",
            t2: "2. Be aware that your API Key may be disclosed by authorizing it to a third-party platform.",
            t2Edit: "Edit",
            t3: "3. You will not be able to create an API if KYC is not completed.",
            gotoKyc: "Go to KYC Now",
            t4: "4. You must enable SMS Verification or Google Verification.",
            t4Edit: "Edit",
            t5: "5. Setting Fund Password.",
            t5Edit: "Edit",
            t6: " Email Verification.",
            t6Edit: "Edit"
        },
        personal:
        {
            phaseOneCompleted: "Phase 1 completed",
            prdut: "Yon can now browse the product",
            kycTips: "Please complete KYC to trade",
            accountId: "Account ID",
            tier: "Tier",
            tierScheme: "Tier Scheme",
            v30: "30-day Volume",
            recentLogin: "Recent Login",
            ipAddress: "IP Address"
        }
    },
    portfolio: {
        tabs: {
            overview: "Portfolio Overview",
            funding: "MetaComp Fiat Portfolio",
            cryptoTrading: "MetaComp Crypto Portfolio",
            mvgxFiat: "MVGX Fiat Portfolio",
            investment: "MVGX Investment Portfolio"
        },
        asset: {
            title: "Portfolio Overview",
            portfolioValue: "Portfolio value",
            portfolioCarbonFootprint: "Portfolio Carbon Footprint",
            ton: "ton CO₂e",
            info: {
                l1: "No assets at the moment. You can either ",
                l2: " deposit digital assets",
                l3: " or ",
                l4: " start trading",
            },
            funding: {
                title: "MetaComp Fiat Portfolio",
                deposit: "Deposit",
                withdrawal: "Withdrawal",
                transfer: "Transfer",
            },
            crypto: {
                title: "MetaComp Crypto Portfolio",
                transfer: "Transfer",
                trade: "Trade"
            },
            portfolio: {
                title: "MVGX Fiat Portfolio",
                deposit: "Deposit",
                withdrawal: "Withdrawal",
                transfer: "Transfer",
            },
            investment: {
                title: "MVGX Investment Portfolio",
                transfer: "Transfer",
                trade: "Trade"
            }
        },
        history: {
            deposit: {
                title: "Deposit",
                description: "Transfer funds from wallets to your account",
            },
            withdrawal: {
                title: "Withdrawal",
                description: "Withdraw funds to your wallets",
            },
            transfer: {
                title: "Transfer",
                description: "Transfer crypto currency between trading accounts",
            },
            securitynotice: {
                title: "Security Notice",
                mce: "MetaComp",
                mvgx: "MVGX",
                description: "Check the website address and turn on two-factor authentication. Do not transfer money to staff who claim to be from ",
                description_end: " or disclose any information like passwords."
            },
            tips: {
                l1: "1. To prevent phishing, please make sure you visit the following website: ",
                l2: "2. Do not install any browser plug-ins that claim to be related to MetaComp.",
                l22: "2. Do not install any browser plug-ins that claim to be related to MVGX.",
                l3: "3. Do not reveal any browser, short messages,email,Google Authenticator codes to anyone, including MetaComp staff.",
                l33: "3. Do not reveal any browser, short messages,email,Google Authenticator codes to anyone, including MVGX staff.",
                l4: "4. Don't make calls to anyone claiming to be MetaComp customer service.",
                l44: "4. Don't make calls to anyone claiming to be MVGX customer service.",
                l5: "5. Do not transfer money to any staff who claim to be from MetaComp.",
                l55: "5. Do not transfer money to any staff who claim to be from MVGX."
            }
        },
        tableAccount: {
            instruments: "Instruments",
            balance: "Balance",
            valuation: "Valuation",
            env: "Carbon Footprint (ton CO₂e)",
            available: "Available Balance",
            actions: "Actions",
            deposit: "Deposit",
            withdrawal: "Withdrawal",
            transfer: "Transfer"
        }
    },
    depositPop: {
        mceDepositFiat: "Deposit MetaComp Fiat Account",
        mvgxDepositFiat: "Deposit MVGX Fiat Account",
        descriptionFiat: "Send your funds from your bank account",
        depositCrypto: "Deposit Crypto",
        descriptionCrypto: "Deposit your crypto from your wallet address",
    },
    depositFiat: {
        return: "Return",
        fundingProtfolio: "MetaComp Fiat portfolio",
        deposit: "Deposit",
        depositFiat: "Deposit MetaComp Fiat Account",
        depositMvgxFiat: "Deposit MVGX Fiat",
        depositCrypto: "Deposit Crypto",
        depositBankDetails: "Deposit Bank Details",
        beneficiaryName: "Beneficiary Name",
        beneficiaryBankAccount: "Beneficiary Bank Account",
        bankName: "Bank Name",
        swiftCode: "Swift Code",
        countryCode: "Country Code",
        bankAddress: "Bank Address",
        referenceCode: "Reference Code",
        tips: "Please indicate this Reference code on the payment details of your remittance instruction to the bank. Transfers that does not comply with this requirement may be lost or delayed.",
        recentDeposits: "Recent Deposits",
        goto: "Go to",
        history: "History",
        seeMore: " to see more transactions",
        intermediaryBank: "Intermediary Bank",
        ibsCode: "Intermediary Bank SWIFT Code",
        intermediaryBankAddress: "Intermediary Bank Address",
        fiatTable: {
            instruments: "Instruments",
            dateReceived: "Date Received",
            depositAmount: "Deposit Amount",
            status: "Status",
        },
        depositTips: "Please select the appropriate currency and input the corresponding reference code below.",
    },
    depositMvgxFiat: {
        return: "Return",
        fundingProtfolio: "MVGX Fiat portfolio",
        deposit: "Deposit",
        depositMvgxFiat: "Deposit MVGX Fiat Account",
        depositMceFiat: "Deposit MetaComp Fiat",
        depositCrypto: "Deposit Crypto",
        depositBankDetails: "Deposit Bank Details",
        beneficiaryName: "Beneficiary Name",
        beneficiaryBankAccount: "Beneficiary Bank Account",
        bankName: "Bank Name",
        swiftCode: "Swift Code",
        countryCode: "Country Code",
        bankAddress: "Bank Address",
        referenceCode: "Reference Code",
        tips: "Please indicate this Reference code on the payment details of your remittance instruction to the bank. Transfers that does not comply with this requirement may be lost or delayed.",
        recentDeposits: "Recent Deposits",
        goto: "Go to ",
        history: "History",
        seeMore: " to see more transactions",
        fiatTable: {
            instruments: "Instruments",
            dateReceived: "Date Received",
            depositAmount: "Deposit Amount",
            status: "Status",
        },
        depositTips: "Please select the appropriate currency and input the corresponding reference code below",
    },
    depositCrypto: {
        return: "Return",
        fundingProtfolio: "MetaComp Crypto portfolio",
        deposit: "Deposit",
        depositCrypto: "Deposit Crypto",
        description: "You need to satisfy all prerequisites before the assets are available for deposit.",
        depositMvgxFiat: "Deposit MVGX Fiat",
        depositMceFiat: "Deposit MetaComp Fiat",
        depositBankDetails: {
            title: "Deposit Bank Details",
            selectAInstrument: "Select a Instrument",
            chain: "Select a Chain",
            address: "Address",
            selfTest:"Self-test the sending wallet address (recommended)",
            screenYourWalletAddress:"Screen Your Wallet Address",
            depositConfirmation: "Deposit Confirmation",
            block: "block (s)",
            tips: "Please Confirm the Chain You Selected is ",
            beneficiaryName: "Beneficiary Name",
            beneficiaryTips: "Cyberdyne Tech Services Pte. Ltd.",
        },
        recentDeposits: "Recent Deposits",
        goto: "Go to ",
        history: "History",
        seeMore: " to see more transactions",
        selfTestTooltip: 'Only whitelisted address will be allowed to send crypto currencies to MetaComp. Please spend some time to self-test and whitelist your sending wallet address to minimize the risk of rejection of your deposit.',
        copySuccess: "Copied to clipboard",
    },
    cryptoFiatStatus: {
        l1: "1. You will not be able to deposit if level 2 kyc has not been completed.",
        l2: "2. Accept MVGX Market Trading Rules.",
        l3: "2. Enable login 2-Step Verification.",
        gotoKYC: "Go to KYC now",
        seeRules: "See Rules",
        edit: "Edit",
    },
    crytoTable: {
        instruments: "Instruments",
        dateReceived: "Date Received",
        depositAmount: "Deposit Amount",
        chain: "Chain",
        status: "Status",
    },
    history: {
        tabs: {
            depositHistory: "Deposit History",
            withdrawHistory: "Withdraw History",
            transferHistory: "Transfer History",
            fiat: "Fiat",
            crypto: "Crypto",
        },
        find: {
            exportToCSV: "Export to CSV",
        },
        depositTable: {
            instruments: "Instruments",
            dateReceived: "Date Received",
            depositAmount: "Deposit Amount",
            refNo: "Ref No.",
            chain: "Chain",
            status: "Status",
        },
        withdrawTable: {
            instruments: "Instruments",
            dateReceived: "Date Received",
            withdrawAmount: "Amount Withdraw",
            chain: "Chain",
            refNo: "Ref No.",
            walletAddress: "Wallet Address",
            bankAccount: "Bank Account",
            fees: "Fees (Custody Fee + Withdrawal Fee)",
            fiatFees: "Fees",
            status: "Status",
            actions: "Actions",
            cancel: "Cancel",
        },
        transferTable: {
            instruments: "Instruments",
            dateReceived: "Date Received",
            transferAmount: "Transfer Amount",
            refNo: "Ref No.",
            source: "Source",
            destination: "Destination",
            status: "Status",
        }
    },
    googleVerify: {
        return: "Return",
        profile: "Profile - Security Verification",
        googleAuthentication: "Google Authentication(2FA Code)",
        notice: {
            notice: "Notice",
            l1: "1. Do not delete code or uninstall your preferred authenticator from your device. Google Authenticator is recommended.",
            l2: "2. If the Google Authenticator doesn't work properly, try to re-sync it by going to Settings.",
        },
        code: "Enter your 2FA code",
        enterCode: "Please enter the 6-digit code",
        confirm: "Confirm",
        confirmSuccess: "Confirm Successful",
        copySuccess: "Copied to clipboard",
    },
    fundPassword: {
        return: "Return",
        profile: "Profile - Security Verification",
        fundingPassword: "Funding Password",
        tips: "Funding password is a six-digit password used for important operations such as transaction.Please note that funding password is not 2FA code.",
        step1: "You must complete this step in order to set your 2FA code.",
        step2: "You must complete this step in order to set your 2FA code.",
        gotoSet: "Go to",
        form: {
            code: "2FA Code",
            enterCode: "Please enter the 6-digit code",
            fundingPassword: "Funding Password",
            confirmFundingPassword: "Confirm Funding Password",
        },
        rules: "This item must be filled.",
        confirm: "Confirm",
    },
    withdrawal: {
        withdrawCrypto: {
            return: "Return",
            fundingProtfolio: "MetaComp Crypto portfolio",
            withdraw: "Withdraw",
            withdrawCrypto: "Withdraw Crypto",
            receiveAmount: "Receive amount",
            withdrawMceFiat: "Withdraw MetaComp Fiat",
            withdrawMvgxFiat: "Withdraw MVGX Fiat",
            withdrawAmount: "Amount Withdraw",
            available: "Available in Funding Portfolio：",
            address: "Select Your Label",
            wallectAdressName: "Wallet Address",
            addressBook: "Address Book",
            chain: "Chain",
            minimumWithdrawAmount: "Minimum Withdraw Amount",
            withdrawalFee: "Withdrawal Fee",
            submitRequest: "Submit Request",
            recentWithdrawals: "Recent Withdrawals",
            notice: {
                notice: "Notice",
                l1: "1. Please Note, MetaComp can't reverse funds transfer request. Please carefully check account number and address is correct before transfer.",
                l2: "2. Please note that MVGX only supports the ERC-20 network at the moment. Make sure the destination wallet is ERC-20 compatible, otherwise you are at risk of losing your assets.",
            },
            successful: "Withdrawal request has been submitted.",
            rules:{
                l1: "This item must be filled.",
            },
            sufficientFunds: "Not sufficient funds",
            lessMiniAmount: "Cannot be less than the minimum withdraw amount",
            
        },
        withdrawStatus: {
            l1: "1. You must complete level 3 kyc before you can make a withdrawal.",
            l2Crypto: "2. Set your wallet address.",
            l2Fiat: "2. Set your bank account.",
            l22: "2. Set your wallet address.",
            l3: "3. Accept MetaComp Market Trading Rules.",
            l4: "4. Set Google Authentication (2FA Code).",
            edit: "Edit",
            seeRules: "See Rules",
        },
        crytoTable: {
            instruments: "Instruments",
            dateReceived: "Date Received",
            withdrawAmount: "Amount Withdraw",
            chain: "Chain",
            refNo: "Ref No.",
            walletAddress: "Wallet Address",
            fees: "Fees (Custody Fee + Withdrawal Fee)",
            status: "Status",
            actions: "Actions",
            cancel: "Cancel"
        },
        withdrawFait: {
            return: "Return",
            fundingProtfolio: "MetaComp Fiat protfolio",
            withdraw: "Withdraw",
            withdrawMceFiat: "Withdraw MetaComp Fiat",
            withdrawMvgxFiat: "Withdraw MVGX Fiat",
            withdrawCrypto: "Withdraw Crypto",
            withdrawAmount: "Amount Withdraw",
            available: "Available in Funding Portfolio：",
            bankAccount: "Select Your Bank Account",
            label: "Select Your Label",
            labelAccount: "Bank Account",
            labelName: "Bank Name",
            labelAddress: "Bank Address",
            bankAccountBook: "Bank Account Book",
            chain: "Select a Chain",
            minimumWithdrawAmount: "Minimum Withdraw Amount",
            withdrawalFee: "Withdrawal Fee",
            receiveAmount: "Receive amount",
            submitRequest: "Submit Request",
            recentWithdrawals: "Recent Withdrawals",
            notice: {
                notice: "Notice",
                mce:'MetaComp',
                mvgx:'MVGX',
                l1: "1. Please Note,",
                l1_1:"can't reverse funds transfer request. Please carefully check account number and address is correct before transfer.",
                l2: "2. Please note that",
                l2_1:"only supports the ERC-20 network at the moment. Make sure the destination wallet is ERC-20 compatible, otherwise you are at risk of losing your assets."
            },
            successful: "Withdrawal request has been submitted.",
            rules:{
                l1: "This item must be filled.",
            },
            sufficientFunds: "Not sufficient funds",
            lessMiniAmount: "Cannot be less than the minimum withdraw amount",
        },
        withdrawMvgxFait: {
            return: "Return",
            fundingProtfolio: "MVGX Fiat protfolio",
            withdraw: "Withdraw",
            withdrawMceFiat: "Withdraw MetaComp Fiat",
            withdrawMvgxFiat: "Withdraw MVGX Fiat",
            withdrawCrypto: "Withdraw Crypto",
            withdrawAmount: "Amount Withdraw",
            available: "Available in Funding Portfolio：",
            bankAccount: "Select Your Label",
            bankAccountBook: "Bank Account Book",
            chain: "Select a Chain",
            minimumWithdrawAmount: "Minimum Withdraw Amount",
            withdrawalFee: "Withdrawal Fee",
            receiveAmount: "Receive amount",
            submitRequest: "Submit Request",
            recentWithdrawals: "Recent Withdrawals",
            notice: {
                notice: "Notice",
                l1: "1. Please Note, MVGX can't reverse funds transfer request. Please carefully check account number and address is correct before transfer.",
                l2: "2. Please note that MVGX only supports the ERC-20 network at the moment. Make sure the destination wallet is ERC-20 compatible, otherwise you are at risk of losing your assets.",
            },
            successful: "Withdrawal request has been submitted.",
            rules:{
                l1: "This item must be filled.",
            },
            sufficientFunds: "Not sufficient funds",
            lessMiniAmount: "Cannot be less than the minimum withdraw amount",
        },
        faitTable: {
            instruments: "Instruments",
            dateReceived: "Date Received",
            withdrawAmount: "Amount Withdraw",
            bankAccount: "Bank Account",
            fees: "Fees (Custody Fee + Withdrawal Fee)",
            status: "Status",
            actions: "Actions",
            cancel: "Cancel"
        },
    },
    noTrading: {
        noTransactionsFound: "No transactions found",
        goto: 'Go to ',
        history: "History ",
        toSee: "to see transactions older than 10 records",
    },
    cutTime: {
        cutOffTime: "Cut-off Time",
        hours: "hours",
        minutes: "minutes",
        seconds: "seconds",
    },
    documentation: {
        miniBtcOfferingMemorandum: "Mini BTC Offering Memorandum",
        miniBtcProductSheet: "Mini BTC Product Sheet",
        carbonNeutralMiniBTCBasket: "Carbon Neutral Mini BTC Basket",
        view: "view",
        tabName: "Documentation"
    },
    miniBtcPop: {
        title: "Mini Bitcoin Unit 001",
        content: {
            l1: "This Application should be read incontext of, and together with, the Offering Memorandum. Except where otherwise defined in thisApplication all capitalised terms shall have the samemeaning as in the Offering Memorandum.",
            l2: "The agreement between you and the Manager forinvestment into the Mini Bitcoin Unit 001 (the 'Scheme') will come intoeffect when a correctly completed Application and related subscription monies (and any relevantaccompanying documents, where required) are received and accepted by the Manager (such date of acceptance,the 'Effective Date'). The Manager reserves theright not to accept any Application.",
        },
        scroll: "Scroll to the Bottom to Select and Agree this Notice",
        scrollL1: "I certainly read and agree to the Terms of Use and the Privacy Agreement",
        scrollL2: "I understand and agree it is using external service provider DocuSign to capture my e-signature",
        fullName: "Full Name",
        nameOfSignatory: "Name of Signatory",
        designation: "Designation",
        email: "Email",
        agreeAndContinue: "Agree and continue",
        rules: {
            l1: "Please enter the Full Name",
            l2: "Please enter the Name of Signatory",
            l3: "Please enter the Designation",
            l4: "Please enter the Email",
            l5: "Please enter the correct email address",
        },
        placeholder: {
            l1: "Full Name",
            l2: "2FA Code",
            l3: "Please enter the 6-digit code",
        },
        showText: {
            name: "Your Name",
            title: "Your Title",
            email: "Your email address",
        }

    },
    mobileTabs: {
        subscribe: "Subscription",
        redeem: "Redemption",
    },
    overview: {
        tabName: "Overview",
        title1: "What is Mini BTC",
        description1: "Mini Bitcoin shall invest in and hold Bitcoin. This provides investors with a convenient and compliant manner to gain investment exposure to Bitcoin without the hassle of a digital wallet. Each MBTC represents 0.001 BTC. The BTC as represented by the MBTC is held in custody by MVGX in a dedicated cold wallet with institutional investment grade security.",
        title2: "Advantages of Mini BTC",
        description2: "MetaComp Mini Bitcoin is an investment vehicle that provides investors the opportunity to gain exposure to Bitcoin in the form of a security without the hassles of buying, storing, and safekeeping Bitcoin personally. Thereby, giving the investors the advantage of entering the crypto market with ease. Investors will be provided the liquidity on MetaComp platform to buy and sell their Mini Bitcoin.",
        benchmarkPrice: "Benchmark Price",
        AUM: "AUM",
        valuePerUnit: "Value Per Unit",
        subscriptionFee: "Subscription Fee",
        managementFee: "Management Fee",
        unitsOutstanding: "Units Outstanding",
        inceptionDate: "Inception Date",
        CISNet: "CISNet",
        redemptionFee: "Redemption Fee",
    },
    redeemTable: {
        date: "Date",
        amount: "Amount",
        estimatedValue: "Estimated value",
        fee: "Fee",
        status: "Status",
        actions: "Actions",
        cancel: "Cancel",
    },
    redeen: {
        myRedemptionRequest: "My Redemption Request",
        availableUnits: "Available Units for Redemption",
        units: "Units",
        unitsToRedeem: "Units to Redeem",
        minimumRedemption: "Minimum Redemption:",
        unit: "Unit",
        estimatedRedemptionValue: "Estimated Redemption Value",
        estimatedFee: "Estimated Fee",
        available: "Available",
        amountToInvest: "Redemption Value * ",
        annualized: "% + annualized Maintenance Fee",
        reset: "Reset",
        submit: "Submit",
        recentRedemptionHistory: "Recent Redemption History",
        placeholder: {
            l1: "Enter Unit",
            l2: "$ 0",
            l3: "$ 0.00"
        },
        submitTips:{
            l1: "Please Enter Unit",
            l2: "The number of copies entered cannot be 0",
            l3: "The input quota cannot be less than the minimum redemption quota",
            l4: "Subscription for today has ended, please submit your request next business day!",
            l5: "The entered number of copies cannot be greater than the number of available copies",
            submitSuccess: "Submit Success",
        }
    },
    scheme: {
        miniBitcoinInvestmentScheme: "Mini Bitcoin Investment Scheme",
        ticker: "TICKER",
        registrationNumber: "MAS CISNET REGISTRATION NUMBER",
        assetUnderManagement: "ASSET UNDER MANAGEMENT",
        livePrice: "LIVE PRICE",
        dailyChange: "DAILY CHANGE",
        sinceInception: "SINCE INCEPTION",
        tips: "Displayed price is only indicative, actual price may differ.",
        tips2:'Inception Date on ',
    },
    spec: {
        tabName: "Spec",
        title: "Specs",
        investmentObjective: "Investment Objective",
        benchmarkPrice: "Benchmark Price",
        investorQualification: "Investor Qualification",
        unitSize: "Unit Size",
        minimumSubscriptionAmount: "Minimum Subscription Amount",
        subscriptionHours: "Subscription Hours",
        currency: "Currency",
        dailySubscriptionCap: "Daily Subscription Cap",
        minimumRedemptionAmount: "Minimum Redemption Amount",
        unit: "Unit(s)",
        redemptionHours: "Redemption Hours",
        settlementMethod: "Settlement Method",
        cntUnitSize:"Each unit will be backed by one (1) ton of carbon credit",
    },
    under: {
        name: 'METH is physically backed by 0.001 ETH',
        tabName: 'Underlying Assets',
    },
    METH:{
        tooltip1:"Please reach out to sales{'@'}mvgx.com to invest in ",
        tooltip2:'',
    },
    underLyingAsset:{
        CNVIS:{
            title:'Asset 001',
            projectName:'Project Name',
            projectNameDesc:'Ningxia Angli Lingwu Photovoltaic Grid Connected Power Plant Project',
            projectType:'Project Type',
            prjectTypeDesc:"Energy industries (renewable/non-renewable sources)",
            verraStandard:'Verra Standard',
            verraStandardDesc:'Ningxia Angli Lingwu Photovoltaic Grid Connected Power Plant Project',
            projectDeveloper:'Project Developer/Proponent',
            projectDeveloperDesc:'Datang Angli (Lingwu) New Energy Co., Ltd',
            quantity:'Quantity',
            projectCountry:'Project Country',
            projectCountryDesc:'China',
            project3rd:'Project 3rd-Party Verifier',
            project3rdDesc:'China Quality Certification Center (CQC)',
            projectMeth:'Project Methodology',
            projectMethDesc:'ACM0002',
            period:'Crediting Period',
            periodDesc:'Dec 28, 2021 - Dec 27, 2031',
        },
        CNT:{
            title:'Asset 001',
            projectName:'Project Name',
            projectNameDesc:'200 MW Wind Farm, Huang Gai Zhuo, Guyuan, Zhangjiakou, Hebei, China',
            projectType:'Project Type',
            prjectTypeDesc:"Energy Industries (renewable sources)",
            carbonStandard:'Carbon Credit Standard',
            carbonStandardDesc:'China Certified Emission Reduction (CCER)',
            registryNumber:"Rigistry Serial Number",
            projectDeveloper:'Project Developer/​Proponent',
            projectDeveloperDesc:'CGN Wind Power Co.,Ltd',
            creditQuantity:'Credit Quantity (tonnes CO2 eq)',
            projectCountry:'Project Country',
            projectCountryDesc:'China',
            project3rd:'Project 3rd-Party Validator/Verifier',
            project3rdDesc:'Foreign Economic Cooperation Office, Ministry of Environmental Protection of China (MEPFECO）',
            projectMeth:'Project Methodology',
            projectMethDesc:'ACM0002',
            period:'Crediting Period',
            periodDesc:'Dec 12, 2014 - Dec 11, 2021',
        },        
        view:{
            viewName:'View',
            title:"Project Documents",
            document:'Document',
            documentType:'Document Type',
            projectDesc:'Project Description',
            monitorReport:'Monitoring Report',
            verification:'Verification Representation',
            projectDesign:'Project Design Document​',
            projectMonitorReport:'Project Monitoring Report',
            emissionVerifyReport:'Emission Reduction Verification Report'
        },
    },
    subscribe: {
        title: "My Subscription Request",
        subscriptionCap: "Subscription Cap",
        available: "Available",
        amountToInvest: "Amount to Invest",
        minimumSubscription: "Minimum Subscription:",
        faitPortfolio: "Fund your MVGX Fiat Portfolio",
        estimatedAllocation: "Estimated Allocation",
        units: "Units",
        cashbackRewardsToUse: "Cashback Rewards to Use",
        fee: "Fee",
        tips: "Amount to Invest * ",
        actualAmountDeducted: "Actual Amount Deducted",
        reset: "Reset",
        submit: "Submit",
        recentInvestmentHistory: "Recent Investment History",
        placeholderBalance: "Enter Balance",
        submitTip1: "Please Enter Balance",
        submitTip2: "The amount entered cannot be equal to 0",
        submitTip3: "The input amount should not be less than the subscription amount",
        submitTip4: "Subscription for today has ended, please submit your request next business day!",
        submitTip5: "Total Subscription Amount Exceeded Subscription Cap",
        submitSuccessful: "Submit Successful",
    },
    subTable: {
        date: "Date",
        amount: "Amount",
        allocated: "Allocated",
        fee: "Fee",
        env: "Carbon Footprint (ton CO₂e)",
        status: "Status",
        actions: "Actions",
        cancel: 'Cancel',
    },
    tabs: {
        subscribe: "Subscription",
        redeem: "Redemption",
        trade: "Trade",
        retirement: "Retirement",
        correspondPe: "Correspond Adjustment",
    },
    miniBtcTable: {
        createdAt: "Created At.",
        type: "Type",
        amount: "Amount",
        allocated: "Allocated",
        estimatedValue: "Estimated Value",
        fee: "Fee",
        env: "Carbon Footprint (ton CO₂e)",
        status: "Status",
        actions: "Actions",
        cancel: "Cancel",
    },
    buy: {
        receive: "Quantity",
        spend: "Spend",
        available: "Available in Portfolio:",
        transfer: "Transfer",
        previewAndQuote: "Preview and Quote",
        buy: "Buy",
        price: "Price",
        quantity: "Quantity",
        total: "Total",
        priceWill: "Price will refresh in",
        seconds: "seconds",
        cancel: "Cancel",
        execute: "Execute",
        range: "range:",
        please: "Please input quantity",
        consult: "Please consult with your Relationship Manager, if you wish to receive a real time quote directly from our trading team.",
    },
    otcTable: {
        orderId: "Order ID",
        createdAt: "Created At.",
        symbol: "Symbol",
        buyOrSell: "Buy / Sell",
        quantity: "Quantity",
        executedPrice: "Executed Price",
        status: "Status"
    },
    sell: {
        spend: "Quantity",
        available: "Available in Portfolio:",
        transfer: "Transfer",
        receive: "Receive",
        amount: 'Amount',
        previewAndQuote: "Preview and Quote",
        sell: "Sell",
        limit: "Limit",
        price: "Price",
        quantity: "Quantity",
        total: "Total",
        priceWill: "Price will refresh in",
        seconds: "seconds",
        cancel: "Cancel",
        execute: "Execute",
        range: "range:",
        please: "Please input quantity",
    },
    otcIndex: {
        buy: "Buy",
        sell: "Sell",
        otc: "OTC",
        recentRecords: "Recent Records",
    },
    cryptoTrading: {
        title: "MetaComp Crypto Portfolio",
        portfolioCarbonFootprint: "Portfolio Carbon Footprint：",
        ton: "ton CO₂e",
    },
    fundingAccount: {
        title: "MetaComp Fiat Portfolio",
        portfolioCarbonFootprint: "Portfolio Carbon Footprint：",
        ton: "ton CO₂e",
    },
    investment: {
        title: "MVGX Investment Portfolio",
        portfolioCarbonFootprint: "Portfolio Carbon Footprint：",
        ton: "ton CO₂e",
    },
    mvgxFait: {
        title: "MVGX Fiat Portfolio",
        portfolioCarbonFootprint: "Portfolio Carbon Footprint：",
        ton: "ton CO₂e",
    },
    movementTable: {
        createdAt: "Created At.",
        operationID: "Operation ID",
        accountName: "Account Name",
        instruments: "Instruments",
        transactionType: "Transaction Type",
        amount: "Amount",
    },
    movementIndex: {
        movementCharges: "Movement & Charges",
    },
    detailTable: {
        time: "Time",
        operationID: "Operation ID",
        accountType: "Account Type",
        instruments: "Instruments",
        transactionType: "Transaction Type",
        amount: "Amount",
    },
    stateFind: {
        export: "Export",
    },
    stateTable: {
        instruments: "Instruments",
        balanceBroughtForward: "Balance Brought Forward",
        balanceCarriedForward: "Balance Carried Forward",

        fp: "FP",
        fundingPortfolio: "MetaComp Fiat Portfolio",
        cp: "CP",
        cryptoTradingPortfolio: "MetaComp Crypto Portfolio",
        ip: "IP",
        investmentPortfolio: "MVGX Investment Portfolio",
        mvgxFiatPortfolio: "MVGX Fiat Portfolio",
    },
    export: {
        statementReport: "Statement Report"
    },
    statmentIndex: {
        statementSummary: "Statement Summary",
        details: "Details",
    },
    addBank: {
        return: "Return",
        fundingProtfolio: "Funding portfolio",
        withDrawal: "WithDrawal",
        bankAccountList: "Bank Account List",
        addBankAccount: "Add Bank Account",
    },
    transter: {
        return: "Return",
        mceProtfolio: "MetaComp Fiat Portfolio",
        mvgxProtfolio: "MVGX Fiat Portfolio",
        transfer: "Transfer",
        recentRecords: "Recent Records",
    },
    addBankPop: {
        name: "Bank Name",
        accountNo: "IBAN/Account No.*(Exclude '-')",
        countryCode: "Country/Region",
        swiftCode: "Swift Code",
        bankAddress: "Bank Address",
        submit: "Submit",
        label: "Label",
        checkErr: "The IBAN/Account No you submitted does not pass the verification. Please check and fill it in again.",
    },
    addWalletPop: {
        walletOwner: 'Wallet Owner',
        walletAddress: "Wallet Address",
        label: "Label",
        chain: "Chain",
        description: "Description",
        submit: "Submit",
        checkErr: "The wallet address you submitted does not pass the verification. Please check and fill it in again.",
        riskErr: "This address you entered is invalid or the chain you selected does not matched your wallet address.",
    },
    screenAddress: {
        walletAddressDetails:"Wallet Address Details",
        walletAddress: "Wallet Address",
        chain: "Chain",
        description: "Description",
        submit: "Submit",
        close: "Close",
        selfTest: "Self-Test",
        screeningSuggestion: "Screening Suggestion",
        suggestionText1: "Please fill in the required fields above first.",
        suggestionText2: "After successful completion of the self-test, you will be able to register and whitelist your wallet.",
        lowRisk1: "Your wallet address is at low risk",
        lowRisk2: "It can be whitelisted.",
        highRisk1: "Your wallet address is at high risk.",
        highRisk2: "It is recommended that you use another wallet address. Otherwise, processing time will be prolonged."
    },
    bankAccount: {
        return: "Return",
        fundingProtfolio: "Funding portfolio",
        bankAccountList: "Bank Account List",
        bankAccount: "Bank Account",
        addBankAccount: "Add Bank Account",
        date: "Date Created",
        label: "Label",
        bankName: 'Bank Name',
        accountNo: "DIBAN/Account No.",
        swiftCode: "Swift Code",
        recipientName: "Recipient Name",
        actions: "Actions",
        edit: "Edit",
        delete: "Delete"
    },
    walletAddress: {
        return: "Return",
        fundingProtfolio: "Funding portfolio",
        walletAddressList: "Wallet Address List",
        walletAddress: "Wallet Address",
        addWalletAddress: "Add Wallet Address",
        date: "Date Created",
        label:"Label",
        instruments: "Instruments",
        actions: "Actions",
        status: "status",
        edit: "Edit",
        delete: "Delete"
    },
    withdrawPop: {
        withdrawMceFiat: "Withdraw From MetaComp Fiat Account",
        withdrawMvgxFiat: "Withdraw From MVGX Fiat Account",
        description1: "Withdraw cash to your bank account",
        withdrawCrypto: "Withdraw From MetaComp Crypto Account",
        description2: "Withdraw crypto assets to your wallet address",
    },
    kycVerification: {
        individualVerification: "Individual Verification",
        corporateVerification: "Corporate Verification",
        unverified: "Unverified",
        level: "Completed KYC Level Phase ",
        phase1: "Phase 1 completed",
        phase2: "Phase 2 completed",
        needVerification: "Please ensure that you have prepared the mandatory and supporting documents you need for account opening. ",
        completedVerification: "You have been successfully completed verification",
        viewDocs: "View Docs Required",
        switchCorporate: "Switch to Corporate Verification",
        switchIndividual: "Switch to Individual Verification",
        tierScheme: "Tier Scheme",
        currentFunctionality: "Current Functionality",
        detail2: "You can access website to browse any page and any products. No trading and no funding transaction allowed.",
        availableFeatures: "Available Features for Successful Verification",
        startVerification: "Start Verification",
        verifyAgain: "Verify Again",
        kyc1: "Deposit, Withdraw and Transfer between different portfolios",
        kyc2: "Crypto Trading Transaction",
        kyc3: "Investment Products Transaction",
        error: "Verification Failed: Identity Card verification failed. Please reupload or retake the picture.",
        customerAbleTo: "Customer able to: Access the trade site to browse.​​",
        unableTo: "​Customer unable to: Trade, or pre-fund his/her account.",
        phaseOne: "Phase 1:",
        toPreview: " To Preview MetaComp Products",
        phaseOneDetail: {
            l1: "Enters your email and personal information. ",
            l2: "Customer able to: Access the trade site to browse. ",
            l3: "Customer unable to: Trade, or pre-fund his/her account.",
        },
        phaseTwo: "Phase 2:",
        toTrade: "To Trade MetaComp Product",
        phaseTwoDetail: {
            l1: "Provides copies of Identity Card and Address Proof.",
            l2: "Customer able to: Deposit fiat, trade products, withdrawals.",
        },
        completed: "Completed",
        completedDetail: "Customer able to: Deposit fiat, trade products, withdrawals.",
        ableToFait: "Customer able to: Deposit fiat, trade products ",
        unableToWithdraw: "Customer unable to: withdraw cash",
        customerAble: "Customer able to: Deposit fiat, trade products, withdrawals",
    },
    TierSchemeEnterprise: {
        tierScheme: "Tier Scheme",
        phaseOne: "Phase 1: To Preview MVGX Products",
        phaseOneDetail: {
            l1: "Enters your email and personal information.",
            l2: "Customer able to: Access the trade site to browse.",
            l3: "Customer unable to: Trade, or pre-fund his/her account.",
        },
        phaseTwo: "Phase 2: To Trade MVGX Product",
        phaseTwoDetail: {
            l1: "Customer able to: Deposit fiat, trade products.",
            l2: "Customer unable to: Withdraw.",
        },
        phaseThree: "Phase 3: Complete Documents Submission",
        phaseThreeDetail: {
            l1: "Customer able to: Deposit fiat, trade products, withdrawals. ",
        }
    },
    docsRequired: {
        return: "返回",
        individualVerification: "Individual Verification ",
        corporateVerification: "Corporate Verification ",
        docsRequired: "Docs Required"
    },
    docsTab: {
        tab: {
            l1: "Singaporeans or Singapore Permanent Residents",
            l2: " Malaysians or Non-Singaporeans (Foreigners)",
        }
    },
    singaproeans: {
        l1: "Please prepare the following documents to aid in the verification process.",
        l1Detail: {
            l1: "All documents must be translated to English.",
            l2: "All documents must be Certified True Copies (CTC). Either certified by third-party Notary Public, or Chartered Accountant, or Lawyer, or to arrange with MVGX officer to sight original documents via video-conference.",
        },
        l2: "1. A dated selfie with a clear view of NRIC​. ",
        l2_2: "2. Residential/Mailing Address Proof: One of the following dated within the past three (3) months.",
        l2Detail: {
            l1: "- Utility bills",
            l2: "- Bank statements",
            l3: "- Credit card statements",
            l4: "- Government correspondences",
        },
        l3: "3. Accredited Investor Proof: Any of the following items or combination of the following items.",
        l3Detail: {
            l1: "- Last 3 months' original computerized salary slips, latest Notice of Income Assessment, etc.",
            l2: "- Latest bank statement, CDP statement, custody holdings or share financing statements, investment portfolio statements (showing details of unit trust held, fixed income securities, etc), latest statement showing details of surrender value of insurance policies, etc",
            l3: "-  Copy of land title deed for properties",
        }
    },
    malaysians: {
        l1: "Please prepare the following documents to aid in the verification process.",
        l1Detail: {
            l1: "All documents must be translated to English.",
            l2: "All documents must be Certified True Copies (CTC). Either certified by third-party Notary Public, or Chartered Accountant, or Lawyer, or to arrange with MVGX officer to sight original documents via video-conference.",
        },
        l2: "1. A dated selfie with a clear view of Passport. Passport must be with validity of more than 6 months​. ",
        l2_2: "2. Residential/Mailing Address Proof: One of the following dated within the past three (3) months.",
        l2Detail: {
            l1: "- Utility bills",
            l2: "- Bank statements",
            l3: "- Credit card statements",
            l4: "- Government correspondences",
        },
    },
    personDetail: {
        title: "Title",
        firstName: "First Name",
        lastName: "Last Name",
        name: 'Full name as per IC or Passport',
        passportNumber: "Identity Card/Passport Number ",
        idNumber: "ID Type",
        birth: "Date of birth",
        nationality: "Nationality",
        countryOfResidence: "Country/Region of residence",
        isSingapore: "Singapore",
        others: "Others",
        countryOther: "Country/Region of Birth",
        highestEducation: "Highest Education",
        confirmAndNext: "Confirm and Next",
        same: "Same as Nationality",
        years: "The client must be over 18 years old can register.",
        rules: {
            title: "Please select Title",
            firstName: "Please enter First Name",
            lastName: "Please enter Last Name",
            passportNumber: "Please enter Passport Number",
            idNumber: "Please enter ID Number",
            birth: "Please select Date of birth",
            nationality: "Please select Country/Region",
            countryOther: "Please select Country/Region other than Singapore",
            highestEducation: "Please select Highest Education",
        },
        certifiedCard: "Certified True Copy Required of your Identity Card",
        certifiedPassport: "Certified True Copy Required of your Passport",
        note: "When certifying, please ensure the following are included:",
        note1: "1) Signature of certifier;",
        note2: "2) Name of certifier;",
        note3: "3) Role/ Position of certifier;",
        note4: "4) Date of certification; and",
        note5: "5) The certification wording (or words with similar effect): 'I have seen the original document and I hereby certify that this document is a complete and true copy of the original'.",
        ctcIdCardNote: "When certifying, please ensure the following are included:",
        ctcIdCardNote1: "1) Must have identity card or passport in hand;",
        ctcIdCardNote2: "2) Name and Date;",
        ctcIdCardNote3: "3) The certification wording (or words with similar effect): 'I agree to open an account with MetaComp and this selfie submitted is for the sole purpose of account opening.'.",
        isBlockedCountriesTips: "Your selected country is blocked, please select again.",
    },
    contactDetail: {
        mobileNumber: "Mobile Number",
        homeNumber: "Home Number",
        emailAddress: "Email Address",
        emailAddressTips: "To inform you that E-Statement is ready to access online",
        mailingAddress: "Mailing Address",
        mailingAddressTips: "Please provide the same mailing address per your account maintained with CDP(if applicable)",
        countryPostalCode: "Country/Region & Postal Code",
        postalCode: "Postal Code",
        residentialAddress: "Residential Address",
        which: "which needs to be detailed to the Unit Number",
        residentialAddressTips: "Please provide the same mailing address per your account maintained with CDP(if applicable)",
        confirmAndNext: "Confirm and Next",
        checkDetail: "Same as Residential Address",
        rules: {
            mobile: "Please enter Mobile Number",
            homeNumber: "Please enter Home Number",
            emailAddress: "Please enter Email Address",
            correctEmail: 'Please enter the correct email address',
            mailingAddress: "Please enter Mailing Address",
            countryPostalCode: "Please enter Country/Region & Postal Code",
            residentialAddress: "Please enter Residential Address",
        }
    },
    modal: {
        title: "SingPass or Manual Review",
        detail: "For Singaporean and permanent resident with Singpass account, you can retrieve data from Myinfo to complete your application.",
        tip1: "Myinfo is a Singapore government digital initiative for your convenience.Authenticated through Singpass, Myinfo is a government digital service that allows you to pre-fill online applications by auto-populating your personal data. By clicking on 'Apply via Singpass' (using Singpass), you agree to our",
        termsOfUse: " Terms of Use",
        and: "and",
        privacyStatement: "Privacy Statement.",
        manualReview: "No. Manual Review",
    },
    trading: {
        validateInput: {
            buyTriggerPriceRequired: "Buy trigger price is required",
            buyPriceRequired: "Buy Price is required",
            buyAmountRequired: "Buy Quantity is required",
            sellTriggerPriceRequired: "Sell trigger price is required",
            sellPriceRequired: "Sell Price is required",
            sellAmountRequired: "Sell Quantity is required",
            fastPriceRequired: "Price is required",
            fastAmountRequired: "Amount is required",
            updateOrderPriceRequired: "Price is required",
            updateOrderAmountRequired: "Amount is required",
            minNumRequired: "Minimum quantity is",
        }
    },
    feeSchedule: {
        feeSchedule: "Fee Schedule",
        transactionFees: "Transaction Fees",
        transactionFeesDetail: "15 bps for both maker and taker roles. charged in fiat for CIS products and in the purchased currency otherwise.",
        transactionFeesDetailMvgx: "15 bps for both maker and taker roles, charged in fiat.",
        otcFees: "OTC Fees (On Screen)",
        custodyFees: "Custody Fees",
        waived: "Waived",
        withdrawalFees: "Withdrawal Fees",
        cryptoCurrencies: "Crypto-Currencies",
        cryptoCurrenciesDetail: "Withdrawal fees are meant to cover on-chain transfer costs",
        asset: "Asset",
        fee: "Fee",
        miniAmount: "Min Amount",
        depositAndWithdrawalFees: "Deposit and Withdrawal Fees",
        fiatCurrencies: "Fiat Currencies",
        mce:'MetaComp',
        mvgx:'MVGX',
        introduce: " does not charge any fee on fiat currency deposits or withdrawals. However, correspondent banks may include wire transfer charges which may impact the final amount received.",
        OTCFeesDesk: "OTC Fees (Desk)",
        tips: "Fee is embedded in the quote.",
        otcFeesDetail:"Embedded in the quote",
    },
    footer: {
        general: "General",
        generalList: {
            l1: "Our Offerings",
            l2: "NFT Gallery",
            l3: "Our Platform",
            l4: "Listing",
            l5: "Research",
        },
        about: "About",
        aboutList: {
            l1: "Our Vision",
            l2: "Why MVGX",
            l3: "Our Team",
            l4: "Join Us",
        },
        media: "Media",
        mediaLits: {
            l1: "Blog",
            l2: "Press Releases",
            l3: "Videos",
        },
        company: "Company",
        companyList: {
            l1: "Terms of Use",
            l2: "Privacy Policy",
            l3: "Contact Us",
            l4: "FAQ",
            l5: "Trading Rules",
            l6: "Disclosures",
        },
        license: "License no. CMS 1010",
        recognised: "Recognised Market Operator",
        copyright: "Copyright © 2023 MetaVerse Green Exchange",
        detail: "The MVGX platform is operated by MetaVerse Green Exchange Pte. Ltd. (“MVGX”). MVGX is regulated by the Monetary Authority of Singapore and holds:- (1) a capital markets services license for the dealing of capital markets products  and providing custodial services in accordance with the conditions therein; and (2) a Recognised Market Operator license for the operation of an approved recognized market in accordance with the conditions therein.",
        contentMvgx: "The MVGX platform is operated by MetaVerse Green Exchange Pte. Ltd. (“MVGX”). MVGX is regulated by the Monetary Authority of Singapore and holds:- (1) a capital markets services license for the dealing of capital markets products  and providing custodial services in accordance with the conditions therein; and (2) a Recognised Market Operator license for the operation of an approved recognized market in accordance with the conditions therein.",
        contentMce: "An Integrated Payment Platform Operated by MetaComp Pte. Ltd., its parent company, and affiliates",
        contentMce2:"Copyright © 2023 MetaComp All Rights Reserved",
    },
    comHeader: {
        spotOrders: "Spot Orders",
        investmentOrders: "Investment Orders",
        OTCOrders: "OTC Orders",
        spotTradeHistory: "Spot Trade History",
        portfolioOverview: "Portfolio Overview",
        MCEFiatPortfolio: "MetaComp Fiat Portfolio",
        MVGXFiatPortfolio: "MVGX Fiat Portfolio",
        MCECryptoPortfolio: "MetaComp Crypto Portfolio",
        MVGXInvestmentPortfolio: "MVGX Investment Portfolio",
        bankAccount: "Bank Account",
        walletAddressBook: "Wallet Address Book",
        depositHistory: "Deposit History",
        withdrawalHistory: "Withdrawal History",
        transferHistory: "Transfer History",
        statement: "Statement",
        movementCharges: "Movement & Charges",
        KYCLevel: "KYC Level",
        accountID: "Account ID",
        personalDetails: "Personal Details",
        KYCVerification: "KYC Verification",
        securityVerification: "Security Verification",
        subAccounts: "Sub-Accounts",
        logout: "Log out",
        logIn: "Log In",
        signup: "Sign up",
    },
    tradeList: {
        title: "Trading Hot List",
        viewMore: "View more",
        name: "Trading Pair",
        lasePrice: "Last Price",
        change: "24H Change",
        markets: "Markets",
        trade: "Trade",
    },
    transferForm: {
        from: "From",
        to: "To",
        amount: "Amount",
        availableAmount: "Available Amount",
        tips: "Not enough balance,please deposit and try again.",
        deposit: "Deposit",
        portfolioTransfer: "portfolioTransfer",
        submit: "Submit",
        continueToTransferAgain: "Transfer",
    },
    banner: {
        tradeNow: "Trade Now",
        signUp: "Sign Up",
    },
    downLoadApp: {
        tradeAnywhereAnytime: "Trade Anywhere Anytime",
        detail: "Scan the QR Code to Download the Mobile APP",
        downloadApp: "Download App",
    },
    listImage: {
        fast: "On/Off-Ramp",
        fastDetail: "Fast way to on-ramp/off-ramp with Asia's leading commercial banks",
        OTC: "OTC",
        OTCDetail: "Trade large quantities and minimize slippage",
        invest: "Invest",
        investDetail: "Explore investment opportunities through MAS CISNet-registered CIS products listed on our platform",
        competitiveFees: "Competitive Fees",
        competitiveFeesDetail: "Find out more about our fee schedule",
        payment: "Payment",
        gateway: "Integrated Fiat/Stable Coin Payment Gateway"
    },
    mobileBanner: {
        title: "Start Your Journey On MetaComp",
        detail: "Your partner into the digital space",
        signUp: "Sign Up",
    },
    quickAccess: {
        title: "Quick Access",
        makeADeposit: "Make a deposit",
        makeADepositTips: "Fund your account now",
        deposit: "Deposit",
        completeKYC: "Complete KYC",
        completeKYCTips: "Complete your KYC and begin your journey with us",
        enter: "Enter",
        startTrading: "Start trading",
        startTradingTips: "Sell and buy your digital assets and explore more",
        manageSubAccounts: "Access Sub-Accounts",
        manageSubAccountsTips: "Quick Access to manage your Sub-Accounts",
        start: "Start"
    },
    bannerMce: {
        tradeNow: "Trade Now",
        signUp: "Sign Up",
        tip1: "MetaComp is a licensed digital asset financial service platform operated by Singapore-based MetaComp Pte. Ltd., its parent company, and affiliates.",
        mce: {
            b1: "MetaComp: Your Trusted Partner into the Digital Asset Realm",
            b2: "Reveal your true potential by tapping into crypto and traditional finance",
            title: "MetaComp: Your Trusted Partner Bridging Digital Assets with Traditional Financial Services",
        },
        ourVision: {
            b1: "Our vision: To be the bridge between Digital Assets and Traditional Financial Services",
            b2: "Our mission: To provide integrated Digital Asset financial services in a secure, compliant and efficient way",
        },
        productsServices: {
            b1: "Our Products and Services",
            b2: "Ramp on and ramp off capability in USD, EUR and SGD and related services in partnership with leading Asian banks",
            b3: "OTC trading with minimal slippage, partnering with some of the largest liquidity providers",
            b4: "Custody services with the highest levels of security",
            b5: "Access to MAS CISNET-registered Collective Investment Schemes products listed on our platform",
        },
        congrat:{
            b1: "Congratulations!",
            b2: "MetaComp for having been granted its Major Payment Institution license under",
            b3: "Payment Services Act ",
            b4: "by Monetary Authority of Singapore! ",
            b5: "Click Here to Start Trading with Us",
        },
    },
    bitcoinIndex: {
        title: "MVGX Bitcoin Index",
        high: "24h High",
        low: "24h Low",
        consist: "The MVGX Bitcoin Index Price consists of the price of Bitcoin computed over the last 30-minute moving average, using the average of equally weighted executed prices on six crypto exchanges, excluding outlier data. The six exchanges are MVGX, BitFinex, Coinbase, FTX, Gemini, Kraken.",
    },
    methIndex: {
        title: "MVGX Ether Index",
        high: "24h High",
        low: "24h Low",
        consist: "The MVGX Ether Index Price consists of the price of Bitcoin computed over the last 30-minute moving average, using the average of equally weighted executed prices on six crypto exchanges, excluding outlier data. The six exchanges are MVGX, BitFinex, Coinbase, FTX, Gemini, Kraken.",
    },
    allTableList: {
        name: 'Name',
        lastPrice: "Last Price",
        change: "24H Change",
        high: "High",
        low: "Low",
        trade: "Trade",
    },
    favorites: {
        noResults: "No results. Go to",
        all: "all",
        market: "market to add your favorite tokens.",
    },
    favoritesTableList: {
        name: 'Name',
        lastPrice: "Last Price",
        change: "24H Change",
        high: "High",
        low: "Low",
        trade: "Trade",
    },
    madeTable: {
        offer: "Offer",
        offerPrice: "Offer Price",
        USDPrice: "USD Price",
        expiration: "Expiration",
        from: "From",
        status: "Status",
    },
    moreFC: {
        title: "More Favorite Collections",
        ethereum: "Ethereum (ERC-721)",
        ERC: "ERC",
        price: "Price：",
        daysLeft: "2 Days left",
    },
    nftHonor: {
        title: "MY NFT Collections",
        create: "Create",
        nftHONOR: "NFT-HONOR",
        num: "0x4f12...F84f",
        date: "Joined September 2021",
    },
    nftList: {
        price: "Price：",
    },
    offersMade: {
        title: "Offers Made"
    },
    offersReceived: {
        title: "Offers Received",
        acceptAll: "Accept All",
    },
    receivedTable: {
        offer: "Offer",
        offerPrice: "Offer Price",
        USDPrice: "USD Price",
        expiration: "Expiration",
        from: "From",
    },
    openTable: {
        tabName: "Open Orders",
        viewMore: "View More",
        createdAt: "Created At.",
        orderID: "Order ID",
        symbol: "Symbol",
        orderType: "Order Type",
        buySell: "Buy / Sell",
        price: "Price",
        amount: "Amount",
        status: "Status",
        actions: "Actions",
        update: "Update",
        cancel: "Cancel",
    },
    orderTable: {
        tabName: "Order History",
        createdAt: "Created At.",
        orderID: "Order ID",
        symbol: "Symbol",
        orderType: "Order Type",
        buySell: "Buy / Sell",
        price: "Price",
        amount: "Amount",
        actions: "Actions",
    },
    tradeTable: {
        createdAt: "Created At.",
        tradeID: "Trade ID",
        symbol: "Symbol",
        buySell: "Buy / Sell",
        price: "Price",
        amount: "Amount",
        total: "Total",
        fee: "fee",
        status: "Status",
    },
    user: {
        participantName: "Participant Name:",
        accountNumber: "Account Number:",
        date: "Date of generation:"
    },
    addressModel: {
        title: "Edit Mail Address",
        address: "Mail Address",
        cancel: "Cancel",
        save: "Save",
    },
    contactNameModel: {
        title: "Edit Contact Name",
        contactName: "Contact Name",
        cancel: "Cancel",
        save: "Save",
    },
    contactNumberModel: {
        title: "Edit Contact Number",
        country: "Country/Region",
        contactNumber: "Contact Number",
        cancel: "Cancel",
        save: "Save",
        pleaseHolder: 'Please enter the Contact Number'
    },
    descriptionModel: {
        title: "Edit Description",
        description: "Description",
        cancel: "Cancel",
        save: "Save",
    },
    kycVerificationCom: {
        description: "Please select your account type to start your KYC Verification",
        individual: "Individual",
        corporate: "Corporate",
        next: "Next",
        return: 'Return',
        individualStart: "Please specify your individual user type",
        individualSingaporean: "Singaporean or Singapore Permanent Resident",
        individualNoSingaporean: "Non-Singaporean Individual",
        corporateStart: "Please specify your corporate type",
        corporateSingaporean: "Singapore Corporation",
        corporateNoSingaporean: "Non-Singapore Corporation",
        start: "Start",
        more: "More stringent requirements and longer service time apply for high-risk customers.",
        please: "Please note that once started, you will not be able to modify your type.",
        pleaseContinue: "Please Choose to Continue",
        continueToKYCForm: "Continue to KYC Form",
        later: "I will do this later",
        uploadIPA: "Upload IPA as supporting document",
        pdfTip: "Only Pdf, Doc and Docx no more than 5MB are accepted.",
        submit: "Submit",
    },
    loginPasswordModel: {
        tips: "Tips",
        detail: "When resetting your password, you will be logged out automatically",
        cancel: "Cancel",
        confirm: "Confirm",
    },
    nameModel: {
        title: "Edit Name",
        name: "Name",
        cancel: "Cancel",
        save: "Save",
        firstName: "First Name",
        lastName: "Last Name",
    },
    preSettings: {
        title: "Preference Settings",
        order: 'Order Confirmation Reminders',
        orderDetail: "If the order reminder function is enabled, it will need to be reconfirmed every time an order is submitted.",
        incompleted: "Incompleted",
        manage: "Manage",
        notificationLanguage: "Notification Language",
        notificationLanguageDetail: "Email and mobile application  push notification language settings.",
        onSiteNotifications: "On-site Notifications",
        onSiteNotificationsDetail: "Once enabled, you will receive relevant notifications within the app and website.",
        marketingEmails: "Marketing Emails",
        marketingEmailsDetail: "Select whether you want to receive marketing emails from us.",
    },
    subAccounts: {
        title: "Sub-Accounts",
        create: "Create Sub-Accounts",
    },
    loginPassword: {
        title: "Login Password",
        tips: "Withdrawals and fiat sale will be suspended for 24 hours if you change the login password.",
        oldPassword: "Old Password",
        newPassword: "New Password",
        comfirmPassword: "Comfirm Password",
        confirm: "Confirm",
        updateSuccess: "Update Successful",
        rules: {
            message: "This item must be filled.",
        }
    },
    mailNotificationSettings: {
        title: "Mail Notification Settings",
        matchedOrdersNotification: "Matched orders notification",
        tip: "We will notify you through email when there is a limit order matched.",
        emailNotification: "Email notification",
        radio1: "Notify regardless if I was online or not (Recommended)",
        radio2: "Only notify when I’m offline",
        radio3: "Do not notify, neither online or offline",
        successfully: "Successfully",
        failed: "Failed",
    },
    subAccountsIndex: {
        return: "Return",
        title: "Sub-Accounts",
        assets: "Assets",
        estimatedValuation: "Estimated valuation"
    },
    SubTable: {
        label: "Label",
        date: "Date Received",
        email: "Email",
        accountNo: "Account No.",
        status: "Status",
        actions: "Actions",
        edit: "Edit",
        transfer: "Transfer",
        login: 'Log in',
        remarks: "Remarks",
    },
    buyForm: {
        buy: "Buy",
        sell: "Sell",
        buy2: "Buy",
        sell2: "Sell",
        order: "Order",
        quantity: "Quantity",
        amount: "Amount of",
        to: "to",
        required: "required",
        bestPrice: "Best price",
        marketPrice: "at the best market price",
        pricePer: "at a fixed price per",
        orderType: "Order type",
        total: "Total",
        of: "of",
        estimated: "Estimated of",
        amount2: "Amount of",
        fastBuy: "Fast Buy",
        fastSell: "Fast Sell",
        limitPrice: "Limit price",
        estPrice: "Est.price",
        limit: "Limit",
        market: "Market",
        best: "Best Market Price",
    },
    buyPop: {
        confirmYourOrder: "Confirm your order",
        orderDetails: "ORDER DETAILS",
        orderType: "Order type",
        limitPrice: "Limit price",
        freeCurrency: "Fee currency",
        estimatedFee: "Estimated fee",
        estimatedUSDToSpend: "Estimated USD to spend",
        submitOrder: "Submit Order",
        cancel: "Cancel",
    },
    canclePop: {
        tips: "Are you sure you want to cancel the selected order?",
        confirm: "Confirm",
        cancel: "Cancel",
    },
    fastBuy: {
        order: "Order"
    },
    updatePop: {
        confirmYourOrder: "Confirm your order",
        buy: "Buy",
        limit: "Limit",
        orderDetails: "ORDER DETAILS",
        orderType: "Order type",
        buyLimit: "Buy (Limit)",
        limitPrice: "Limit price",
        amount: "Amount",
        freeCurrency: "Fee currency",
        estimatedFee: "Estimated fee",
        estimatedUSDToSpend: "Estimated USD to spend",
        submitOrder: "Submit Order",
        cancel: "Cancel",
    },
    fastTradeIndex: {
        title: "Fast Trade"
    },
    dealStatus: {
        title: "The following prerequisites must be met before you can trade.",
        one: "1. You must complete Level 1 KYC before you can trade.",
        gotoKyc: "Go to KYC now",
        two: "2. Accept MVGX Market Trading Rules.",
        exit: "Exit",
        agree: "Click to view rules and agree",
    },
    limt: {
        descriptionOne: "A Good-Til-Cancelled (GTC) order is an order to buy or sell an instrument that lasts until the order is completed or canceled",
        GTC: "GTC",
        descriptionTwo: "An Immediate-Or-Cancel (IOC) order is an order to buy or sell an instrument that must be executed immediately. Any portion of an IOC order that cannot be filled immediately will be cancelled",
        IOC: "IOC",
        descriptionThree: "A Fill or Kill (FOK) order is an order that is directed to be executed immediately at the market or a specified price or canceled if not filled",
        FOK: "FOK",
        available: "Available：",
    },
    market: {
        descriptionOne: "A Good-Til-Cancelled (GTC) order is an order to buy or sell an instrument that lasts until the order is completed or canceled",
        GTC: "GTC",
        descriptionTwo: "An Immediate-Or-Cancel (IOC) order is an order to buy or sell an instrument that must be executed immediately. Any portion of an IOC order that cannot be filled immediately will be cancelled",
        IOC: "IOC",
        descriptionThree: "A Fill or Kill (FOK) order is an order that is directed to be executed immediately at the market or a specified price or canceled if not filled",
        FOK: "FOK",
        available: "Available：",
    },
    dealIndex: {
        placeAnOrder: "Place an Order",
        tips: "Sorry, unavailable for trading. Our trade time is",
        available: "Available in Crypto Portfolio",
        transfer: "Transfer",
    },
    lastPrice: {
        lastPrice: "Last Price",
    },
    marketTrades: {
        marketTrades: "Market Trades",
        time: "Time",
        amount: "Amount",
        price: "Price",
    },
    orderSoketIndex: {
        orderBook: "Order Book",
        price: "Price",
        amount: "Amount",
        total: "Total",
    },
    historyTable: {
        createdAt: "Created At.",
        orderID: "Order ID",
        symbol: "Symbol",
        orderType: "Order Type",
        buySell: "Buy / Sell",
        executionPrice: 'Execution Price',
        amount: "Amount",
        status: "Status",
    },
    tradeHeader: {
        lastPrice: "Last Price",
        high: "24h High",
        low: "24h Low",
        volume: "24h Volume",
        amount: "24h Amount",
    },
    addQuest: {
        corporateVerification: "Corporate Verification",
        additionalQuestions: "List of Additional Questions",
        question: "Question",
        answer: "Answer",
        uploadFile: "Upload File",
        tips: "Drag the file to here or click",
        this: "this",
        upload: "to upload",
        submit: "Submit",
    },
    complete: {
        thankYou: "Thank You!",
        successfully: "Your application has been successfully submitted; you will be notified via email shortly.",
        tip: "Your account is being reviewed. Account reviews usually take some time. During this time, you won’t be able to trade crypto or withdraw funds from your portfolio. As soon as the review is finished, we’ll send you an email. Please watch your inbox for updates.",
    },
    startVerification: {
        select: "Select the type of account you wish to open",
        tip: "Please note that once started, you will not be able to modify your type or switch your entity to the",
    },
    addressProof: {
        //不同
        title: "Mailing/Business Operation Address Proof",
        detail: "If the company's business operation address is different from the company's registered address, please provide business operation address proof (to be dated within the last 3 months)",
        mailingAddress: "- Any of the following items can be used as residential address proof: ",
        a1: "(a) Government Related Documents",
        a2: "(a) Government statement",
        b1: "(b)Tenancy/Lease Agreement",
        b2: "(b) Bank statement",
        b3: "(c) Bank statement",
        c1: "(d) Utility bill",
        c2: "(c) Utility and telco bill",
        d: "(e) Credit card statements",
        please: "Please note that the document must be dated within the past 3 months.",
        drag: "Drag the file to here or click",
        this: "this",
        toUpload: "to Upload",
        confirm: "Confirm and Next",
    },
    enterprisePhase3Index: {
        return: "Return",
        corporateVerification: "Corporate Verification",
        information: "Information to Key In",
    },
    backSide: {
        backSide: "Back Side",
    },
    frontSide: {
        frontSide: "Front Side",
        upload: "Upload"
    },
    innerLoadCon: {
        drag: "Click here to upload",
        this: "this",
        toUpload: "to Upload",
        onlyPDF: "Only PDF file of no more than 5 MB are supported.",
    },
    investor: {
        l1: "- Bank statement to reflect financial holdings, i.e., net asset of S$10 million (or the equivalent in foreign currencies), can be accepted.",
        l2: "- Crypto wallet assets holding statements are also accepted as part of financial holding of the S$10 million.",
        l3: "- Annual financial statements (balance sheet), or management accounts (balance sheet) to be provided within 30 calendar days.",
    },
    issue: {
        HK: "HK Companies (ICRIS)",
        cayman: "Cayman Island Companies (Cayman Islands General Registry)",
        malaysiaCompanies: "Malaysia Companies (SSM)",
        taiwanCompanies: "Taiwan Companies (GCIS)",
        indonesiaCompanies: "Indonesia Companies (Indonesia Registry)",
        south: "South Korea Companies (South Korea Registry)",
        united: "United Arab Emirates (Company Registry Information)",
    },
    documentList: {
        l1: 'Hong Kong (NAR1)',
        l2: 'Malaysia (SSM)',
        l3: 'Taiwan (GCIS)',
        l4: 'Indonesia (Indonesia Registry)​',
        l5: 'South Korea (South Korea Registry)​',
        l6: 'Cayman Islands (Certificate of Incumbency)',
        l7: 'British Virgin Islands (Certificate of Incumbency) ​',
        l8: 'United Kingdom (Companies House extract)',
        l9: 'United Arab Emirates (Company Registry Information)',
    },
    memorandumText: {
        l1: "Full set of M&A / Constitution",
        l2: "-  Certified by third-party notary, or Chartered Accountant, or Lawyer, or to arrange with MVGX officer to sight original documents via video-conference"
    },
    enterpriseOverview: {
        l1: "-  An illustration of the company's ownership structure showing Person(s) / Shareholder(s) with >=25% indirect and/or direct shareholdings(to be signed and dated by Director)",
        l2: "-  Corporate Secretarial document to verify the shareholding structure (ie: intermediate owners, Shareholders)",
    },
    passportIDText: {
        l1: "-  Please provide copy of Identity Card or Passport (with a validity of more than 6 months).",
        l2: "-  Please also (a) upload a dated selfie with a clear view of the Identity Card or Passport, or (b) arrange with MVGX officer to sight original document via video-conference.",
        l3: "-  Shareholder with >=25% shareholding",
    },
    residentialAddress: {
        l1: "Signature of certifier",
        l2: "Name of certifier",
        l3: "Role/ Position of certifier",
        l4: "Date of certification.",
        l5: "Shareholder with >=25% shareholding",
    },
    secretarialDocuments: {
        title: "Corp secretarial document required for corporates incorporated in the following territories",
        HongKong: "Hong Kong (NAR1)",
        malaysia: "Malaysia (SSM)",
        taiwan: "Taiwan (GCIS)",
        indonesia: "Indonesia (Indonesia Registry)",
        southKorea: "South Korea (South Korea Registry)​",
        caymanIslands: "Cayman Islands (Certificate of Incumbency)",
        britishVirginIslands: "British Virgin Islands (Certificate of Incumbency)",
        unitedKingdom: "United Kingdom (Companies House extract)",
        unitedArabEmirates: "United Arab Emirates (Company Registry Information)"
    },
    companyDetails: {
        nameOfCorporation: "Name of Corporation",
        incorporation: "Place of Incorporation/Registration",
        country: "Country of Incorporation / Registration",
        date: "Date of Incorporation/Registration",
        telephoneNo: "Contact Number",   //不同
        activity: "Business Activity",  //不同
        registeredAddress: "Registered Address",
        registeredAddressTips: "it need to consistent with Certificate of Incorporation / Registration.",
        mailingAddress: "Business or Mailing Address", //不同
        mailingAddressTips: "which needs to be detailed to the Room number",
        postalCode: "Country/Region & Postal Code",
        confirmAndNext: "Confirm and Next",
        rules: {
            nameOfCorporation: "Please enter Name of Corporation",
            incorporation: "Please select Place of Incorporation / Registration",
            country: "Please select Country (ies) Where Business is Conducted",
            date: "Please select Date of Incorporation / Registration",
            telephoneNo: "Please enter Telephone No.",
            activity: "Please enter Principal Business Activity",
            registeredAddress: "Please enter Registed Address",
            mailingAddress: "Please enter Mailing Address",
            postalCode: "Please enter Country/Region & Postal Code",
        }
    },
    copyofDocuments: {
        //改动很大 不同
        one: "Certificate of Incorporation",
        upload: "Upload as this template File, cilck to download",
        two: "Copies of File Upload",
        two2: "Please select the Certification Mode",
        three: "Latest ACRA Business Profile (dated within the last 3 months)",
        four: "Corporate Secretarial Documents to verify Intermediate Owners, Shareholders & Directors (dated within the last 3 months) ",
        fourDe: "Corp secretarial document required for corporates incorporated in the following territories",
        five: "Certificate of Good Standing issued by the issuing authority (dated within the last 3 months)",
        six: "Memorandum and Articles of Association / Constitution",
        sixDe: "Upload Certified True Copy of M & A",
        sixEn: "Please select the Certification Mode",
        seven: "Passport/ID of AT LEAST (1) Executive Director, Shareholders* and ALL Authorized Signatory(ies)",
        eight: "An illustration of the company's ownership structure",
        eightDe: "An illustration of the company's ownership structure showing Person(s) / Shareholder(s) with >=25% indirect and/ordirect shareholdings(to be signed and dated by Director)",
        eightEn: "When certifying, please ensure the following are included",
        nine: "Corporate Secretarial document",
        nine1: "Corporate Secretarial document to verify the shareholding structure (ie: intermediate owners, Shareholder(s) with >=25%)",
        nine2: "Corporate secretarial document required for corporates incorporated in the following territories:",
        ten: "Board Resolution",
        ten1: "Upload refer to this template File, click to download",
        ten2: "If you are unable to provide this file at once, you must need to submit by 90 calendar days and 1st week reminder. Otherwise, your account will be suspended.",
        twoRadioList: {
            one: "Certified by third-party notary public, or chartered accountant, or lawyer",
            two: "Arrange with MVGX officer to sight original documents via video-conference",
            twoDetail: " MVGX officer will contact you by email and/or mobile phone. If no one contact you within 2 Hours during Singapore office hour, Please contact ",
        },
        threeDetail: "If extracted from online Government registry directly, provide proof of extraction such as URL links or receipts, else certified true copy is required.",
        sevenRadioList: {
            one: "A dated selfie with a clear view of Passport",
            add: "Add New Passport/ID",
            tips: "Ensure that the text on the photo is clearly visible. Only JPG/PNG images no more than 5 MB are supported.",
            please: "Please put a sign in the selfie say",
            agree: "I agree to open an account on MetaComp",
            two: "Arrange with MVGX officer to sight original documents via video-conference",
            twoDetail: "MVGX officer will contact you by email and/or mobile phone.If no one contact you within 2 Hours during Singapore office hour, Please contact",
            three: "Mail Certified Ture Copied",
            threeDetail: "Certified by third-party Notary Public, or Chartered Accountant, or Lawyer.",
            email: "Address: 75 Ayer Rajah Crescent #02-15 Singapore 139953",
        },
        tenContent: {
            title: "You have to satisfy ANY ONE of the Following in order to be qualified as an accredited investor.",
            detail: {
                one: "Bank statement to reflect financial holdings, i.e., net asset of S$10 million (or the equivalent in foreign currencies), can be accepted.",
                two: "Crypto wallet assets holding statements are also accepted as part of financial holding of the S$10 million.",
                three: "Annual financial statements (balance sheet), or management accounts (balance sheet) to be provided within 30 calendar days.",
            },
        },
        rules: "I have read, understood and agree with the the terms and conditions in the Trading Rules.",
        confirm: "Confirm and Submit",
        pleaseAgree: "please select agree",
        pleaseSelect: "please select",
        pleaseFile: "please select file",
    },
    enterprise: {
        return: "Return",
        corporateVerification: "Corporate Verification",
        information: "Information to Key In",
    },
    phase3: {
        title: "Singaporeans & Singapore Permanent Residents Individual",
        income: "Accredited Investor must prove ANY ONE of the following",
        follow: "Please tick one of the following options.",
        incomeIdTips: "You have to satisfy ANY ONE of the Following in order to be qualified as an accredited investor.",
        accreditedInvestorProof: "Please upload any one of the following documents",
        accreditedInvestorProofDetail: {
            title: "Any of the following items or combination of the following items",
            l1: "- Last 3 months' original computerized salary slips, latest Notice of Income Assessment, etc.",
            l2: "- Latest bank statement, CDP statement, custody holdings or share financing statements, investment portfolio statements (showing details of unit trust held, fixed income securities, etc), latest statement showing details of surrender value of insurance policies, etc.",
            l3: "- Copy of land title deed for properties.",
            l4: "- Crypto wallet statement."
        },
        confirm: "Confirm and Submit",
        note: "Note",
        l1: "All documents are to be translated to English or have English Language.",
        l2: "Provides Proof of Accredited Investor status within 30 days.",
        l3: "The account shall be suspended if the customer failed to provide Proof of AI after 30 calendar days.",
    },
    acknowledge: {
        income: "Income or networth",
        declaration: "Declaration",
        yes: "YES",
        no: "No",
        confirm: "Confirm and Submit",
        declarationList: {
            l1: "Have you ever been convicted of any offences (except minor traffic offences)",
            l2: "Have you ever been sanctioned by any professional body of regulatory body(ies)?",
            l3: "Have you ever been adjudged bankrupt or insolvent?",
        }
    },
    employDetails: {
        employmentStatus: "Employment Status",
        occupation: "Occupation",
        employer: "Employer / Company Name",
        industry: "Industry / Nature of Business",
        officeNumber: "Office Number",
        annualIncome: "Annual Income",
        employerAddress: "Employer Address",
        country: "Country/Region & Postal Code",
        confirm: "Confirm and Next",
        rules: {
            employmentStatus: "Please enter Employment Status",
            occupation: "Please enter Occupation",
            employer: "Please enter Employer / Company Name",
            industry: "Please enter Industry / Nature of Business",
            officeNumber: "Please enter Office Number",
            annualIncome: "Please select Annual Income",
            employerAddress: "Please enter Employer Address",
            country: "Please enter Country/Region & Postal Code",
        }
    },
    networth: {
        title: "Source of your funds that will be used for trading",
        tip1: "Source of your accumulated wealth",
        tip2: "Same as Source of Funds",
        country: "Please state all country (ies) of origin for source (s) of wealth declared",
        singapore: "Singapore",
        others: "Others",
        confirm: "Confirm and Next",
        pleaseSelect: "Please select",
    },
    taxIncidence: {
        title: "If you were born in the U.S. but are no longer a U.S. Tax Resident, please provide the following documents:",
        certificate: "- Certificate of loss of nationality of the U.S.",
        form: "- Form I-407",
        question: "What is/are your Country (ies) of Tax Residence?",
        radioList: {
            one: "Singapore Refer to NRIC for Taxpayer Identification Number(TIN)",
            two: "United State of America Social Security No. (”SSN”)",
            three: "Other Countries Please provide information of other tax residency (ies) below",
        },
        TINAvailable: "If TIN is not available, select a reason",
        TINAvailableDetail: {
            title: "Please provide one of the following documents from ALL of the countries which you are a tax resident of:",
            l1: "- Passport",
            l2: "- National identity card",
            l3: "- Govenment issued identity document",
        },
        reasonCountry: "Reason country of address outside Tax Residency",
        pleaseExplain: "Please explain why the country of your address differs from the Tax Residency (ies) declared.",
        confirm: "Confirm and Next",
        reasonData: {
            l1: "No Tax obligation (e.g. minor, retiree, new citizen)",
            l2: "TIN is not issued by country",
            l3: "TIN is not required by country ",
            l4: "Unable to obtain a TIN Provide reason",
        },
        reasonDeferData: {
            l1: "This is one of my many addresses",
            l2: "This is the address of a trusted person (e.g. family member, relative, personal assistant)",
            l3: "This is a temporary address  (e.g. for studies, work)",
            l4: "Others Please specify",
        }
    },
    formateImage: {
        good: "Good",
        notCropped: "Not cropped",
        notBlur: "Not blur",
        notReflection: "Not reflection",
    },
    individualAddressProof: {
        one: "A dated selfie with a clear view of Passport",
        two: "Arrange with MVGX officer to sight original documents via video-conference",
        twoDetail: "MVGX officer will contact you by email and/or mobile phone.If no one contact you within 2 Hours during Singapore office hour, Please contact",
        three: "Mail Certified Ture Copied",
        threeDetail: "Certified by third-party Notary Public, or Chartered Accountant, or Lawyer.",
        email: "Address: 75 Ayer Rajah Crescent #02-15 Singapore 139953",
        tips: "Click “+” icon to upload your photo. Please ensure the photo is clear. Only JPG/PNG images no more than 5 MB are accepted.",
        frontSide: "Front Side",
        please: "Please put a sign in the selfie say",
        agree: "I agree to open an account on MetaComp",
        pleaseCheck: "Please the check",
        four: "Residential/Mailing Address Proof",
        fourDetail: "One of the following dated within the past three (3) months",
        uploadPDF: "Click here to upload.",
        this: "this",
        toUpload: "or click here to upload.",
        confirm: "Confirm and Next",
    },
    certifiedTrueCopies: {
        one: "A dated selfie with a clear view of ID/Passport",
        two: "Arrange with MVGX officer to sight original documents via video-conference",
        three: "Mail Certified true copy",
        errMsg: "You have to satisfy ANY ONE of the Following in order to be qualified as an accredited investor.",
        tips: "Ensure that the text on the photo is clearly visible. Only JPG/PNG images no more than 5 MB are supported.",
        please: "Please put a sign in the selfie say",
        agree: "I agree to open an account on MetaComp",
        twoDetail: "A MVGX certification officer will contact you shortly to arrange a video conference.",
        send: "Send mail to",
        minutes: "if you haven't received a response after 30 minutes.",
        threeDetail: "Certified by third-party Notary Public, or Chartered Accountant, or Lawyer.",
    },
    taxResidency: {
        one: "Specify your TIN",
        onePlease: "Please enter your TIN",
        country: "Country/Region of tax residence",
        placeholderCountry: "Select country of tax residency",
        TIN: "TIN",
        TINAvailable: "If no TIN is available, please select one of the following reasons",
        reasonTIN: "Reason for missing TIN",
        reason: "Reason",
        TINRequired: "TIN is required. If no TIN is available, please select reason for no TIN",
        add: "+ Add a new Tax Residency",
        tips: "If your country/jurisdiction of tax residence did not include the country of your address, please tick the following box and confirm that:",
        checkTips1: "I am not a tax resident of the country(ies) where I am residing. By checking this box, I also confirm that I am not a tax resident of any countries other than those I have declared above.",
        pleaseCheck: "Please chenck",
        pleaseEnter: "Please enter",
        checkTips2: "I have read, understood and agree with the the terms and conditions in the",
        tradingRules: "Trading Rules",
        confirm: "Submit",
        note: 'Note',
        note1: "Under the Foreign Account Tax Compliance (FATCA) and Common Reporting Standard (CRS), we are required to determine the tax residence status of our account holder. If you are a US Person or tax resident outside the country where your account is held, we may need to give the national tax authority your account information, which may then be shared with different countries’ tax authorities.",
        note2: "Please complete this Self-Certification Form so that we hold accurate and up-to-date information about your tax residence status. If there are subsequent changes to your circumstances, please let us know by providing an updated Self-Certification Form promptly.",
        note3: "If you have any questions on how to determine your tax residence status, please consult a professional tax advisor.",
        note4: "If you have require further details, please consult your tax/legal adviser or local tax authority.",
        note5: "It is important for you to provide us with complete and accurate information in this form, as these are pursuant to requirements under Singapore Income Tax Act (Chapter 134) and its subsidiary legislation.",
        chooseTaxNumber: "Choose type of your Tax Identication Number ('TIN')",
        taxNumberOne: 'I am solely a tax resident of Singapore and do not have foreign tax residency',
        taxNumberTwo: 'I am currently a Tax Resident of the following jurisdictions (including Singapore if applicable)',
        tinTypeOne: "My TIN is my NRIC or Foreign Identification Number",
        tinTypeTwo: "My TIN is NOT my NRIC or Foreign Identification Number",
    },
    individual: {
        return: "Return",
        individualVerification: "KYC Verification ",
        information: "Information to Key In",
    },
    statusDetail: {
        KYCStatus: "KYC Status",
        questionDesc: "Your application is being processed. Please wait for approval. click this view additional request.",
        sorryDesc: "the information you submitted has not been approved. Please click “Verify Again” button to re-submit.",
        thanksDesc: "Your application has been approved. ",
        sorry: "Sorry!",
        thanks: "Thank you very much!",
        phase1: "Phase 1 Verified by MVGX / MetaComp",
        phase2: "Phase 2 Application Submitted",
        phase22: "Phase 2 Verified by MVGX / MetaComp",
        phase3: "Phase 3 Application Submitted",
        phase33: "Phase 3 Verified by MVGX / MetaComp",
        phaseInfo1a: "Customer: Enters his/her email and password for account registration",
        phaseInfo1b: "MVGX Action: System verifies customer's email.",
        phaseInfo1c: "Expected Service Time: Instantaneous",
        phaseInfo1d: "Customer able to: Access the trade site to browse.",
        phaseInfo1e: "Customer unable to: Trade, or pre-fund his/her account.",
        emailA: "Email Address Verification",
        Log: "Logged in using the password",
        inPhaseInfo2a: "Customer: Provides copies of Identity Card and Address Proof.",
        inPhaseInfo2b: "Estimated Completion Time: 15 Minutes",
        inPhaseInfo3a: "Expected Service Time: 30 Minutes (unless negative information discovered)",
        inPhaseInfo3b: "After you submit information, we will run the negative screening process.",
        inPhaseInfo3c: "Customer able to: Deposit fiat, trade products",
        inPhaseInfo4a: "Expected Time: 15 Minutes",
        inPhaseInfo4b: "The information you submitted is incomplete. Please complete Phase 2 first.",
        inPhaseInfo5a: "MVGX Action:  Validate Proof of Accredited Investor based on your Phase 3 application submitted.",
        inPhaseInfo5b: "Expected Service Time: 1 day after receiving documents",
        inPhaseInfo5c: "Customer able to: Withdraw cash via linked bank account",
        coPhaseInfo2a: "Customer: Provides copies of Identity Card and Address Proof.",
        coPhaseInfo2b: "Estimated Completion Time: 15 Minutes",
        coPhaseInfo3a: "MVGX Action: Runs the negative screening process based on your Phase 2 application submitted.",
        coPhaseInfo3b: "Expected Service Time: 2 hours",
        coPhaseInfo3c: "Customer able to: Deposit, trade, withdraw.",
        coPhaseInfo4a: "Expected Time: 15 Minutes",
        coPhaseInfo4b: "The information you submitted is incomplete. Please complete Phase 2 first.",
        coPhaseInfo2Tip: "For Enhanced Due Diligence customers (i.e. high risk), more stringent requirements apply.There shall be no sales and marketing activities, onboarding or provision of any other DPT ervices within PRC.",
    },
    kycStatus: {
        return: 'Return',
        individualVerification: 'Individual Verification',
        corporateVerification: 'Corporate Verification',
        information: "Information to Key In",
        explain: "Please prepare the following documents before you proceed to complete the form",
        view: "View Docs Required",
        more: "More stringent requirements and longer service time apply for high-risk customers.",
        individualSingapore: "Individual: Singaporeans & Singapore Permanent Residents",
        nonSingapore: "Non Singaporean",
        singaporeCompanies: "Singapore Companies",
        nonSingaporeCompanies: "Non-Singapore Companies",
    },
    createPop: {
        accountLabel: "Account Label",
        accountEmail: "Account Email",
        modifyPassword: "Modify Password",
        accountPassword: "Account Password",
        submit: "Submit",
        placeholder: {
            accountLabel: "1~24 Characters",
            accountEmail: "6 Characters",
            accountPassword: "One uppercase character and one number",
        },
        rules: {
            message: "This item must be filled.",
        }
    },
    subTransferForm: {
        from: "From",
        to: "To",
        masterAccount: "(Master Account)",
        subAccount: "(Your Sub Account)",
        availableAmount: "Available Amount：",
        tips: "Not enough balance,please deposit and try again.",
        deposit: "Deposit",
        successful: "Transfer Successful",
        rules: {
            message: "This item must be filled.",
        }
    },
    orderTabs: {
        spot: "Spot",
        investment: "Investment",
        OTC: "OTC",
    },
    spotTabs: {
        openOrders: "Open Orders",
        orderHistory: "Order History",
        tradeHistory: "Trade History",
    },
    dealTabs: {
        limit: "Limit",
        market: "Market",
        stopLimit: "Stop Limit",
        stopMarket: "Stop Market",
    },
    dealLimt: {
        buyPrice: "Price",
        sellPrice: "Price",
        buyQuantity: "Quantity",
        sellQuantity: "Quantity",
        total: "Total",
        buy: "Buy",
        sell: "Sell",
        triggerPrice: "Trigger Price",
        basic: "Basic",
        advanced: "Advanced",
    },
    dealMarket: {
        triggerPrice: "Trigger Price",
        bestMarketPrice: "Best Market Price",
        buyQuantity: "Quantity",
        sellQuantity: "Quantity",
        total: "Total",
        buy: "Buy",
        sell: "Sell",
        basic: "Basic",
        advanced: "Advanced",
        quantityCalculated: "Quantity calculated after trade execution.",
    },
    offeringHighlights:{
        title: 'Offering Highlights',
        desc1:'MAS CISNET registered collective investment scheme – a form of capital markets product (the “Scheme”)',
        desc2:'Easy and accessible means to gain exposure to crypto mining in the form of Bitcoin hashrate',
        desc3:'Investors can choose to subscribe in fiat currency and do not require to have a crypto wallet ',
        desc4:'Units of the Scheme are listed on a MAS-licensed exchange providing secondary liquidity',
        desc5:'The crypto assets generated by the mining is custodized by a MAS- licensed custodian in Singapore',
        desc6:"Base yield of 10%, with further upside",
        desc7:"Crypto mining provides investors lower costs, relatively lower risk and more stable return versus investing directly in Bitcoin",
        desc8:"The mining assets and facilities are located in North America using Bitman S19J 5nm ASICs that are 95% online",
        desc9:"Professional and experienced management team  that has operated crypto mining and dealing in hashrate since 2016. The  Group currently manages over 1,000 PH of crypto mining operations with a $200m AUM.",
    },
    startTradingPop:{
        trade: "Trade",
        spotTrading: "Spot Trading",
        securities: "Securities",
        fastTrade: "Fast Trade",
        otc: "OTC",
    },
    // Mini Crypto Leaders Index 页面
    miniCrypto: {
        underlyingAssets: 'Underlying Assets',
        underlyingAssetsTxt: 'MCLI is physically backed by 0.001 of (1 Bitcoin and 6 Ether)',
    },
    mmn: {
        scheme: {
            title: "MMF Interest Bearing Note",
            code: "PRODUCT CODE",
            productIssuer: "PRODUCT ISSUER",
            dailyReturnRate : "DAILY RETURN RATE",
            annualizedInterestRate: "ANNUALIZED INTEREST RATE",
            tips: "Inception Date on ",
        },
        overview: {
            title1: "Product Introduction",
            description1: "In the current crypto bear market, many crypto holders are struggling to find stable and less risky investment opportunities for their crypto assets, there has always been a barrier for crypto asset holders to invest in the traditional financial (TradFi) products, such as equities, bonds, funds, and fixed deposits.   Metaverse Green Exchange (MVGX) is well positioned to bridge this gap and provide crypto holders with more options to diversify their investments while reducing risk exposure.",
            title2: "Product Restrictions",
            underlyingPortfolio: "Underlying Portfolio",
            typeOfSecurity: "Type of Security",
            collateralizedAssets: "Collateralized Assets",
            interestRate: "Interest Rate Type",
            interest: "Interest Accrual Frequency",
            tenor: "Tenor",
            subscriptionCutOffTime: "Subscription Cut-off Time",
            subscriptionCurrency: "Subscription Settlement Currency",
            minimalSubscriptionAmount: "Minimum Subscription Amount",
            minimumHoldingPeriod: "Minimum Holding Period",
            interestAccrualStartTime: "Interest Accrual Start Time",
            redemptionCutOffTime: "Redemption Cut-Off Time",
            redemptionCurrency: "Redemption Settlement Currency",
            earliestRedemptionDate: "Earliest Redemption Date",
            interestAccrualEndDate: "Interest Accrual End Date",
            note: "Note:",
            tips: "Business Day : A day that is both a Singapore Business Day and a US Business Day."
        },
        pie: {
            title: "Underlying Portfolio Composition",
            interestBearingAccount: "Interest Bearing Account (Bank)",
            interestBearingAccountHouse: "Interest Bearing Account (Security House)",
            moneyMarketFunds: "Money Market Funds",
            USTreasuryProducts: "US Treasury Products",
        }
    },
    MCENew: {
        header: {
            productsServices: "Products and Services",
            security: "Security",
            resources: "Resources",
            articles: "Articles",
            faq: "FAQ",
            disclaimer: 'Legal',
            termsAndConditions: "Terms of Use",
            privacyPolicy: "Privacy Policy",
            disclosure: "Risk warning on Digital Payment Token Services",
            tradeRules: "Trading Rules",
            aboutUs: "About us",
            news: "News",
            project1: "CAMP",
            project2: "CAMP DeMPC Wallet",
            project3: "Wallet Safe",
        },
        title: "Unlock the Power of the Digital Age with MetaComp",
        getStarted: "GET STARTED",
        explore: "Explore",
        productsServices: {
            title: "Meet CAMP: <br/>the all-in-one platform <br/>you’ll ever need",
            p1: "Custody with multi-cloud MPC wallet technology for heightened security",
            p2: "Trade digital assets on an Exchange built on NASDAQ technology or through our platform to optimize execution",
            p3: "Collect or instruct payments from/to your clients, suppliers",
            p4: "Invest in portfolio of traditional financial products and/or digital asset derivatives for additional yield",
        },
        need: {
            title: 'Need a head-start in the digital space?',
            title2: 'Good news, we have done the heavy lifting for you!',
            subtitle1: "CAMP DeMPC Wallet",
            subtitle2: "Wallet Safe ",
            p1:"We transform complexity into simplicity, ensuring our platform is a breeze for you to use.",
            p2:"Our multi-cloud MPC wallet allows you to manage your digital assets with multi-party signature while enjoying the convenience of a user-friendly interface",
            p3:"Enjoy the peace of mind that our battle-tested systems and proprietary methodology keep you and your assets safe, while ensuring each step you take is in compliance with the regulation.",
            p4:"Our wallet safe offer protects your wallet by ensuring that the assets you receive are not tainted or that sending to other wallets is safe, even before you process the transaction",
        },
        safety: {
            title: "The safety of your funds is our priority",
            p1: "Assets are safe-guarded by a licensed custodian in Singapore",
            p2: "No single point of failure and mandatory multi-party processes to manage funds",
            p3: "High-level encryption communication",
            p4: "Multi-cloud architecture makes our systems practically invulnerable to cyber-attacks",
            p5: "2-Factor Authentication for login and other critical actions on the platform",
            p6: "(Andrew/Lily to include more details about security measures and standards used in the industry)",
        },
        aboutUs: {
            title: "About us",
            subtitle1: "Our license",
            subtitle2: "Contact us",
            subtitle3: "Why we are so passionate about the Digital Era",
            p1: "MetaComp was granted the Major Payment Institution Licence under the Payment Services Act 2019 by the Monetary Authority of Singapore in December 2022.",
            p2: "Drop us a note, we'd love to catch up!  ",
            p3: "At MetaComp we firmly believe digital assets will bring tremendous value to the real-world economy by speeding up transactions, lowering the costs and reducing counterparty and market risks.",
        },
        footer: {
            legal: "Legal",
            company: "Company",
            termsOfUse: "Terms of Use",
            privacyPolicy: "Privacy Policy",
            riskWarning: "Risk Warning on Digital Payment Token Services",
            tradingRules: "Trading Rules",
            product: "Product",
            exchange: "Exchange",
            investmentFinancialServices: "Investment Financial Services",
            carbonConnectSuite: "Carbon Connect Suite",
            media: "Media",
            pressReleases: "Press Releases",
            thoughtLeadership: "Thought Leadership",
            videos: "Videos",
            detail: "An Integrated Payment Platform Operated by MetaComp Pte. Ltd., its parent company, and affiliates",
            copyright: "Copyright © 2023 MetaComp All Rights Reserved",
            products : "Products and Services",
            CAMP: 'CAMP',
            MPC: 'CAMP DeMPC Wallet',
            wallet: 'Wallet Safe',
            cookie: 'Cookies Policy',
            touchWithUs: "Get In Touch With Us",
        }

    }
}