export default {
    messages: {
        '100100000': 'System Identify',
        '100100001': '不正确用户名密码',
        '100100002': '2FA代码不正确',
        '100100003': 'Insufficient Authentication Exception',
        '905500001': '请输入正确的邮箱验证码',
        '905500002': '您当前的邮箱已注册，请您直接登录',
        '905500003': '用户不存在',
        '905500004': '2FA代码不正确',
        '905500005': '密码在3次内重复',
        '905500006': '提款请求取消失败',
        '905500007': '用户未过KYC',
        '905500008': '用户未绑定twoFa',
        '905500009': '用户不是Master用户',
        '905500010': '子账号不存在',
        '905500011': '子账号不是此Master的子账号',
        '905500012': '',
        '905500013': "您输入的旧密码不正确",
        '905500014': "子账号状态不是Active",
        '905500015': "无效的交易账号",
        '905500016': '没有提款权限',
        '905500017': '源账号没有相应的权限',
        '905500018': '目标账号没有相应的权限',
        '905500019': '没有修改权限',
        '905500020': '个人账户不允许创建子账户',
        '905500021': '银行账号已创建',
        '905500023': '标签已存在，请修改并重新提交',

        '906000007': '由于价格变动，我们无法执行你方订单。',
        '906000008': 'OTC下单失败 —— 余额不足',
        '906000009': 'OTC下单失败 —— 其他错误',

        '906000021': '',
        '906000022': '高风险等级钱包地址',
        '906000023': '',
        '906000024': '',

        '904700001': "系统异常",
        '904700002': "已过MBT可交易时间",
        '904700003': "未到MBT开盘时间",
        '904700004': "MBT认购量/赎回量小于最低限制",
        '904700005': "MBT可认购量不足",
        '904700006': "账户余额不足",
        '904700007': "锁定账户余额不足",
        '904700008': "解锁账户余额失败",
        '904700009': "非本账户订单，禁止操作",
        '904700010': "未找到指定订单",
        '904700011': "非Pending状态，无法执行取消操作",
    },
    me: {
        message: {
            1412: '账号余额不足',
        }
    },
    header: {
        ourVision: "愿景",
        productsServices: "产品和服务",
        markets: "市场",
        trades: "交易",
        investments: "金融产品",
        nft: "NFT市场",
        otc: "大宗交易",
        careers: "招贤纳士",
        company: "公司",
        disclaimer: "法律声明",
        termsAndConditions: "使用条款",
        privacyPolicy: "隐私条款",
        disclosure: "数字支付代币服务风险披露",
        tradeRules: "交易规则",
        login: "登录",
        signUp: "注册",
        spotTrading: "虚拟货币",
        securities: "证券产品交易",
        fastTrade: "快速交易",
        miniBitcoin: "Mini Bitcoin",
        bitcoinIndex: "MetaComp比特币指数",
        chinese: "中文",
        english: "English",
        download: "下载",
        investment: {
            digital:"数字碳信用",
            hashRate:"算力投资计划",
            verra: "China Verra投资计划",
            mmn: "货币市场投资组合支持票据 (MMN)",
        },
        aboutUs: '关于我们',
        services: '服务',
        crossBorder: '跨境汇款服务',
        tokenService: '数字支付代币服务',
        bankAcount: '虚拟银行账户',
        articles: '文章'
    },
    chooseMetacomp: {
        why: '为什么要选择Metacomp',
        fees: '具有竞争力的交易手续费',
        banks: '通过领先的银行实现在亚洲时区进行快速的法定货币结算',
        dpt: '支持法币（美元、欧元、新加坡元和其他15种货币）和主流数字支付代币',
        support: '专属的客户支持',
    },
    aboutUs: {
        about: '关于',
        metacomp: 'MetaComp',
        title: '关于MetaComp',
        content1: 'MetaComp是一家领先的数字资产平台，总部位于新加坡，根据2019年支付服务法案受新加坡金融管理局监管。MetaComp成立于2018年，提供世界一流的数字交易平台，采用最佳的纳斯达克交易引擎。MetaComp采用P2B2C（平台对商业合作伙伴对客户）模式运营，并为其客户（包括机构投资者、数字经济企业和监管机构）提供一套集成的端到端服务。',
        content2: 'MetaComp的整套服务是合规的，且提供了一个可靠的平台，通过场外交易和交易所交易服务、法定货币支付网关、数字资产托管、主要经纪和资产管理，使企业能够开发和扩展其数字资产产品。',
        subtitle1: '我们的愿景和使命',
        subtitle2: '我们的许可',
        subcontent1: 'MetaComp正在建立一个金融基础设施，为操作的资产未来提供无缝的支持，使加密货币和传统金融可以和谐共存。',
        subcontent2: 'MetaComp于2022年12月获得了新加坡金融管理局颁发的支付服务法案下的主要支付机构许可证。',
        contactUs: '联系我们'
    },
    notice: {
        spotTrading: "虚拟货币",
        securities: "证券产品交易",
        miniBitcoin: "Mini Bitcoin",
        bitcoinIndex: "MetaComp比特币指数",
        NFTMarket: "NFT Market",
        MyNFTAsset: "My NFT Asset",
    },
    userMenu: {
        levelKYC: "KYC级别",
        accountID: "账号",
        spotOrders: "现货订单",
        investmentOrders: "投资订单",
        OTCOrders: "OTC订单",
        spotTradeHistory: "虚拟货币历史记录",
        faq: "FAQ",
        MCEFiat: "MetaComp法币",
        MVGXFiat: "MVGX法币",
        MCECrypto: "MetaComp数字货币",
        MVGXInvestment: "MVGX投资",
        bankAccount: "银行账户",
        walletAddressBook: "钱包地址簿",
        depositHistory: "充值历史",
        withdrawalHistory: "提币历史",
        transferHistory: "内部转账历史",
        statement: "结算单",
        movement: "对账单",
        personal:"个人信息",
        KYCVerification: "KYC认证",
        securityVerification: "安全验证",
        APIManagement: "API管理",
        subAccounts: "子账号",
        logout: "退出",
    },
    login: {
        login: "登录"
    },
    loginForm: {
        email: '邮箱',
        password: '密码',
        login: '登录',
        forgotPassword: '忘记密码?',
        signUp: '注册',
        visiting: '请检查您正在访问',

        mobile: {
            visiting: '请检查您正在访问',
        },
        rules: {
            email: '请输入您的邮箱地址',
            correctEmail: '请输入正确的邮箱地址',
            password: '请输入您的密码',
        },
        loginSuccess: '登录成功',
        expire1: "您的密码还有",
        expire2: "天到期. 是否现在更换密码?",
        resetPassword: "您的密码已经过期，请重新设置。",
    },
    loginHeader: {
        signUpNow: '立即注册',
        login: '登录',
        haveAccount: '已有账号？',
        haveRegister: "还没有注册？"
    },
    signUp: {
        create: '创建一个帐户开始与我们交易!',
        signUp: '请输入您的电子邮件地址'
    },
    signUpForm: {
        signUp: '注册',
        email: '邮箱',
        sendCode: '发送验证码',
        sendCodeSuccess: '验证码发送成功',
        verificationCode: '邮箱验证码',
        password: '密码',
        confirmPassword: '确认密码',
        inconsistentPasswords: '两次输入的密码不一致',
        tips: '我已阅读并同意',
        terms: '使用条款',
        passwordRules: {
            l1: '密码长度至少为10个字符',
            l2: '密码必须包含至少一个数字',
            l3: '密码必须包含至少一个大写字母'
        },
        rules: {
            email: '请输入您的邮箱地址',
            correctEmail: '请输入正确的邮箱地址',
            code: "请输入您的邮箱验证码",
            password: '请输入您的密码',
            confirm: '请输入您的确认密码'
        },
        agreement: '请勾选使用条款',
        signUpSuccess: '注册成功',
        placeholder: {
            email: "请输入您的邮箱地址",
            verificationCode: "请输入您收到的验证码",
        },
        next: "下一步",
    },
    signUpInformation: {
        title: "",
    },
    signUpInformationForm: {
        verified: "已验证",
        firstName: "名",
        lastName: "姓",
        password: "密码",
        confirmPassword: "确认密码",
        referralCode: "推荐码",
        signUp: "注册",
        placeholder: {
            firstName: "请输入您的名",
            lastName: "请输入您的姓",
            password: "请输入您的密码",
            confirmPassword: "请再次输入您的密码",
            referralCode: "请选择推荐码",
        },
        tips: "我确认我已经满18周岁，我已经阅读并同意",
        terms: "使用条款.",
        rules:{
            firstName: '请输入您的名',
            lastName: '请输入您的姓'
        }
    },
    forgotPassword: {
        forgotPassword: '忘记密码?',

    },
    forgotPasswordForm: {
        email: '邮箱',
        sendCode: '发送验证码',
        sendCodeSuccess: '发送验证码成功',
        verificationCode: '邮箱验证码',
        newPassword: '新密码',
        confirmPassword: '确认密码',
        inconsistentPasswords: '两次输入的密码不一致',
        code: '2FA验证',
        submit: '提交',
        passwordRules: {
            l1: '密码长度至少为10个字符',
            l2: '密码必须包含至少一个数字',
            l3: '密码必须包含至少一个大写字母'
        },
        rules: {
            email: '请输入您的邮箱地址',
            correctEmail: '请输入正确的邮箱地址',
            code: "请输入您的邮箱验证码",
            password: '请输入您的新密码',
            confirm: '请再次输入您的密码'
        },
        forgotPasswordSuccess: '修改密码成功'
    },
    twoFa: {
        twoFa: "安全验证",
    },
    twoFaFrom: {
        code: "2FA验证码",
        emailVerification: "邮箱验证",
        sendCode: "发送验证码",
        rules: {
            two2faCode: "此项为必填项",
        },
        submit: "提交",
        forgotCode: "忘记您的2FA验证码?",
        sendCodeSuccess: "验证码发送成功"
    },
    forgotCode: {
        forgotCode: "忘记2FA验证码"
    },
    forgotCodeForm: {
        tips: "一次性的验证码将会发送到您的邮箱",
        email: "邮箱",
        send: "发送",
    },
    home: {
        name: "首页"
    },
    about: {
        name: "关于"
    },
    TransactionAndOtcAndCustody: {
        transactionFees: '交易手续费',
        transactionFeesDescription: '挂单和吃单都按0.15%收取',
        otcFees: '大宗交易手续费(线上)',
        otcFeesDescription: '0.25%',
        custodyFees: '托管手续费',
        custodyFeesDescription: '暂时不收取费用',
    },
    depositAndWithdrawalFees: {
        title: '充值和提现手续费',
        cryptoCurrencies: '数字货币',
        asset: '数字资产',
        fee: '手续费',
        minAmount: '最小提币数量',
        tips: '提现手续费用于支付链上的相关传输成本'
    },
    depositAndWithdrawalFeesDetail: {
        title: '充值和提现手续费',
        fiatCurrencies: '法币',
        description: 'MetaComp不会在您充值或提现的过程中收取任何手续费。但银行可能会根据手续费而影响您的实际到账金额。',
    },
    otcFees: {
        title: '大宗交易手续费(线下)',
        description: '0.1% + 其他费用，获取更多信息请联系'
    },
    profile: {
        tabs: {
            overview: "账号概览",
            personalDetails: "个人信息",
            kyc: "身份认证",
            securityVerification: "安全验证",
            apiManagement: "API管理",
            subAccounts: "子账号",
            externalAuthorizationManagement: "外部授权管理",
            preferencesSettings: "偏好设置"
        },
        overview: {
            title: "欢迎使用MetaComp交易平台",
            recentLogin: "最近登录",
            ipAddress: "IP 地址",
            contactEmail: "联系邮箱",
            accountNo: "账号",
            currentTier: "当前认证等级",
            accountType: "账号类型",
            createdTime: "创建时间",
            expirationDays: "距离到期日",
            expirationTime: "到期时间",
            days: "天",
            kyc: {
                title: "身份认证",
                description: "为了保护您的资金，请先完成身份验证。",
                descriptionSuccess: "您已成功通过认证",
            },
            securityVerification: {
                title: "安全认证",
                description: "你目前的安全强度: ",
                google: "谷歌身份验证",
                mobile: "手机号码认证",
                email: "Email认证",
                tooWeek: "较弱",
                middle: "中等",
                strong: "极强",
            },
            apiManagement: {
                title: "API管理",
                description: "使用API自动化你的交易"
            },
            subAccounts: {
                title: "子账号",
                description: "为了方便的资产管理，可以从主账户创建多个子账户"
            },
            externalAuthorizationManagement: {
                title: "外部授权管理",
                description: "外部授权"
            },
            preferenceSettings: {
                title: "偏好设置",
                description: "偏好或通知设置"
            }
        },
        personalDetails: {
            title: "个人信息",
            recentLogin: "最近登录",
            ipAddress: "IP 地址",
            contactEmail: "联系邮箱",
            contactNumber: "联系电话",
            address: "邮件地址",
            contactName: "联系人",
            name: "姓名",
            description: "描述",
            disabled: "未配置",
            enable: "已配置",
            recentLoginTable: {
                id: "ID",
                loginRegion: "登录区域",
                LoginDevice: "登录设备",
                time: "时间",
                ipAddress: "IP 地址",
                language: "语言",
                status: "状态"

            }
        },
        kyc: {
            title: "KYC认证",
            description: "为了保护您的资金安全，请尽快完成您的KYC认证",
            changeType: "申请企业账户",
            stage0: {
                title: "KYC等级0",
                emailVerification: "电子邮箱验证",
                mobileVerification: "手机号验证"
            },
            rules:{
                upload: "请上传文件"
            }
        },
        securityVerification: {
            title: "安全验证",
            description: "你目前的安全强度:",
            google: {
                title: "谷歌身份验证",
                description: "谷歌身份验证码用于登录、退出或更改安全设置时的安全验证。如果接收短信代码有困难，可以使用谷歌身份验证。"
            },
            mobile: {
                title: "手机号码认证",
                description: "用于账号登录、提现等功能的验证。单击查看支持的国家列表。"
            },
            email: {
                title: "Email 认证",
                description: "用于账号登录、提现等功能的验证。"
            },
            securitySettings: "安全设置",
            loginPassword: {
                title: "登录密码",
                description: "登录密码用于登录您的帐号。"
            },
            fundPassword: {
                title: "资金密码",
                description: "为保护您的资产，请在交易时开启资金密码。"
            },
            twoFa: {
                title: "登录两步认证方式",
                description: "登录2步验证增加了额外的一层安全到您的帐户。您必须启用谷歌验证器或电子邮件验证或短信才能启用此设置。一旦启用，每次登录您的帐户时，都需要使用“谷歌Authenticator”提供的电子邮件验证码或短信代码或六位数字代码。",
                google: "谷歌身份验证",
                email: "Email 认证",
                sms: "短信认证"
            },
            mailNotificationSettings: {
                title: "邮件通知设置",
                description: "如果有任何改动，我们将通过邮件通知",
            },
            updateUserType2faSuccess: "修改成功",
            enable: "打开",
            change: "更改",
            tooWeek: "较弱",
            middle: "中等",
            strong: "极强",
        },
        api: {
            title: "API管理",
            description: {
                l1: "使用API自动化你的交易。详细信息参考: ",
                l2: " API 文档",
                l3: "",
            },
            create: "创建API",
            delete: "删除API",
            t1: "1. 不要向任何人透露您的API Key，以避免资产损失。建议将“API Key”绑定IP，以提高帐户安全性。",
            t1Edit: "编辑",
            t2: "2. 请注意，您的API密钥可能会被授权给第三方平台公开。",
            t2Edit: "编辑",
            t3: "3.如果KYC未完成，您将无法创建API。",
            gotoKyc: "现在就去做KYC",
            t4: "4. 您必须启用短信验证或谷歌验证。",
            t4Edit: "编辑",
            t5: "5. 密码资金基金。",
            t5Edit: "编辑",
            t6: "邮件验证",
            t6Edit: "编辑"
        },
        personal:
        {
            phaseOneCompleted: "等级1认证完成",
            prdut: "您现在可以浏览我们的产品",
            kycTips: "请完成KYC以开启交易权限",
            accountId: "账号",
            tier: "等级",
            tierScheme: "等级概述",
            v30: "30天成交量",
            recentLogin: "最近登录",
            ipAddress: "IP 地址"
        }
    },
    portfolio: {
        tabs: {
            overview: "资产概览",
            funding: "MetaComp法币账户",
            cryptoTrading: "MetaComp数字资产账户",
            mvgxFiat: "MVGX法币账户",
            investment: "MVGX投资账户"
        },
        asset: {
            title: "资产概览",
            portfolioValue: "资产账户价值",
            portfolioCarbonFootprint: "资产账户的碳排放量",
            ton: "ton CO₂e",
            info: {
                l1: "目前没有资产。你可以",
                l2: " 存入数字资产",
                l3: " 或者 ",
                l4: " 开始交易",
            },
            funding: {
                title: "MetaComp法币账户",
                deposit: "充值",
                withdrawal: "取币",
                transfer: "转账",
            },
            crypto: {
                title: "MetaComp数字资产账户",
                transfer: "转账",
                trade: "交易"
            },
            portfolio: {
                title: "MVGX法币账户",
                deposit: "充值",
                withdrawal: "取币",
                transfer: "转账",
            },
            investment: {
                title: "MVGX投资账户",
                transfer: "转账",
                trade: "交易"
            }
        },
        history: {
            deposit: {
                title: "充值",
                description: "将资金从钱包转到您的账户",
            },
            withdrawal: {
                title: "取币",
                description: "从钱包中提取资金",
            },
            transfer: {
                title: "转账",
                description: "在交易账户之间转移加密货币",
            },
            securitynotice: {
                title: "安全提示",
                mce: "MetaComp",
                mvgx: "MVGX",
                description: "检查网站地址并开启双因素身份验证。切勿向声称来自",
                description_end: "的员工转账或泄露密码等任何信息。",
            },
            tips: {
                l1: "1. 为防止网络钓鱼，请浏览以下网站: ",
                l2: "2. 不要安装任何声称与MetaComp相关的浏览器插件。",
                l22: "2. 不要安装任何声称与MVGX相关的浏览器插件。",
                l3: "3.不要向任何人(包括MetaComp员工)透露任何浏览器、短消息、电子邮件、谷歌验证器代码。",
                l33: "3.不要向任何人(包括MVGX员工)透露任何浏览器、短消息、电子邮件、谷歌验证器代码。",
                l4: "4. 不要给任何声称是MetaComp客服的人打电话。",
                l44: "4. 不要给任何声称是MVGX客服的人打电话。",
                l5: "5. 不要把钱转给任何自称来自MetaComp的员工。",
                l55: "5. 不要把钱转给任何自称来自MVGX的员工。"
            }
        },
        tableAccount: {
            instruments: "交易品种",
            balance: "余额",
            valuation: "估值",
            env: "碳排放量 (ton CO₂e)",
            available: "可用余额",
            actions: "操作",
            deposit: "充值",
            withdrawal: "取币",
            transfer: "转账"
        }
    },
    depositPop: {
        mceDepositFiat: "向MetaComp充值法币",
        mvgxDepositFiat: "向MVGX充值法币",
        depositFiat: "法币",
        descriptionFiat: "从您的银行账户充值",
        depositCrypto: "充值数字货币",
        descriptionCrypto: "从您的钱包地址发送数字货币",
    },
    depositFiat: {
        return: "返回",
        fundingProtfolio: "资产账户",
        deposit: "充值",
        depositFiat: "向MetaComp充值法币",
        depositMvgxFiat: "向MVGX充值法币",
        depositCrypto: "充值数字货币",
        depositBankDetails: "充值银行详细信息",
        beneficiaryName: "收款人姓名",
        beneficiaryBankAccount: "收款人银行账号",
        bankName: "银行名称",
        swiftCode: "Swift代码",
        countryCode: "国家代码",
        bankAddress: "银行地址",
        referenceCode: "参考代码",
        tips: "请将此参考代码填写在汇款备注中。如果您没有按照此项要求汇款将有可能导致您的汇款到账延迟甚至丢失。",
        recentDeposits: "最近的充值记录",
        goto: "转到 ",
        history: "历史记录",
        seeMore: " 更多交易",
        intermediaryBank: "中转银行",
        ibsCode: "中转银行SWIFT号码",
        intermediaryBankAddress: "中转银行地址",
        fiatTable: {
            instruments: "交易品种",
            dateReceived: "到账日期",
            depositAmount: "充值金额",
            status: "状态",
        },
        depositTips: "请选择转款币种，并填写正确的参考代码。",
    },
    depositMvgxFiat: {
        return: "返回",
        fundingProtfolio: "资产账户",
        deposit: "充值",
        depositMvgxFiat: "向MVGX充值法币",
        depositMceFiat: "向MetaComp充值法币",
        depositCrypto: "充值数字货币",
        depositBankDetails: "充值银行详细信息",
        beneficiaryName: "收款人姓名",
        beneficiaryBankAccount: "收款人银行账号",
        bankName: "银行名称",
        swiftCode: "Swift代码",
        countryCode: "国家代码",
        bankAddress: "银行地址",
        referenceCode: "参考代码",
        tips: "请将此参考代码填写在汇款备注中。如果您没有按照此项要求汇款将有可能导致您的汇款到账延迟甚至丢失。",
        recentDeposits: "最近的充值记录",
        goto: "转到",
        history: "历史记录",
        seeMore: "更多交易",
        fiatTable: {
            instruments: "交易品种",
            dateReceived: "到账日期",
            depositAmount: "充值金额",
            status: "状态",
        },
        depositTips: "Please select the appropriate currency and input the corresponding reference code below",
    },
    depositCrypto: {
        return: "返回",
        fundingProtfolio: "MetaComp数字资产账户",
        deposit: "充值",
        depositCrypto: "充值数字货币",
        description: "您的账户还没有完成开启充值功能所需要的条件。",
        depositMvgxFiat: "向MVGX充值法币",
        depositMceFiat: "向MetaComp充值法币",
        depositBankDetails: {
            title: "充值银行信息",
            selectAInstrument: "请选择交易品种",
            chain: "请选择链",
            address: "地址",
            selfTest:"钱包地址自检（推荐）",
            screenYourWalletAddress:"检测您的钱包地址",
            depositConfirmation: "充值确认",
            block: "区块",
            tips: "请确认您选择的链是",
            beneficiaryName: "收款人姓名",
            beneficiaryTips: "Cyberdyne Tech Services Pte. Ltd.",
        },
        recentDeposits: "最近的充值记录",
        goto: "转到 ",
        history: "历史记录",
        seeMore: " 更多交易",
        selfTestTooltip: '只有白名单地址将被允许发送加密货币到MetaComp。请您花些时间自检并将您的钱包地址加入白名单，以降低您存款被拒的风险。',
        copySuccess: "复制成功",
    },
    cryptoFiatStatus: {
        l1: "1. 您需要完成KYC等级2认证，以开启充值功能。",
        l2: "2. 接受MVGX市场交易规则。",
        l3: "2. 设置并开启双重认证。",
        gotoKYC: "转到 KYC",
        seeRules: "查看规则",
        edit: "编辑",
    },
    crytoTable: {
        instruments: "交易品种",
        dateReceived: "到账日期",
        depositAmount: "充值金额",
        chain: "链",
        status: "状态",
    },
    history: {
        tabs: {
            depositHistory: "充值记录",
            withdrawHistory: "提币记录",
            transferHistory: "转账记录",
            fiat: "法币",
            crypto: "数字货币",
        },
        find: {
            exportToCSV: "导出到CSV",
        },
        depositTable: {
            instruments: "交易品种",
            dateReceived: "到账日期",
            depositAmount: "充值金额",
            refNo: "参考号码",
            chain: "链",
            status: "状态",
        },
        withdrawTable: {
            instruments: "交易品种",
            dateReceived: "到账日期",
            withdrawAmount: "提币金额",
            chain: "链",
            refNo: "参考号码",
            walletAddress: "钱包地址",
            bankAccount: "银行账号",
            fees: "费率 (托管费 + 提币费)",
            fiatFees: "手续费",
            status: "状态",
            actions: "操作",
            cancel: "取消",
        },
        transferTable: {
            instruments: "交易品种",
            dateReceived: "到账日期",
            transferAmount: "转账金额",
            refNo: "参考号码",
            source: "来源",
            destination: "目标",
            status: "状态",
        }
    },
    googleVerify: {
        return: "返回",
        profile: "用户资料 - 安全验证",
        googleAuthentication: "谷歌验证器(2FA Code)",
        notice: {
            notice: "注意",
            l1: "1. 请不要随意删除您的验证器或验证二维码。推荐使用谷歌验证器。",
            l2: "2. 如果您的谷歌验证器不正常，请到设置中重新同步。",
        },
        code: "输入您的2FA验证码",
        enterCode: "请输入6位的验证码",
        confirm: "确认",
        confirmSuccess: "提交成功",
        copySuccess: "内容已复制到粘贴板！"
    },
    fundPassword: {
        return: "返回",
        profile: "用户资料 - 安全验证",
        fundingPassword: "资金密码",
        tips: "资金密码是由6位数字组成的密码，用于保护您的重要操作,例如：转账。请注意资金密码不是2FA密码。",
        step1: "您需要首先设置资金密码，才能设置2FA密码。",
        step2: "您需要首先设置资金密码，才能设置2FA密码。",
        gotoSet: "去设置",
        form: {
            code: "2FA密码",
            enterCode: "请输入6位的验证码",
            fundingPassword: "资金密码",
            confirmFundingPassword: "确认资金密码",
        },
        rules: "此项为必填项",
        confirm: "确认",
    },
    withdrawal: {
        withdrawCrypto: {
            return: "返回",
            fundingProtfolio: "资产账户",
            withdraw: "提现",
            withdrawCrypto: "提取数字货币",
            receiveAmount: "到账数量",
            withdrawMceFiat: "从MetaComp提取法币",
            withdrawMvgxFiat: "从MVGX提取法币",
            withdrawAmount: "提币金额",
            available: "资产账户可用余额：",
            address: "Select Your Label",
            wallectAdressName: "Wallet Address",
            addressBook: "钱包地址",
            chain: "链",
            minimumWithdrawAmount: "最小提币金额",
            withdrawalFee: "提币费",
            submitRequest: "提交请求",
            recentWithdrawals: "最近提币历史",
            notice: {
                notice: "注意",
                l1: "1. 请注意，MetaComp不能撤回已经提交的请求。请再转帐前仔细检查账号和地址。",
                l2: "2. 请注意现阶段MetaComp仅支持ERC-20网络，请确认您的目标钱包是兼容ERC-20网络的，否则您有可能面临丢失资产的风险。",
            },
            successful: "提币请求已经成功提交",
            rules:{
                l1: "此项为必填项",
            },
            sufficientFunds: "余额不足",
            lessMiniAmount: "提现金额不能小于最小提现金额",
        },
        withdrawStatus: {
            l1: "1. 发起提币请求前您需要完成KYC等级3认证。",
            l2Crypto: "2. 设置您的钱包地址。",
            l2Fiat: "2. 设置您的银行账户。",
            l22: "2.  设置您的钱包地址。",
            l3: "3. 同意MetaComp市场交易规则。",
            l4: "4. 设置谷歌2FA验证。",
            edit: "编辑",
            readAgree: "点击阅读并同意",
        },
        crytoTable: {
            instruments: "交易品种",
            dateReceived: "到账日期",
            withdrawAmount: "提币金额",
            chain: "链",
            refNo: "参考号码",
            walletAddress: "钱包地址",
            fees: "费率 (托管费 + 提币费)",
            status: "状态",
            actions: "操作",
            cancel: "取消"
        },
        withdrawFait: {
            return: "返回",
            fundingProtfolio: "MetaComp法币资产账户",
            withdraw: "提现",
            withdrawMceFiat: "从MetaComp提取法币",
            withdrawMvgxFiat: "从MVGX提取法币",
            withdrawCrypto: "提取数字货币",
            withdrawAmount: "提币金额",
            available: "资产账户可用余额：",
            bankAccount: "选择您的银行帐户",
            label: "Select Your Label",
            labelAccount: "Bank Account",
            labelName: "Bank Name",
            labelAddress: "Bank Address",
            bankAccountBook: "银行地址簿",
            walletAddress: "选择您的钱包地址",
            chain: "请选择链",
            minimumWithdrawAmount: "最小提现金额",
            withdrawalFee: "提币费",
            receiveAmount: "到账数量",
            submitRequest: "提交请求",
            recentWithdrawals: "最近提币历史",
            notice: {
                notice: "注意",
                mce:'MetaComp',
                mvgx:'MVGX',
                l1: "1. 请注意，",
                l1_1: "不能撤回已经提交的请求。请再转帐前仔细检查账号和地址。",
                l2: "2. 请注意现阶段",
                l2_1:"仅支持ERC-20网络，请确认您的目标钱包是兼容ERC-20网络的，否则您有可能面临丢失资产的风险。",
            },
            successful: "提交请求已提交",
            rules:{
                l1: "此项为必填项",
            },
            sufficientFunds: "余额不足",
            lessMiniAmount: "提现金额不能小于最小提现金额",
        },
        withdrawMvgxFait: {
            return: "返回",
            fundingProtfolio: "MVGX法币资产账户",
            withdraw: "提现",
            withdrawMceFiat: "从MetaComp提取法币",
            withdrawMvgxFiat: "从MVGX提取法币",
            withdrawCrypto: "提取数字货币",
            withdrawAmount: "提币金额",
            available: "资产账户可用余额：",
            bankAccount: "选择您的银行帐户",
            bankAccountBook: "银行地址簿",
            chain: "请选择链",
            minimumWithdrawAmount: "最小提现金额",
            withdrawalFee: "提币费",
            receiveAmount: "到账数量",
            submitRequest: "提交请求",
            recentWithdrawals: "最近提币历史",
            notice: {
                notice: "注意",
                l1: "1. 请注意，MetaComp不能撤回已经提交的请求。请再转帐前仔细检查账号和地址。",
                l2: "2. 请注意现阶段MetaComp仅支持ERC-20网络，请确认您的目标钱包是兼容ERC-20网络的，否则您有可能面临丢失资产的风险。",
            },
            successful: "提币请求已经成功提交",
            rules:{
                l1: "此项为必填项",
            },
            sufficientFunds: "余额不足",
            lessMiniAmount: "提现金额不能小于最小提现金额",
        },
        faitTable: {
            instruments: "交易品种",
            dateReceived: "到账日期",
            withdrawAmount: "提币金额",
            bankAccount: "银行账户",
            fees: "费率 (托管费 + 提币费)",
            status: "状态",
            actions: "操作",
            cancel: "取消"
        },
    },
    noTrading: {
        noTransactionsFound: "没有交易记录",
        goto: '转到',
        history: "历史",
        toSee: "查看更多交易记录",
    },
    cutTime: {
        cutOffTime: "截止时间",
        hours: "时",
        minutes: "分",
        seconds: "秒",
    },
    documentation: {
        miniBtcOfferingMemorandum: "Mini BTC Offering Memorandum",
        miniBtcProductSheet: "Mini BTC Product Sheet",
        carbonNeutralMiniBTCBasket: "Carbon Neutral Mini BTC Basket",
        view: "查看",
        tabName: "文献记录"
    },
    miniBtcPop: {
        title: "Mini Bitcoin Unit 001",
        content: {
            l1: "This Application should be read incontext of, and together with, the Offering Memorandum. Except where otherwise defined in thisApplication all capitalised terms shall have the samemeaning as in the Offering Memorandum.",
            l2: "The agreement between you and the Manager forinvestment into the Mini Bitcoin Unit 001 (the 'Scheme') will come intoeffect when a correctly completed Application and related subscription monies (and any relevantaccompanying documents, where required) are received and accepted by the Manager (such date of acceptance,the 'Effective Date'). The Manager reserves theright not to accept any Application.",
        },
        scroll: "滑到底部，可勾选并同意此协议",
        scrollL1: "我已阅读并同意",
        scrollL2: "我明白并且同意MetaComp回使用外部服务供应商DocuSign",
        fullName: "全名",
        nameOfSignatory: "签署人姓名",
        designation: "职位",
        email: "邮箱",
        agreeAndContinue: "同意并继续用户协议与隐私条款",
        rules: {
            l1: "请输入全名",
            l2: "请输入文件签署人的姓名",
            l3: "请输入职位",
            l4: "请输入邮箱",
            l5: "请输入正确的邮箱地址",
        },
        placeholder: {
            l1: "全名",
            l2: "2FA验证码",
            l3: "请输入6位的验证码",
        },
        showText: {
            name: "您的姓名",
            title: "您的职位",
            email: "您的邮箱地址",
        }
    },
    mobileTabs: {
        subscribe: "认购",
        redeem: "赎回",
    },
    overview: {
        tabName: "概要",
        title1: "什么是Mini BTC",
        description1: "Mini Bitcoin旨在作为一种投资工具用来投资并持有比特币。Mini Bitcoin产品将使得投资者能够方便并合规的方式来获得比特币的敞口，而又不用关注持有数字钱包以及转账的相关繁琐流程。投资者每获得一个Mini Btc都意味着MVGX会在现货市场购买等值的0.001个BTC，并且相应的BTC会被托管并存储在MVGX的银行安全级别的专用冷钱包中。",
        title2: "Mini Bitcoin 优势",
        description2: "MVGX Mini Bitcoin作为一种投资工具，不仅为投资者提供了以证券形式获得BItcoin的机会，同时MVGX帮助投资者购买、存储和保管Bitcoin，为投资者进入加密市场的提供便捷入口。",
        benchmarkPrice: "参考价格",
        AUM: "在管资产总市值",
        valuePerUnit: "每单位价值",
        subscriptionFee: "认购费",
        managementFee: "管理费",
        unitsOutstanding: "发行总量",
        inceptionDate: "成立日期",
        CISNet: "ISIN编码",
        redemptionFee: "赎回费",
    },
    redeemTable: {
        date: "日期",
        amount: "金额",
        estimatedValue: "预估价值",
        fee: "手续费",
        status: "状态",
        actions: "操作",
        cancel: "取消",
    },
    redeen: {
        myRedemptionRequest: "我的赎回申请",
        availableUnits: "可赎回份额",
        units: "份",
        unitsToRedeem: "赎回份额",
        minimumRedemption: " 最小赎回份额:",
        unit: "份",
        estimatedRedemptionValue: "预计赎回份额",
        estimatedFee: "预估手续费",
        available: "可用余额",
        amountToInvest: "申购金额* ",
        annualized: "% + 操作费用",
        reset: "重置",
        submit: "提交",
        recentRedemptionHistory: "赎回历史记录",
        placeholder: {
            l1: "请输入您期望赎回的份额数量",
            l2: "$ 0",
            l3: "$ 0.00"
        },
        submitTips:{
            l1: "请输入您期望赎回的份额",
            l2: "赎回份额不能为0",
            l3: "赎回份额不能小于最小赎回份额",
            l4: "今天的订阅已经结束，请在下一个工作日提交您的请求!",
            l5: "赎回份额不能大于最小赎回份额",
            submitSuccess: "提交成功",
        }
    },
    scheme: {
        miniBitcoinInvestmentScheme: "Mini Bitcoin投资计划",
        ticker: "产品编码",
        registrationNumber: "新加坡金融局CISNET登记号",
        assetUnderManagement: "在管资产总市值",
        livePrice: "当前价格",
        dailyChange: "日涨跌幅",
        sinceInception: "自成立以来",
        tips: "展示价格仅作参考，与实际价格可能不同。",
        tips2:'起始日期 ',
    },
    spec: {
        tabName: "规格",
        title: "规格",
        investmentObjective: "投资目标",
        benchmarkPrice: "参考价格",
        investorQualification: "可认购投资者",
        unitSize: "认购单位",
        minimumSubscriptionAmount: "最小认购金额",
        subscriptionHours: "认购时间",
        currency: "币种",
        dailySubscriptionCap: "当日认购限额",
        minimumRedemptionAmount: "最小赎回份额",
        unit: "个单位",
        redemptionHours: "赎回时间",
        settlementMethod: "结算方式",
        cntUnitSize:"每个单位与1吨碳信用额度相同",
    },
    under: {
        name: 'METH由0.001的ETH支持',
        tabName: '标的资产',
    },
    METH:{
        tooltip1:'如您投资本产品',
        tooltip2:"可发送邮件到sales{'@'}mvgx.com进行询问"
    },
    underLyingAsset:{
        CNVIS:{
            title:'资产 001',
            projectName:'项目名称',
            projectNameDesc:'大唐昂力(灵武)光伏并网电站项目',
            projectType:'项目类型',
            prjectTypeDesc:"能源工业 (可再生/不可再生能源)",
            verraStandard:'Verra 标准',
            verraStandardDesc:'大唐昂力(灵武)光伏并网电站项目',
            projectDeveloper:'项目开发人员/支持人员',
            projectDeveloperDesc:'大唐昂力(灵武)新能源有限公司',
            quantity:'数量',
            projectCountry:'项目所在国家',
            projectCountryDesc:'中国',
            project3rd:'项目第三方认证机构',
            project3rdDesc:'中国质量认证中心（CQC）',
            projectMeth:'项目算法',
            projectMethDesc:'ACM0002',
            period:'有效期',
            periodDesc:'2021.12.28 ~ 2031.12.27',
        },
        CNT:{
            title:'资产 001',
            projectName:'项目名称',
            projectNameDesc:'中国河北省张家口市固原黄盖卓200兆瓦风电场',
            projectType:'项目类型',
            prjectTypeDesc:"能源工业(可再生能源)",
            carbonStandard:'碳信用标准',
            carbonStandardDesc:'中国认证减排 (CCER)',
            registryNumber:"认证系列号",
            projectDeveloper:'项目开发人员/支持人员',
            projectDeveloperDesc:'中广核风电股份有限公司',
            creditQuantity:'信用数量 (tonnes CO2 eq)',
            projectCountry:'项目所在国家',
            projectCountryDesc:'中国',
            project3rd:'项目第三方认证机构',
            project3rdDesc:'中国环境保护部对外经济合作办公室（MEPFECO）',
            projectMeth:'项目算法',
            projectMethDesc:'ACM0002',
            period:'有效期',
            periodDesc:'2014.12.12 ~ 2021.12.11',
        },        
        view:{
            viewName:'查看',
            title:"项目文件",
            document:'文件名称',
            documentType:'文件类型',
            projectDesc:'项目描述',
            monitorReport:'监控报告',
            verification:'证明文件',
            projectDesign:'项目设计文件',
            projectMonitorReport:'项目监控报告',
            emissionVerifyReport:'减排核查报告'
        },
    },
    subscribe: {
        title: "我的申购申请",
        subscriptionCap: "当日认购上限",
        available: "可用余额",
        amountToInvest: "投资金额",
        minimumSubscription: "最小申购额:",
        faitPortfolio: "向您的MVGX账户中充值法币",
        estimatedAllocation: "预估认购份额",
        units: "份",
        cashbackRewardsToUse: "返现奖励",
        fee: "手续费",
        tips: "申购金额 * ",
        actualAmountDeducted: "实际金额中扣除",
        reset: "重置",
        submit: "提交",
        recentInvestmentHistory: "申购历史记录",
        placeholderBalance: "输入金额",
        submitTip1: "请输入金额",
        submitTip2: "输入的金额不能等于0",
        submitTip3: "输入金额不应少于订阅金额",
        submitTip4: "今天的订阅已经结束，请在下一个工作日提交您的请求!",
        submitTip5: "认购总额超过认购上限",
        submitSuccessful: "提交成功"
    },
    subTable: {
        date: "日期",
        amount: "金额",
        allocated: "份额",
        fee: "手续费",
        env: "碳排放量 (ton CO₂e)",
        status: "状态",
        actions: "操作",
        cancel: '取消',
    },
    tabs: {
        subscribe: "认购",
        redeem: "赎回",
        trade: "交易",
        retirement: "Retirement",
        correspondPe: "Correspond Adjustment",
    },
    miniBtcTable: {
        createdAt: "日期",
        type: "类型",
        amount: "金额",
        allocated: "份额",
        estimatedValue: "预估价值",
        fee: "手续费",
        env: "碳排放量 (ton CO₂e)",
        status: "状态",
        actions: "操作",
        cancel: "取消",
    },
    buy: {
        receive: "金额",
        spend: "花费",
        available: "可用余额:",
        transfer: "转账",
        previewAndQuote: "预览并询价",
        buy: "买",
        price: "价格",
        quantity: "数量",
        total: "总金额",
        priceWill: "价格会在",
        seconds: "秒后刷新",
        cancel: "取消",
        execute: "执行",
        range: "范围:",
        please: "请输入数量",
        consult: "如果您希望收到我们交易团队的实时报价，请咨询您的客户经理。",
    },
    otcTable: {
        orderId: "订单号",
        createdAt: "建立于",
        symbol: "标识",
        buyOrSell: "买/卖",
        quantity: "数量",
        executedPrice: "执行价格",
        status: "状态"
    },
    sell: {
        spend: "数量",
        available: "可用余额:",
        transfer: "转账",
        receive: "收到",
        amount: '金额',
        previewAndQuote: "预览并询价",
        buy: "买",
        limit: "限额",
        price: "价格",
        quantity: "数量",
        total: "总金额",
        priceWill: "价格会在",
        seconds: "秒后刷新",
        cancel: "取消",
        execute: "执行",
        range: "范围:",
        please: "请输入数量",
    },
    otcIndex: {
        buy: "买入",
        sell: "卖出",
        otc: "大宗交易",
        recentRecords: "近期记录",
    },
    cryptoTrading: {
        title: "MetaComp数字资产账户",
        portfolioCarbonFootprint: "资产账户的碳排放量：",
        ton: "ton CO₂e",
    },
    fundingAccount: {
        title: "MetaComp法币账户",
        portfolioCarbonFootprint: "资产账户的碳排放量：",
        ton: "ton CO₂e",
    },
    investment: {
        title: "MVGX投资账户",
        portfolioCarbonFootprint: "资产账户的碳排放量：",
        ton: "ton CO₂e",
    },
    mvgxFait: {
        title: "MVGX法币账户",
        portfolioCarbonFootprint: "资产账户的碳排放量：",
        ton: "ton CO₂e",
    },
    movementTable: {
        createdAt: "建立于",
        operationID: "业务号",
        accountName: "账户名称",
        instruments: "交易品种",
        transactionType: "交易类型",
        amount: "金额",
    },
    movementIndex: {
        movementCharges: "账户流水&收费", //不同
    },
    detailTable: {
        time: "时间",
        operationID: "业务号",
        accountType: "账户类型",
        instruments: "交易品种",
        transactionType: "交易类型",
        amount: "金额",
    },
    stateFind: {
        export: "导出",
    },
    stateTable: {
        instruments: "交易品种",
        balanceBroughtForward: "结转余额承前",
        balanceCarriedForward: "余额结转下期",
        fp: "FP",
        fundingPortfolio: "MetaComp资金账户",
        cp: "CP",
        cryptoTradingPortfolio: "MetaComp数字资产账户",
        ip: "IP",
        investmentPortfolio: "投资账户",
        mvgxFiatPortfolio: "MVGX法币账户",
    },
    export: {
        statementReport: "对账报表"
    },
    statmentIndex: {
        statementSummary: "报表摘要",
        details: "明细",
    },
    addBank: {
        return: "返回",
        fundingProtfolio: "资产账户",
        withDrawal: "提币",
        bankAccountList: "银行账户列表",
        addBankAccount: "添加银行账户",
    },
    transter: {
        return: "返回",
        mceProtfolio: "MetaComp法币资产账户",
        mvgxProtfolio: "MVGX法币资产账户",
        transfer: "转账",
        recentRecords: "近期记录",
    },
    addBankPop: {
        name: "银行名称",
        accountNo: "IBAN/账号.*(不包括 '-')",
        countryCode: "国家/地区",
        swiftCode: "Swift代码",
        bankAddress: "银行地址",
        label: "Label",
        submit: "提交",
        checkErr: "您提交的银行账户未通过校验，请检查并重新填写。",
    },
    addWalletPop: {
        walletOwner: 'Wallet Owner',
        walletAddress: "钱包地址",
        label: "Label",
        chain: "链",
        description: "描述",
        submit: "提交",
        checkErr: "您提交的钱包地址未通过校验，请检查并重新填写。",
        riskErr: "您填写了无效地址，或您选择了与该地址不相匹配的提现网络。",
    },
    screenAddress: {
        walletAddressDetails:"钱包地址详情",
        walletAddress: "钱包地址",
        chain: "链",
        description: "描述",
        submit: "提交",
        close:"关闭",
        selfTest: '自检测试',
        screeningSuggestion:"校验结果建议",
        suggestionText1:"请先填写以上要求的内容。",
        suggestionText2:"成功完成自检后，能够将您的钱包注册并添加到白名单地址中。",
        lowRisk1:"您的钱包地址属于低风险范畴。",
        lowRisk2:"此地址可以添加到白名单并正常使用。",
        highRisk1:"您的钱包地址属于高风险范畴。",
        highRisk2:"我们推荐您使用另外的钱包地址。如仍使用，处理时间将会延长。"
    },
    bankAccount: {
        return: "返回",
        fundingProtfolio: "资金账户",
        bankAccountList: "银行账户列表",
        bankAccount: "银行账户",
        addBankAccount: "添加银行账户",
        date: "创建日期",
        label: "Label",
        bankName: '银行名称',
        accountNo: "IBAN/账号.",
        swiftCode: "Swift代码",
        recipientName: "收款姓名",
        actions: "操作",
        edit: "编辑",
        delete: "删除"
    },
    walletAddress: {
        return: "返回",
        fundingProtfolio: "资金账户",
        walletAddressList: "钱包地址列表",
        walletAddress: "钱包地址",
        addWalletAddress: "添加钱包地址",
        date: "创建日期",
        label:"Label",
        instruments: "交易品种",
        actions: "操作",
        status: "状态",
        edit: "编辑",
        delete: "删除"
    },
    withdrawPop: {
        withdrawMceFiat: "从MetaComp提取现金",
        withdrawMvgxFiat: "从MVGX提取现金",
        description1: "将现金资产提取到您的银行账户",
        withdrawCrypto: "提取数字货币",
        description2: "将数字资产提取到您的钱包地址",
    },
    kycVerification: {
        individualVerification: "个人验证",
        corporateVerification: "机构验证",
        unverified: "未验证",
        level: "已完成验证的KYC等级",
        phase1: "等级1已完成",
        phase2: "等级2已完成",
        needVerification: "请确保您已经准备好开户所需的必须文件以及相关证明",
        completedVerification: "您已经成功完成了KYC验证",
        viewDocs: "查看所需要的文件",
        switchCorporate: "切换到机构验证",
        switchIndividual: "切换到给个人验证",
        tierScheme: "等级概述",
        currentFunctionality: "当前等级的功能",
        detail2: "您可以访问我们的网站并浏览所有产品。但是无法进行交易或跟资产转账。",
        availableFeatures: "成功通过验证后您将获得如下功能",
        startVerification: "开始验证",
        verifyAgain: "再次验证",
        kyc1: "充值，提币或在不同账户间进行内部转账",
        kyc2: "数字货币交易",
        kyc3: "投资产品交易",
        error: "验证失败：身份证明文件验证失败，请重新上传或拍照。",
        customerAbleTo: "客户能够：访问交易网站来浏览产品。​​",
        unableTo: "​客户不能够：交易，或存取款。",
        phaseOne: "等级1:",
        toPreview: " 预览MetaComp产品",
        phaseOneDetail: {
            l1: "请输入您的电子邮件地址和个人信息。",
            l2: "客户能够：访问交易网站来浏览产品。",
            l3: "客户不能够：交易，或存取款。",
        },
        phaseTwo: "等级1:",
        toTrade: "开始交易MetaComp产品",
        phaseTwoDetail: {
            l1: "请提供身份证明文件和地址证明的副本。",
            l2: "客户可以：充值法币，交易产品，和提币。",
        },
        completed: "已完成",
        completedDetail: "客户能够：充值法币，交易产品，和提币。.",
        ableToFait: "客户可以：充值法币，交易产品。",
        unableToWithdraw: "客户不能够：提取现金",
    },
    TierSchemeEnterprise: {
        tierScheme: "等级概述",
        phaseOne: "等级1：预览MetaComp相关产品",
        phaseOneDetail: {
            l1: "请输入您的电子邮件地址和个人信息。",
            l2: "客户能够：访问交易网站来浏览产品。",
            l3: "客户不能够：交易，或存取款。",
        },
        phaseTwo: "等级2：交易MetaComp相关产品",
        phaseTwoDetail: {
            l1: "客户可以：充值法币，交易产品。",
            l2: "客户不能够：提币",
        },
        phaseThree: "等级3：完成相关文件的提交",
        phaseThreeDetail: {
            l1: "客户可以：充值法币，交易产品,和提币. ",
        }
    },
    docsRequired: {
        return: "返回",
        individualVerification: "个人账户验证",
        docsRequired: "需要的文件"
    },
    docsTab: {
        tab: {
            l1: "新加坡公民或新加坡永久居民",
            l2: "马来西亚公民或非新加坡居民 （外国人）",
        }
    },
    singaproeans: {
        l1: "请按照以下要求来准备文件以便您的验证请求能够更快速的完成",
        l1Detail: {
            l1: "所有的文件必须翻译成英文。",
            l2: "所有的文档必须是经核实无误的副本（CTC）。核实可以由被第三方公证机构，或注册会计师，或是律师，也可以和MetaComp的官员开展视频会议来视频认证副本的原件。"
        },
        l2: "1. 一张带有日期的自拍照，同时照片必须清晰显示出NRIC。",
        l2_2: "2. 居住/邮寄地址证明：以下任意一项均可，但日期必须在三（3）个月之内。",
        l2Detail: {
            l1: "- 水电，燃气费账单",
            l2: "- 银行对账单",
            l3: "- 信用卡对账单",
            l4: "- 政府信函",
        },
        l3: "3. 合格投资人证明：以下任意一项或几项。",
        l3Detail: {
            l1: "- 最近三个月的计算机生成的工资条原件，最新的收入评估证明，等等",
            l2: "- 最新的银行对账单，CDP对账单，在托管资产持仓，股票融资证明，投资组合对账单(需显示单位信托持有明细，固定收益证券，等等), 最近的能够显示出退保金额的保险对账单，等等",
            l3: "- 房地产土所有权证书的副本",
        }
    },
    malaysians: {
        l1: "请按照以下要求来准备文件以便您的验证请求能够更快速的完成",
        l1Detail: {
            l1: "所有的文件必须翻译成英文。",
            l2: "所有的文档必须是经核实无误的副本（CTC）。核实可以由被第三方公证机构，或注册会计师，或是律师，也可以和MetaComp的官员开展视频会议来视频认证副本的原件。",
        },
        l2: "1. 一张带有日期的自拍照，同时照片必须清晰显示出护照照片页，并且护照有效期必须在六（6）个月之内。",
        l2_2: "2. 居住/邮寄地址证明：以下任意一项均可，但日期必须在三（3）个月之内。",
        l2Detail: {
            l1: "- 水电，燃气费账单",
            l2: "- 银行对账单",
            l3: "- 信用卡对账单",
            l4: "- 政府信函",
        },
    },
    personDetail: {
        title: "称谓",
        firstName: "名",
        lastName: "姓",
        pleaceName: "和NRIC/护照一样",
        passportNumber: "护照号码",
        idNumber: "证件号码",
        birth: "出生日期",
        nationality: "国籍",
        countryOfResidence: "出生地所在国家",
        isSingapore: "新加坡",
        others: "其他",
        countryOther: "除新加坡外的其他国家",
        highestEducation: "您的最高受教育程度",
        confirmAndNext: "确认并前往下一步",
        same: "与国籍相同",
        years: "客户必须年满18周岁才可以注册",
        rules: {
            title: "请选择您的称谓",
            firstName: "请输入您的名",
            lastName: "请输入您的姓",
            passportNumber: "请输入您的护照号码",
            idNumber: "请输入您的证件号码",
            birth: "请选择您的出生日期",
            nationality: "请输入您的国籍",
            countryOther: "请选择一个除新加坡外的其他国家",
            highestEducation: "请选择您的最高受教育程度",
        },
        certifiedCard: "Certified True Copy Required of your Identity Card",
        certifiedPassport: "Certified True Copy Required of your Passport",
        note: "When certifying, please ensure the following are included:",
        note1: "1) Signature of certifier;",
        note2: "2) Name of certifier;",
        note3: "3) Role/ Position of certifier;",
        note4: "4) Date of certification; and",
        note5: "5) The certification wording (or words with similar effect): 'I have seen the original document and I hereby certify that this document is a complete and true copy of the original'.",
        ctcIdCardNote: "When certifying, please ensure the following are included:",
        ctcIdCardNote1: "1) Must have identity card or passport in hand;",
        ctcIdCardNote2: "2) Name and Date;",
        ctcIdCardNote3: "3) The certification wording (or words with similar effect): 'I agree to open an account with MetaComp and this selfie submitted is for the sole purpose of account opening.'.",
        isBlockedCountriesTips: "您选择的国家被阻止，请重新选择。",

    },
    contactDetail: {
        mobileNumber: "手机号码",
        homeNumber: "座机号码",
        emailAddress: "电子邮箱地址",
        emailAddressTips: "用来通知您电子对账单已准备好在线访问",
        mailingAddress: "邮寄地址",
        mailingAddressTips: "请输入您的邮寄地址",
        countryPostalCode: "国家/地区以及邮政编码",
        postalCode: "邮政编码",
        residentialAddress: "居住地址",
        which: "which needs to be detailed to the Unit Number",
        residentialAddressTips: "请输入您的居住地址",
        confirmAndNext: "确认并前往下一步",
        checkDetail: "与前面填写的居住地址，国家/地区以及邮政编码相同",
        rules: {
            mobile: "请输入您的手机号码",
            homeNumber: "请输入您的座机号码",
            emailAddress: "请输入您的电子邮箱地址",
            correctEmail: '请您输入正确的电子邮箱地址',
            mailingAddress: "请输入您的邮寄地址",
            countryPostalCode: "请输入您的国家/地区名称和邮政编码",
            residentialAddress: "请输入您的居住地址",
        }
    },
    modal: {
        title: "SingPass或人工审核",
        detail: "对于通过Singpass注册的新加坡公民和永久居民，您可以通过Singpass的Myinfo来提取信息去完成您的申请。",
        tip1: "Myinfo是一项新加坡政府启动的旨在为您提供方便的数字计划。通过Singpass来验证， Myinfo 是一项政府数字服务，可让您通过自动填充您的个人数据来预先填写在线申请。点击“通过 Singpass 申请”（使用 Singpass），即表示您同意我们的",
        termsOfUse: " 使用条款",
        and: "以及",
        privacyStatement: "隐私声明。",
        manualReview: "不，人工审核",
    },
    trading: {
        validateInput: {
            buyTriggerPriceRequired: "需要购买触发价格",
            buyPriceRequired: "请您填写购买价格",
            buyAmountRequired: "请您填写购买金额",
            sellTriggerPriceRequired: "请您填写卖出触发价格",
            sellPriceRequired: "需请您填写卖出价格",
            sellAmountRequired: "请您填写卖出金额",
            fastPriceRequired: "需请您填写价格",
            fastAmountRequired: "请您填写金额",
            updateOrderPriceRequired: "请您填写价格",
            updateOrderAmountRequired: "请您填写金额",
            minNumRequired: "最小交易数量是",
        }
    },
    feeSchedule: {
        feeSchedule: "费率标准",
        transactionFees: "交易费",
        transactionFeesDetail: "maker角色和taker角色均为15个基点，CIS产品法币计费，其他产品已交易币种计费。",
        transactionFeesDetailMvgx: "maker角色和taker角色均为15个基点，法币计费。",
        otcFees: "大宗交易费用（线上）",
        custodyFees: "托管费",
        waived: "减免",
        withdrawalFees: "提款费用",
        cryptoCurrencies: "加密货币",
        cryptoCurrenciesDetail: "提款费用旨在支付链上转账费用",
        asset: "资产",
        fee: "费率",
        miniAmount: "最小金额",
        depositAndWithdrawalFees: "存取款费用",
        fiatCurrencies: "法定货币",
        mce:'MetaComp',
        mvgx:'MVGX',
        introduce: "不对法定货币存款或取款收取任何费用。 然而，代理银行可能收取包含但不限于电汇费用等相关费用，这可能会影响您实际收到的金额。",
        OTCFeesDesk: "大宗交易费用(柜台)",
        tips: "相关费用已经包含在报价中。",
        otcFeesDetail:"已经包含在报价中",
    },
    footer: {
        general: "通用",
        generalList: {
            l1: "我们的产品",
            l2: "NFT图库",
            l3: "我们的平台",
            l4: "列表",
            l5: "研究",
        },
        about: "关于",
        aboutList: {
            l1: "愿景",
            l2: "为什么选择MVGX",
            l3: "我们的团队",
            l4: "加入我们",
        },
        media: "媒体",
        mediaLits: {
            l1: "Blog",
            l2: "新闻发布",
            l3: "视频",
        },
        company: "公司",
        companyList: {
            l1: "条款和条件",
            l2: "隐私条款",
            l3: "联系我们",
            l4: "FAQ",
            l5: "交易规则",
            l6: "信息披露",
        },
        license: "许可证号：CMS 1010",
        recognised: "广泛认可的市场运营商",
        copyright: "Copyright © 2023 MetaVerse Green Exchange",
        detail: "The MVGX platform is operated by MetaVerse Green Exchange Pte. Ltd. (“MVGX”). MVGX is regulated by the Monetary Authority of Singapore and holds:- (1) a capital markets services license for the dealing of capital markets products  and providing custodial services in accordance with the conditions therein; and (2) a Recognised Market Operator license for the operation of an approved recognized market in accordance with the conditions therein.",
        contentMvgx: "The MVGX platform is operated by MetaVerse Green Exchange Pte. Ltd. (“MVGX”). MVGX is regulated by the Monetary Authority of Singapore and holds:- (1) a capital markets services license for the dealing of capital markets products  and providing custodial services in accordance with the conditions therein; and (2) a Recognised Market Operator license for the operation of an approved recognized market in accordance with the conditions therein.",
        contentMce: "一家集成支付平台，由MetaComp Pte. Ltd.,以及它的母公司和附属公司运营",
        contentMce2:"版权所有 © 2023 MetaComp 且保留所有权利",
    },
    comHeader: {
        spotOrders: "现货订单",
        investmentOrders: "投资订单",
        OTCOrders: "大宗交易(OTC)订单",
        spotTradeHistory: "虚拟货币历史记录",
        portfolioOverview: "账户概览",
        MCEFiatPortfolio: "MetaComp法币账户",
        MVGXFiatPortfolio: "MVGX法币账户",
        MCECryptoPortfolio: "MetaComp数字资产账户",
        MVGXInvestmentPortfolio: "MVGX投资账户",
        bankAccount: "银行账户",
        walletAddressBook: "钱包地址簿",
        depositHistory: "存币历史",
        withdrawalHistory: "提币历史",
        transferHistory: "内部转账历史",
        statement: "对账单",
        movementCharges: "流水和收费",
        KYCLevel: "KYC等级",
        accountID: "账户ID",
        personalDetails: "个人信息",
        KYCVerification: "KYC认证",
        securityVerification: "安全验证",
        subAccounts: "子账号",
        logout: "退出登录",
        logIn: "登录",
        signup: "注册",
    },
    tradeList: {
        title: "交易热点列表",
        viewMore: "查看更多",
        name: "货币名称",
        lasePrice: "最后成交价",
        change: "24小时变化",
        markets: "市场",
        trade: "交易",
    },
    transferForm: {
        from: "转出账户",
        to: "转入账户",
        amount: "金额",
        availableAmount: "可用金额",
        tips: "您的账户没有足够的余额，请充值后重试。",
        deposit: "充值",
        portfolioTransfer: "portfolioTransfer",
        submit: "确认",
        continueToTransferAgain: "转账",
    },
    banner: {
        tradeNow: "马上交易",
        signUp: "注册",
    },
    downLoadApp: {
        tradeAnywhereAnytime: "随时随地，皆可交易",
        detail: "请扫描二维码，下载我们的移动端App",
        downloadApp: "下载移动端App",
    },
    listImage: {
        fast: "加密资产和法定货币交易",
        fastDetail: "与亚洲领先的商业银行深度合作，进行快捷加密资产和法定货币交易",
        OTC: "大宗交易",
        OTCDetail: "为大额交易提供最优惠的价格",
        invest: "投资",
        investDetail: "在我们的交易平台上探索各种通过新加坡金融管理局CISNET备案的集合投资计划",
        competitiveFees: "最优惠的费率",
        competitiveFeesDetail: "了解更多关于我们的费率",
        payment: "支付",
        gateway: "集成法币/稳定币支付通道",
    },
    mobileBanner: {
        title: "在MVGX开启您的旅程",
        detail: "您进入数字空间的最佳合作伙伴",
        signUp: "注册",
    },
    quickAccess: {
        title: "快速访问",
        makeADeposit: "进行存款",
        makeADepositTips: "立即为您的账户注资",
        deposit: "存款",
        completeKYC: "完成KYC认证",
        completeKYCTips: "完成KYC认证和我们一起展开您的数字之旅",
        enter: "进入",
        startTrading: "开始交易",
        startTradingTips: "买卖您的数字资产并探索更多的可能性",
        manageSubAccounts: "访问您的子账户",
        manageSubAccountsTips: "快速访问并管理您的子账户",
        start: "开始"
    },
    bannerMce: {
        tradeNow: "立刻交易",
        signUp: "注册",
        tip1: "MetaComp是一家持牌合规数字金融资产服务平台，由新加坡MetaComp Pte. Ltd.,以及它的母公司和附属公司运营",
        mce: {
            b1: "MetaComp: 您进入数字资产领域最值得信赖的伙伴",
            b2: "通过连接数字货币和传统金融领域来展现您真正的投资潜力",
            title: "MetaComp: 值得您信赖的合作伙伴，连接数字资产和传统金融服务",
            bottom: "",
        },
        ourVision: {
            b1: "我们的愿景：成为数字资产与传统金融服务之间的桥梁",
            b2: "我们的使命：以安全、合规、高效的方式提供一站式综合数字资产金融服务",
        },
        productsServices: {
            b1: "我们的产品和服务",
            b2: "我们和亚洲领先的银行有着深度合作，为客户提供美元、欧元和新加坡元的加密资产交易和相关银行服务",
            b3: "我们和业内最大的几家流动性提供商进行深度合作并提供最小滑点的OTC交易",
            b4: "最高安全等级的托管服务",
            b5: "您可以在MetaComp访问多种在新加坡金融管理局CISNET备案的集合投资计划",
        },
        congrat:{
            b1: "热烈祝贺！",
            b2: "MetaComp成功取得由新加坡金融管理局依据支付服务法案（Payment Services Act）颁发的主要支付机构牌照！",
            b3: "",
            b4: "",
            b5: "点击这里开始交易"
        },
    },
    bitcoinIndex: {
        title: "MVGX比特币指数",
        high: "24小时最高",
        low: "24小时最低",
        consist: "MVGX比特币指数价格是最近30分钟的移动平均比特币价格，包含六个加密交易所上等权重的执行价格的平均值，剔除了异常值数据。这六家交易所分别是MVGX, BitFinex, Coinbase, FTX, Gemini, Kraken.",
    },
    methIndex: {
        title: "MVGX以太币指数",
        high: "24小时最高",
        low: "24小时最低",
        consist: "MVGX以太币指数价格由过去30分钟移动平均线计算的比特币价格组成，使用六个加密交易所的同等权重执行价格的平均值，不包括异常数据。这六家交易所分别是MVGX、BitFinex、Coinbase、FTX、Gemini和Kraken。",
    },
    allTableList: {
        name: '货币名称',
        lastPrice: "最新成交价",
        change: "24小时变化",
        high: "最高",
        low: "最低",
        trade: "交易",
    },
    favorites: {
        noResults: "没有结果. 转到",
        all: "全部",
        market: "市场来添加您最喜欢的数字货币.",
    },
    favoritesTableList: {
        name: '货币名称',
        lastPrice: "最新成交价",
        change: "24小时变化",
        high: "最高",
        low: "最低",
        trade: "交易",
    },
    madeTable: {
        offer: "出价",
        offerPrice: "出价",
        USDPrice: "美元价格",
        expiration: "有效期",
        from: "从",
        status: "状态",
    },
    moreFC: {
        title: "更多收藏",
        ethereum: "Ethereum (ERC-721)",
        ERC: "ERC",
        price: "价格：",
        daysLeft: "剩余2天",
    },
    nftHonor: {
        title: "我的NFT收藏",
        create: "创建",
        nftHONOR: "NFT-HONOR",
        num: "0x4f12...F84f",
        date: "2021年9月加入",
    },
    nftList: {
        price: "价格：",
    },
    offersMade: {
        title: "已出价"
    },
    offersReceived: {
        title: "收到的报价",
        acceptAll: "接受所有",
    },
    receivedTable: {
        offer: "出价",
        offerPrice: "出价",
        USDPrice: "美元价格",
        expiration: "有效期",
        from: "从",
    },
    openTable: {
        tabName: "正在交易的订单",
        viewMore: "查看更多",
        createdAt: "建立于",
        orderID: "订单号",
        symbol: "标识",
        orderType: "订单类型",
        buySell: "买/卖",
        price: "价格",
        amount: "金额",
        status: "状态",
        actions: "操作",
        update: "更新",
        cancel: "取消",
    },
    orderTable: {
        tabName: "订单历史",
        createdAt: "建立于",
        orderID: "订单号",
        symbol: "标识",
        orderType: "订单类型",
        buySell: "买/卖",
        price: "价格",
        amount: "金额",
        actions: "操作",
    },
    tradeTable: {
        createdAt: "建立于",
        tradeID: "交易ID",
        symbol: "标识",
        buySell: "买/卖",
        price: "价格",
        amount: "金额",
        total: "总额",
        fee: "费用",
        status: "状态",
    },
    user: {
        participantName: "Participant姓名:",
        accountNumber: "账号:",
        date: "生成日期:"
    },
    addressModel: {
        title: "编辑邮件地址",
        address: "邮件地址",
        cancel: "取消",
        save: "保存",
    },
    contactNameModel: {
        title: "编辑联系人姓名",
        contactName: "联系人姓名",
        cancel: "取消",
        save: "保存",
    },
    contactNumberModel: {
        title: "编辑联系号码",
        country: "国家/地区",
        contactNumber: "联系号码",
        cancel: "取消",
        save: "保存",
        pleaseHolder: '请输入联系号码'

    },
    descriptionModel: {
        title: "编辑描述",
        description: "描述",
        cancel: "取消",
        save: "保存",
    },
    kycVerificationCom: {
        description: "请选择您的账户类型来开始进行KYC",
        individual: "个人账户",
        corporate: "企业账户",
        next: "下一步",
        return: "返回",
        individualStart: "请选择您的个人账户类型",
        individualSingaporean: "新加坡公民或新加坡永久居民",
        individualNoSingaporean: "非新加坡个人",
        corporateStart: "请确认您的企业类型",
        corporateSingaporean: "新加坡企业",
        corporateNoSingaporean: "非新加坡企业",
        start: "开始",
        more: "More stringent requirements and longer service time apply for high-risk customers.",  //翻译
        please: "Please note that once started, you will not be able to modify your type.", //翻译
        pleaseContinue: "Please Choose to Continue",
        continueToKYCForm: "Continue to KYC Form",
        later: "I will do this later",
        uploadIPA: "Upload IPA",
        pdfTip: "Only Pdf, Doc and Docx no more than 5MB are accepted.",
        submit: "Submit",
    },
    loginPasswordModel: {
        tips: "提示",
        detail: "重置密码后，您将会自动退出登录状态。",
        cancel: "取消",
        confirm: "确认",
    },
    nameModel: {
        title: "编辑姓名",
        name: "姓名",
        cancel: "取消",
        save: "保存",
        firstName: "First Name",
        lastName: "Last Name",
    },
    preSettings: {
        title: "首选项设置",
        order: '订单确认提示',
        orderDetail: "如果订单确认提示选项打开，那么您需要在每次订单提交后进行二次确认。",
        incompleted: "未完成",
        manage: "管理",
        notificationLanguage: "提示语言",
        notificationLanguageDetail: "电子邮件和移动端推送提示语言设置。",
        onSiteNotifications: "站内提示",
        onSiteNotificationsDetail: "如果您打开这个选项，您会在App和网站中收到相关提示。",
        marketingEmails: "市场推广邮件",
        marketingEmailsDetail: "请选择您是否希望收到我们发送的市场推广邮件。",
    },
    subAccounts: {
        title: "子账户",
        create: "创建子账户",
    },
    loginPassword: {
        title: "登录密码",
        tips: "如果您修改了登录密码， 提款和提币功能将会被暂停24小时来保护您的资产安全。",
        oldPassword: "旧密码",
        newPassword: "新密码",
        comfirmPassword: "请再次确认密码",
        confirm: "确认",
        updateSuccess: "修改成功",
        rules: {
            message: "此项为必填项.",
        }
    },
    mailNotificationSettings: {
        title: "邮件通知设置",
        matchedOrdersNotification: "订单成交通知",
        tip: "当您成交限价单后，我们将通过邮件通知您",
        emailNotification: "邮件通知",
        radio1: "总是发送邮件通知",
        radio2: "Only notify when I’m offline",
        radio3: "不发送邮件通知",
        successfully: "成功",
        failed: "失败",
    },
    subAccountsIndex: {
        return: "返回",
        title: "子账户",
        assets: "资产",
        estimatedValuation: "估计价值"
    },
    SubTable: {
        label: "标签",
        date: "到账日期",
        email: "Email",
        accountNo: "账号",
        status: "状态",
        actions: "操作",
        edit: "编辑",
        transfer: "转账",
        login: '登录',
        remarks: "备注",
    },
    buyForm: {
        buy: "买",
        sell: "卖",
        buy2: "购买",
        sell2: "售卖",
        order: "订单",
        quantity: "数量",
        amount: "金额",
        to: "的",
        required: "的金额",
        bestPrice: "最好价格",
        marketPrice: "的最好市场价格",
        above: "以",
        pricePer: "的固定价格",
        orderType: "订单类型",
        total: "总额",
        of: "of",
        estimated: "预估支付",
        amount2: "实际支付",
        fastBuy: "快捷购买",
        fastSell: "快速出售",
        limitPrice: "限价",
        estPrice: "预估价格",
        limit: "限价单",
        market: "市价单",
        best: "最优市场价",
    },
    buyPop: {
        confirmYourOrder: "确认您的订单",
        orderDetails: "订单详情",
        orderType: "订单类型",
        limitPrice: "限价",
        freeCurrency: "收费货币",
        estimatedFee: "预估费用",
        estimatedUSDToSpend: "预计美元花费",
        submitOrder: "提交订单",
        cancel: "取消",
    },
    canclePop: {
        tips: "您确认要取消所选择的订单吗？",
        confirm: "确认",
        cancel: "取消",
    },
    fastBuy: {
        order: "订单"
    },
    updatePop: {
        confirmYourOrder: "确认订单",
        buy: "买",
        limit: "限",
        orderDetails: "订单详情",
        orderType: "订单类型",
        buyLimit: "买（限价）",
        limitPrice: "限价",
        amount: "金额",
        freeCurrency: "收费货币",
        estimatedFee: "预估费用",
        estimatedUSDToSpend: "预计美元花费",
        submitOrder: "提交订单",
        cancel: "取消",
    },
    fastTradeIndex: {
        title: "快速交易"
    },
    dealStatus: {
        title: "在开始交易前，您需要满足以下所有条件：",
        one: "1. 完成KYC等级1验证",
        gotoKyc: "立刻转到KYC验证",
        two: "2. 同意MVGX市场交易条款",
        exit: "退出",
        agree: "点击查看条款并同意",
    },
    limt: {
        descriptionOne: "取消前有效(GTC)订单指的是被设置为无限期有效或直到被客户取消的挂单",
        GTC: "GTC",
        descriptionTwo: "立即成交或取消(IOC)订单必须立即以限价或更佳的价格成交。如果订单无法立即完全成交，未成交的部分将被取消",
        IOC: "IOC",
        descriptionThree: "成交或取消(FOK)订单是指在特定时间段内，一旦市价达到订单所指定的价格时，立即进行交易，如果不能以指定或较好的价格进行交易，则立即取消",
        FOK: "FOK",
        available: "可用：",
    },
    market: {
        descriptionOne: "取消前有效(GTC)订单指的是被设置为无限期有效或直到被客户取消的挂单",
        GTC: "GTC",
        descriptionTwo: "立即成交或取消(IOC)订单必须立即以限价或更佳的价格成交。如果订单无法立即完全成交，未成交的部分将被取消",
        IOC: "IOC",
        descriptionThree: "成交或取消(FOK)订单是指在特定时间段内，一旦市价达到订单所指定的价格时，立即进行交易，如果不能以指定或较好的价格进行交易，则立即取消",
        FOK: "FOK",
        available: "可用：",
    },
    dealIndex: {
        placeAnOrder: "即刻下单",
        tips: "很抱歉，现在不是可交易时间。我们的交易时间是",
        available: "数字资产账户余额",
        transfer: "转账",
    },
    lastPrice: {
        lastPrice: "最后成交价格",
    },
    marketTrades: {
        marketTrades: "市场交易",
        time: "时间",
        amount: "金额",
        price: "价格",
    },
    orderSoketIndex: {
        orderBook: "订单簿",
        price: "价格",
        amount: "金额",
        total: "总价",
    },
    historyTable: {
        createdAt: "建立于",
        orderID: "订单号",
        symbol: "标识",
        orderType: "订单类型",
        buySell: "买/卖",
        executionPrice: '执行价格',
        amount: "金额",
        status: "状态",
    },
    tradeHeader: {
        lastPrice: "最后成交价格",
        high: "24小时最高",
        low: "24小时最低",
        volume: "24小时成交量",
        amount: "24小时成交金额",
    },
    addQuest: {
        corporateVerification: "企业验证",
        additionalQuestions: "额外问题清单",
        question: "问题",
        answer: "答案",
        uploadFile: "上传文件",
        tips: "将您的文件拖拽到这里或者点击",
        this: "这个",
        upload: "上传",
        submit: "提交",
    },
    complete: {
        thankYou: "感谢您的信任!",
        successfully: "Your application has been successfully submitted; you will be notified via email shortly.",
        tip: "您的申请正在审核中，请您耐心等待。在申请审核的过程中，您暂时不能进行数字货币虚拟货币，同时也不能进行提款提币。一旦您的账户审核完成，我们将给您发送确认邮件，请注意查收。感谢您的配合和理解。",
    },
    startVerification: {
        select: "请选择您所申请审核的账户类型",
        tip: "请注意，一旦您确认了账户类型并开始填写资料，您将不能再更改您的账户类型，请谨慎选择。",
    },
    addressProof: {
        title: "Mailing/Business Operation Address Proof",
        detail: "If the company's mailing/business operation address is different from the company's registered address, please provide mailing/business operation address proof.",
        mailingAddress: "- Any of the following items can be used as mailing address proof: ",
        a1: "(a) Government statement",
        a2: "(a) Government bodies correspondence (CPF statement, IRAS statement, etc)",
        b: "(b) Bank statement",
        c: "(C) Utility and telco bill",
        d: "(d) ACRA Business Profile or equivalent ",
        please: "Please note that the document must be dated within the past 3 months.",
        drag: "Drag the file to here or click",
        this: "this",
        toUpload: "to Upload",
        confirm: "Confirm and Submit",
    },
    enterprisePhase3Index: {
        return: "返回",
        corporateVerification: "Corporate Verification",
        information: "Information to Key In",
    },
    backSide: {
        backSide: "Back Side",
    },
    frontSide: {
        frontSide: "Front Side",
        upload: "Upload"
    },
    innerLoadCon: {
        drag: "Click here to upload",
        this: "this",
        toUpload: "to Upload",
        onlyPDF: "Only PDF file of no more than 5 MB are supported.",
    },
    investor: {
        l1: "- Bank statement to reflect financial holdings, i.e., net asset of S$10 million (or the equivalent in foreign currencies), can be accepted.",
        l2: "- Crypto wallet assets holding statements are also accepted as part of financial holding of the S$10 million.",
        l3: "- Annual financial statements (balance sheet), or management accounts (balance sheet) to be provided within 30 calendar days.",
    },
    issue: {
        HK: "HK Companies (ICRIS)",
        cayman: "Cayman Island Companies (Cayman Islands General Registry)",
        malaysiaCompanies: ">Malaysia Companies (SSM)",
        taiwanCompanies: "Taiwan Companies (GCIS)",
        indonesiaCompanies: "Indonesia Companies (Indonesia Registry)",
        south: "South Korea Companies (South Korea Registry)",
        united: "United Arab Emirates (Company Registry Information)",
    },
    documentList: {
        l1: 'Hong Kong (NAR1)',
        l2: 'Malaysia (SSM)',
        l3: 'Taiwan (GCIS)',
        l4: 'Indonesia (Indonesia Registry)​',
        l5: 'South Korea (South Korea Registry)​',
        l6: 'Cayman Islands (Certificate of Incumbency)',
        l7: 'British Virgin Islands (Certificate of Incumbency) ​',
        l8: 'United Kingdom (Companies House extract)',
        l9: 'United Arab Emirates (Company Registry Information)',
    },
    memorandumText: {
        l1: "-  Full set of M&A / Constitution",
        l2: "-  Certified by third-party notary, or Chartered Accountant, or Lawyer, or to arrange with MVGX officer to sight original documents via video-conference"
    },
    enterpriseOverview: {
        l1: "-  An illustration of the company's ownership structure showing Person(s) / Shareholder(s) with >=25% indirect and/or direct shareholdings(to be signed and dated by Director)",
        l2: "-  Corporate Secretarial document to verify the shareholding structure (ie: intermediate owners, Shareholders)",
    },
    passportIDText: {
        l1: "-  Please provide copy of Identity Card or Passport (with a validity of more than 6 months).",
        l2: "-  Please also (a) upload a dated selfie with a clear view of the Identity Card or Passport, or (b) arrange with MVGX officer to sight original document via video-conference.",
        l3: "-  Shareholder with >=25% shareholding",
    },
    residentialAddress: {
        l1: "-  Any of the following items can be used as residential address proof:",
        l2: "-  Government bodies correspondence",
        l3: "-  Bank statement",
        l4: "-  Utility and telco bill",
        l5: "-  Shareholder with >=25% shareholding",
    },
    secretarialDocuments: {
        title: "Corp secretarial document required for corporates incorporated in the following territories",
        HongKong: "Hong Kong (NAR1)",
        malaysia: "Malaysia (SSM)",
        taiwan: "Taiwan (GCIS)",
        indonesia: "Indonesia (Indonesia Registry)",
        southKorea: "South Korea (South Korea Registry)​",
        caymanIslands: "Cayman Islands (Certificate of Incumbency)",
        britishVirginIslands: "British Virgin Islands (Certificate of Incumbency)",
        unitedKingdom: "United Kingdom (Companies House extract)",
        unitedArabEmirates: "United Arab Emirates (Company Registry Information)"
    },
    companyDetails: {
        nameOfCorporation: "Name of Corporation",
        incorporation: "Place of Incorporation/Registration",
        country: "Country of Incorporation / Registration",
        date: "Date of Incorporation/Registration",
        telephoneNo: "Telephone No.",
        activity: "Principal Business Activity",
        registeredAddress: "Registered Address",
        registeredAddressTips: "it need to consistent with Certificate of Incorporation / Registration.",
        mailingAddress: "Business or Mailing Address",
        mailingAddressTips: "which needs to be detailed to the Room number",
        postalCode: "Country/Region & Postal Code",
        confirmAndNext: "确认并前往下一步",
        rules: {
            nameOfCorporation: "Please enter Name of Corporation",
            incorporation: "Please select Place of Incorporation / Registration",
            country: "Please select Country (ies) Where Business is Conducted",
            date: "Please select Date of Incorporation / Registration",
            telephoneNo: "Please enter Telephone No.",
            activity: "Please enter Principal Business Activity",
            registeredAddress: "Please enter Registed Address",
            mailingAddress: "Please enter Mailing Address",
            postalCode: "Please enter Country & Postal Code",
        }
    },
    copyofDocuments: {
        //改动很大 不同
        one: "Certificate of Incorporation",
        upload: "Upload as this template File, cilck to download",
        two: "Copies of File Upload",
        two2: "Please select the Certification Mode",
        three: "Latest ACRA Business Profile (dated within the last 3 months)",
        four: "Corporate Secretarial Documents to verify Intermediate Owners, Shareholders & Directors (dated within the last 3 months) ",
        fourDe: "Corp secretarial document required for corporates incorporated in the following territories",
        five: "Certificate of Good Standing issued by the issuing authority (dated within the last 3 months)",
        six: "Memorandum and Articles of Association / Constitution",
        sixDe: "Upload Certified True Copy of M & A",
        sixEn: "Please select the Certification Mode",
        seven: "Passport/ID of AT LEAST (1) Executive Director, Shareholders* and ALL Authorized Signatory(ies)",
        eight: "An illustration of the company's ownership structure",
        eightDe: "An illustration of the company's ownership structure showing Person(s) / Shareholder(s) with >=25% indirect and/ordirect shareholdings(to be signed and dated by Director)",
        eightEn: "When certifying, please ensure the following are included",
        nine: "Corporate Secretarial document",
        nine1: "Corporate Secretarial document to verify the shareholding structure (ie: intermediate owners, Shareholder(s) with >=25%)",
        nine2: "Corporate secretarial document required for corporates incorporated in the following territories:",
        ten: "Board Resolution",
        ten1: "Upload refer to this template File, click to download",
        ten2: "If you are unable to provide this file at once, you must need to submit by 90 calendar days and 1st week reminder. Otherwise, your account will be suspended.",
        twoRadioList: {
            one: "Certified by third-party notary public, or chartered accountant, or lawyer",
            two: "Arrange with MVGX officer to sight original documents via video-conference",
            twoDetail: " MVGX officer will contact you by email and/or mobile phone. If no one contact you within 2 Hours during Singapore office hour, Please contact ",
        },
        threeDetail: "If extracted from online Government registry directly, provide proof of extraction such as URL links or receipts, else certified true copy is required.",
        sevenRadioList: {
            one: "A dated selfie with a clear view of Passport",
            add: "Add New Passport/ID",
            tips: "Ensure that the text on the photo is clearly visible. Only JPG/PNG images no more than 5 MB are supported.",
            please: "Please put a sign in the selfie say",
            agree: "I agree to open an account on MetaComp",
            two: "Arrange with MVGX officer to sight original documents via video-conference",
            twoDetail: "MVGX officer will contact you by email and/or mobile phone.If no one contact you within 2 Hours during Singapore office hour, Please contact",
            three: "Mail Certified Ture Copied",
            threeDetail: "Certified by third-party Notary Public, or Chartered Accountant, or Lawyer.",
            email: "Address: 75 Ayer Rajah Crescent #02-15 Singapore 139953",
        },
        tenContent: {
            title: "You have to satisfy ANY ONE of the Following in order to be qualified as an accredited investor.",
            detail: {
                one: "Bank statement to reflect financial holdings, i.e., net asset of S$10 million (or the equivalent in foreign currencies), can be accepted.",
                two: "Crypto wallet assets holding statements are also accepted as part of financial holding of the S$10 million.",
                three: "Annual financial statements (balance sheet), or management accounts (balance sheet) to be provided within 30 calendar days.",
            },
        },
        rules: "I have read, understood and agree with the the terms and conditions in the Trading Rules.",
        confirm: "Confirm and Submit",
        pleaseAgree: "please select agree",
        pleaseSelect: "please select",
        pleaseFile: "please select file",
    },
    enterprise: {
        return: "返回",
        corporateVerification: "Corporate Verification",
        information: "Information to Key In",
    },
    phase3: {
        title: "Singaporeans & Singapore Permanent Residents Individual",
        income: "Income or networth",
        incomeIdTips: "You have to satisfy ANY ONE of the Following in order to be qualified as an accredited investor.",
        accreditedInvestorProof: "Accredited Investor Proof",
        accreditedInvestorProofDetail: {
            title: "Any of the following items or combination of the following items",
            l1: "- Last 3 months' original computerized salary slips, latest Notice of Income Assessment, etc.",
            l2: "- Latest bank statement, CDP statement, custody holdings or share financing statements, investment portfolio statements (showing details of unit trust held, fixed income securities, etc), latest statement showing details of surrender value of insurance policies, etc.",
            l3: "- Copy of land title deed for properties.",
        },
        confirm: "Confirm and Submit",
        note: "Note",
        l1: "All documents are to be translated to English or have English Language.",
        l2: "Provides Proof of Accredited Investor status within 30 days.",
        l3: "The account shall be suspended if the customer failed to provide Proof of AI after 30 calendar days.",
    },
    acknowledge: {
        income: "Income or networth",
        declaration: "Declaration",
        yes: "YES",
        no: "No",
        confirm: "Confirm and Submit",
        declarationList: {
            l1: "Have you ever been convicted of any offences (except minor traffic offences)",
            l2: "Have you ever been sanctioned by any professional body of regulatory body(ies)?",
            l3: "Have you ever been adjudged bankrupt or insolvent?",
        }
    },
    employDetails: {
        employmentStatus: "工作状态",
        occupation: "职业",
        employer: "雇主或公司名称",
        industry: "行业或工作性质",
        officeNumber: "工作单位电话号码",
        annualIncome: "年收入",
        employerAddress: "工作地址",
        country: "国家或地区以及邮政编码",
        confirm: "确认并继续",
        rules: {
            employmentStatus: "请输入工作状态",
            occupation: "请输入职业",
            employer: "请输入雇主或公司名称",
            industry: "请输入行业或工作性质",
            officeNumber: "请输入工作单位电话号码",
            annualIncome: "请选择年收入区间",
            employerAddress: "请输入工作地址",
            country: "请输入国家或地区以及邮政编码",
        }
    },
    networth: {
        title: "您用于交易的资金来源",
        tip1: "财富来源",
        tip2: "与资产来源相同",
        country: "请列出申报财富来源的所有国家",
        singapore: "新加坡",
        others: "其他",
        confirm: "确认并继续",
        pleaseSelect: "请选择",
    },
    taxIncidence: {
        title: "如果您在美国出生，但不是美国税务居民，请提供以下文件:",
        certificate: "- 丧失美国国籍证明",
        form: "- 表格 I-407",
        question: "你的税务居住地是哪个国家?",
        radioList: {
            one: "新加坡纳税人识别号码(TIN)",
            two: "美国社会保险号码（“SSN”）",
            three: "其他国家，请提供以下其他税务居住地的信息",
        },
        TINAvailable: "如果无法拿到TIN，请选择原因",
        TINAvailableDetail: {
            title: "请提供下列文件之一：",
            l1: "- 护照",
            l2: "- 国家身份证",
            l3: "- 政府签发的身份证件",
        },
        reasonCountry: "Reason country of address outside Tax Residency",
        pleaseExplain: "请说明您的地址与报税地址不一致的原因。",
        confirm: "确认并继续",
        reasonData: {
            l1: "No Tax obligation (e.g. minor, retiree, new citizen)",
            l2: "TIN is not issued by country",
            l3: "TIN is not required by country ",
            l4: "Unable to obtain a TIN Provide reason不能获得TIN",
        },
        reasonDeferData: {
            l1: "这是我很多地址中的一个",
            l2: "这是一个值得信任人的地址（例如家庭成员、亲戚、私人助理）",
            l3: "这是一个暂时的地址（例如学习地址、工作地址）",
            l4: "其他，请证明原因",
        }
    },
    formateImage: {
        good: "正确",
        notCropped: "不能裁剪",
        notBlur: "不能模糊",
        notReflection: "不能有倒影",
    },
    individualAddressProof: {
        one: "带有日期的一张自拍照，且能清楚地看到身份证/护照账号",
        two: "官方审核员将会联系你做一个简短的视频会议。",
        twoDetail: "官方审核员将通过电子邮件或手机号码联系您。如果在2个小时内，没有接到任何联系，请联系",
        three: "将证明文件邮寄给我们",
        threeDetail: "第三方公证人、注册会计师或律师认证。",
        email: "邮寄地址: 75 Ayer Rajah Crescent #02-15 Singapore 139953",
        tips: "请确保照片上的文字清晰可见。仅支持上传不大于5MB的JPG或PNG格式的图片。",
        frontSide: "正面",
        please: "请在自拍照上写",
        agree: "我同意在MetaComp上创建账号",
        pleaseCheck: "请确认",
        four: "住宅/邮寄地址证明",
        fourDetail: "下列其中一项的日期都在过去三(3)个月内",
        // uploadPDF: "拖拽文件到这里或点击这里上传证明文件。仅支持小于5MB的PDF格式的文件上传。",
        uploadPDF: "Click here to upload.",
        this: "this",
        toUpload: "to upload",
        confirm: "确认并继续",
    },
    certifiedTrueCopies: {
        one: "带有日期的一张自拍照，且能清楚地看到身份证/护照账号",
        two: "安排审核员通过视频会议的方式查看您的原始证明文件",
        three: "将证明文件邮寄给我们",
        errMsg: "您必须满足下列任何一项条件之一，才有资格成为合格的投资者。",
        tips: "请确保照片上的文字清晰可见。仅支持上传不大于5MB的JPG或PNG格式的图片。",
        please: "请在自拍照上写",
        agree: "我同意在MetaComp中创建账号",
        twoDetail: "官方审核员将会联系你做一个简短的视频会议。",
        send: "发送邮件到",
        minutes: "如果您在30分钟以后仍未收到任何响应",
        threeDetail: "第三方公证人、注册会计师或律师认证。",
    },
    taxResidency: {
        one: "新加坡纳税人识别号码参考NRIC（TIN）",
        onePlease: "If NRIC is not your TIN, please specify",
        country: "Country/Region of tax residence",
        placeholderCountry: "Select country of tax residency",
        TIN: "TIN",
        TINAvailable: "If no TIN is available, please select one of the following reasons",
        reasonTIN: "Reason for missing TIN",
        reason: "Reason",
        TINRequired: "TIN是必填项，如您没有TIN，请告知具体原因",
        add: "+ 添加新的税务居住地",
        tips: "如果您的居住地址所在国家不包括在报税所在地国家或司法管辖区内，请在下面的方框中打勾并确认:",
        checkTips1: "我不是我现所在居住国家的税务居民。通过勾选方框，我确认除了我在上面所申报的国家之外，我不是其他任何国家的税务居民。",
        pleaseCheck: "请确认",
        pleaseEnter: "请输入",
        checkTips2: "我已经阅读，理解并同意",
        tradingRules: "交易规则",
        confirm: "提交",
        note: '注意事项',
        note1: "根据国外账户税务合规(FATCA)和通用报告标准(CRS)，我们需要确定账户持有人的税务居住状态。如果您是美国人或您在国籍之外的国家发生过税务关系，我们可能需要向国家税务机关提供您的账户信息，并且这些信息可能会与不同国家的税务机关共享。",
        note2: "请填写此自我证明表格，以便我们掌握有关您税务居住状况的准确和最新信息。一旦您的情况有变化，请及时提供更新后的自我认证表格。",
        note3: "如果您无法确定您的税务居住身份，请咨询专业税务顾问。",
        note4: "如您需了解更多详情，请向您的税务/法律顾问或当地税务机关咨询。",
        note5: "根据新加坡所得税法案(第134章)及其附属立法的要求，您必须在此表格中向我们提供完整和准确的信息。",
        chooseTaxNumber: "Choose type of your Tax Identication Number ('TIN')",
        taxNumberOne: 'I am solely a tax resident of Singapore and do not have foreign tax residency',
        taxNumberTwo: 'I am currently a Tax Resident of the following jurisdictions (including Singapore if applicable)',
        tinTypeOne: "My TIN is my NRIC or Foreign Identification Number",
        tinTypeTwo: "My TIN is NOT my NRIC or Foreign Identification Number",
    },
    individual: {
        return: "返回",
        individualVerification: "KYC认证 ",
        information: "提交申请资料",
    },
    statusDetail: {  //翻译
        KYCStatus: "认证状态",
        questionDesc: "您的申请正在处理中。请等待批准，您也可以单击此处查看需要您答复的附加请求。",
        sorryDesc: "您提交的信息没有通过审核。请点击“再次验证”按钮重新提交信息。",
        thanksDesc: "您的申请已通过审核。",
        sorry: "抱歉！",
        thanks: "非常感谢！",
        phase1: "Phase 1 Verified by MVGX / MetaComp",
        phase2: "Phase 2 Application Submitted",
        phase22: "Phase 2 Verified by MVGX / MetaComp",
        phase3: "Phase 3 Application Submitted",
        phase33: "Phase 3 Verified by MVGX / MetaComp",
        phaseInfo1a: "Customer: Enters his/her email and password for account registration",
        phaseInfo1b: "MVGX Action: System verifies customer's email.",
        phaseInfo1c: "Expected Service Time: Instantaneous",
        phaseInfo1d: "Customer able to: Access the trade site to browse.",
        phaseInfo1e: "Customer unable to: Trade, or pre-fund his/her account.",
        emailA: "Email Address Verification",
        Log: "Logged in using the password",
        inPhaseInfo2a: "Customer: Provides copies of Identity Card and Address Proof.",
        inPhaseInfo2b: "Estimated Completion Time: 15 Minutes",
        inPhaseInfo3a: "Expected Service Time: 30 Minutes (unless negative information discovered)",
        inPhaseInfo3b: "After you submit information, we will run the negative screening process.",
        inPhaseInfo3c: "Customer able to: Deposit fiat, trade products",
        inPhaseInfo4a: "Expected Time: 15 Minutes",
        inPhaseInfo4b: "The information you submitted is incomplete. Please complete Phase 2 first.",
        inPhaseInfo5a: "MVGX Action:  Validate Proof of Accredited Investor based on your Phase 3 application submitted.",
        inPhaseInfo5b: "Expected Service Time: 1 day after receiving documents",
        inPhaseInfo5c: "Customer able to: Withdraw cash via linked bank account",
        coPhaseInfo2a: "Customer: Provides copies of Identity Card and Address Proof.",
        coPhaseInfo2b: "Estimated Completion Time: 15 Minutes",
        coPhaseInfo3a: "MVGX Action: Runs the negative screening process based on your Phase 2 application submitted.",
        coPhaseInfo3b: "Expected Service Time: 2 hours",
        coPhaseInfo3c: "Customer able to: Deposit, trade, withdraw.",
        coPhaseInfo4a: "Expected Time: 15 Minutes",
        coPhaseInfo4b: "The information you submitted is incomplete. Please complete Phase 2 first.",
        coPhaseInfo2Tip: "For Enhanced Due Diligence customers (i.e. high risk), more stringent requirements apply.There shall be no sales and marketing activities, onboarding or provision of any other DPT ervices within PRC.",
    },
    kycStatus: {
        return: '返回',
        individualVerification: '个人验证',
        corporateVerification: '企业验证',
        information: "Information to Key In",  //翻译
        explain: "为缩短您填写资料的时间，请您预先您准备好必要的证明文件",
        view: "查看所需上传的证明文件",
        more: "对于高风险客户，审核要求更严格，因此服务时间将会更长。",
        individualSingapore: "Individual: Singaporeans & Singapore Permanent Residents",//翻译
        nonSingapore: "Non Singaporean",//翻译
        singaporeCompanies: "Singapore Companies",//翻译
        nonSingaporeCompanies: "Non-Singapore Companies",//翻译
    },
    createPop: {
        accountLabel: "账号标签",
        accountEmail: "账号邮箱",
        modifyPassword: "修改密码",
        accountPassword: "账号密码",
        submit: "提交",
        placeholder: {
            accountLabel: "1~24 字符",
            accountEmail: "最多6个字符",
            accountPassword: "至少包含一个大写字母，一个数字",
        },
        rules: {
            message: "此字段不能为空",
        }
    },
    subTransferForm: {
        from: "从",
        to: "到",
        masterAccount: "(主账号)",
        subAccount: "(子账号)",
        availableAmount: "可用转账余额：",
        tips: "余额不足，请充值/存币后重试",
        deposit: "充值/存币",
        successful: "转账成功",
        rules: {
            message: "此字段不能为空",
        }
    },
    orderTabs: {
        spot: "现货",
        investment: "金融业务",
        OTC: "大宗交易",
    },
    spotTabs: {
        openOrders: "正在交易的订单",
        orderHistory: "订单历史",
        tradeHistory: "交易历史记录",
    },
    dealTabs: {
        limit: "限价",
        market: "市价",
        stopLimit: "限价止损",
        stopMarket: "市价止损",
    },
    dealLimt: {
        buyPrice: "买入价",
        sellPrice: "卖出价",
        buyQuantity: "买入量",
        sellQuantity: "卖出量",
        total: "成交额",
        buy: "买入",
        sell: "卖出",
        triggerPrice: "触发价",
        basic: "标准模式",
        advanced: "高级模式",
    },
    dealMarket: {
        triggerPrice: "触发价",
        bestMarketPrice: "市场最优价格",
        buyQuantity: "买入量",
        sellQuantity: "卖出量",
        total: "成交额",
        buy: "买入",
        sell: "卖出",
        basic: "标准模式",
        advanced: "高级模式",
        quantityCalculated: "交易执行后计算的数量",
    },
    offeringHighlights:{
        title: '产品亮点',
        desc1:'MAS CISNET注集合投资计划 - 一种资本市场产品(下称“计划”)',
        desc2:'简单和易访问意味着以比特币哈希率的形式接触加密挖矿',
        desc3:'投资者可以选择以法定货币认购，不需要拥有加密货币钱包',
        desc4:'此计划的认购单元在MAS持牌交易所上市，提供二级流动资金',
        desc5:'挖矿产生的加密货币资产由新加坡一家获得MAS许可的托管人托管',
        desc6:"基础收益率为10%，并有进一步上行空间",
        desc7:"与直接投资比特币相比，加密挖矿为投资者提供了更低的成本、相对较低的风险和更稳定的回报",
        desc8:"位于北美的矿业资产和设施使用95%在线的Bitman S19J 5nm ASICs",
        desc9:"专业和经验丰富的管理团队，从2016年开始运营哈希率加密挖掘和交易。该集团目前管理着超过1000个PH值的加密矿业务，资产管理规模为2亿美元",
    },
    startTradingPop:{
        trade: "交易",
        spotTrading: "虚拟货币",
        securities: "证券产品交易",
        fastTrade: "快速交易",
        otc: "大宗交易"
    },
    // Mini Crypto Leaders Index 页面
    miniCrypto: {
        underlyingAssets: '标的资产',
        underlyingAssetsTxt: 'MCLI由0.001个（1BTC + 6ETH）的资产支持',
    },
    mmn: {
        scheme: {
            // title: "MMF Interest Bearing Note",
            code: "产品编码",
            productIssuer: "产品发行方",
            dailyReturnRate : "日收益率",
            annualizedInterestRate: "年化收益率",
            tips: "起始日期",
        },
        overview: {
            title1: "产品介绍",
            description1: "In the current crypto bear market, many crypto holders are struggling to find stable and less risky investment opportunities for their crypto assets, there has always been a barrier for crypto asset holders to invest in the traditional financial (TradFi) products, such as equities, bonds, funds, and fixed deposits.   Metaverse Green Exchange (MVGX) is well positioned to bridge this gap and provide crypto holders with more options to diversify their investments while reducing risk exposure.",
            title2: "产品限制",
            underlyingPortfolio: "底层投资组合",
            typeOfSecurity: "证券类型",
            collateralizedAssets: "质押资产",
            interestRate: "计息方式",
            interest: "计息频次",
            tenor: "期限",
            subscriptionCutOffTime: "认购截止时间",
            subscriptionCurrency: "认购结算币种",
            minimalSubscriptionAmount: "最小认购金额",
            minimumHoldingPeriod: "锁仓期",
            interestAccrualStartTime: "利息开始日",
            redemptionCutOffTime: "赎回截止时间",
            redemptionCurrency: "赎回结算币种",
            earliestRedemptionDate: "最早赎回日",
            interestAccrualEndDate: "利息截止日",
            note: "工作日：",
            tips: "指需要同时满足是新加坡工作日和美国工作日。",
        },
        pie: {
            title: "底层投资组合构成",
            interestBearingAccount: "带息账户（银行）",
            interestBearingAccountHouse: "带息账户（证券行）",
            moneyMarketFunds: "货币市场基金",
            USTreasuryProducts: "美国国债产品",
        }
    },
    MCENew: {
        header: {
            productsServices: "Products and Services",
            security: "Security",
            resources: "Resources",
            articles: "Articles",
            faq: "FAQ",
            disclaimer: 'Legal',
            termsAndConditions: "Terms of Use",
            privacyPolicy: "Privacy Policy",
            disclosure: "Risk warning on Digital Payment Token Services",
            tradeRules: "Trading Rules",
            aboutUs: "About us",
            news: "News",
            project1: "CAMP",
            project2: "CAMP DeMPC Wallet",
            project3: "Wallet Safe",
        },
        title: "Unlock the Power of the Digital Age with MetaComp",
        getStarted: "GET STARTED",
        explore: "Explore",
        productsServices: {
            title: "Meet CAMP: <br/>the all-in-one platform <br/>you’ll ever need",
            p1: "Custody with multi-cloud MPC wallet technology for heightened security",
            p2: "Trade digital assets on an Exchange built on NASDAQ technology or through our platform to optimize execution",
            p3: "Collect or instruct payments from/to your clients, suppliers",
            p4: "Invest in portfolio of traditional financial products and/or digital asset derivatives for additional yield",
        },
        need: {
            title: 'Need a head-start in the digital space?',
            title2: 'Good news, we have done the heavy lifting for you!',
            subtitle1: "CAMP DeMPC Wallet",
            subtitle2: "Wallet Safe ",
            p1:"We transform complexity into simplicity, ensuring our platform is a breeze for you to use.",
            p2:"Our multi-cloud MPC wallet allows you to manage your digital assets with multi-party signature while enjoying the convenience of a user-friendly interface",
            p3:"Enjoy the peace of mind that our battle-tested systems and proprietary methodology keep you and your assets safe, while ensuring each step you take is in compliance with the regulation.",
            p4:"Our wallet safe offer protects your wallet by ensuring that the assets you receive are not tainted or that sending to other wallets is safe, even before you process the transaction",
        },
        safety: {
            title: "The safety of your funds is our priority",
            p1: "Assets are safe-guarded by a licensed custodian in Singapore",
            p2: "No single point of failure and mandatory multi-party processes to manage funds",
            p3: "High-level encryption communication",
            p4: "Multi-cloud architecture makes our systems practically invulnerable to cyber-attacks",
            p5: "2-Factor Authentication for login and other critical actions on the platform",
            p6: "(Andrew/Lily to include more details about security measures and standards used in the industry)",
        },
        aboutUs: {
            title: "About us",
            subtitle1: "Our license",
            subtitle2: "Contact us",
            subtitle3: "Why we are so passionate about the Digital Era",
            p1: "MetaComp was granted the Major Payment Institution Licence under the Payment Services Act 2019 by the Monetary Authority of Singapore in December 2022.",
            p2: "Drop us a note, we'd love to catch up!  ",
            p3: "At MetaComp we firmly believe digital assets will bring tremendous value to the real-world economy by speeding up transactions, lowering the costs and reducing counterparty and market risks.",
        },
        footer: {
            company: "Company",
            termsOfUse: "Terms of Use",
            privacyPolicy: "Privacy Policy",
            tradingRules: "Trading Rules",
            product: "Product",
            exchange: "Exchange",
            investmentFinancialServices: "Investment Financial Services",
            carbonConnectSuite: "Carbon Connect Suite",
            media: "Media",
            pressReleases: "Press Releases",
            thoughtLeadership: "Thought Leadership",
            videos: "Videos",
            detail: "The MVGX platform is operated by MetaVerse Green Exchange Pte. Ltd. (“MVGX”). MVGX is regulated by the Monetary Authority of Singapore and holds a Capital Markets Services (CMS) license for the dealing of capital markets products and providing custodial services in accordance with the conditions therein; and a Recognised Market Operator (RMO) license for the operation of an approved recognized market in accordance with the conditions therein.",
            copyright: "Copyright © 2023 MetaVerse Green Exchange",
            products : "Products and Services",
            CAMP: 'CAMP',
            MPC: 'CAMP DeMPC Wallet',
            wallet: 'Wallet Safe',
            cookie: 'Cookies Policy',
            touchWithUs: "Get In Touch With Us",
        }
    }
}